import { IReportTab } from '@/store/report-data/types';
import { IEquipDetails } from '@/store/details/types';

export enum FileFormat {
  SCV = 'csv',
  PDF = 'pdf',
  XLS = 'xls',
  JPG = 'jpg',
  PNG = 'png',
}

export type ImageFileType = FileFormat.JPG | FileFormat.PNG;

export interface IFileNameQuery {
  tab?: IReportTab;
  routeQuery?: any;
  details?: IEquipDetails;
}
