































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { QueryParamsService } from '@/utils';
import { Action, Getter, Mutation } from 'vuex-class';
import { DETAILS_CONST } from '@/store/details';
import { IEquipDetails } from '@/store/details/types';
import { TABS_CONST } from '@/store/tabs/tabs.const';
import { ITab, ITabsQuery } from '@/store/tabs/tabs.types';
import { DICTIONARIES_CONST } from '@/store/dictionaries/dictionaries.const';
import { GET_CHARTS_PROPS } from '@/store/abstract/props-scheme/constants';
import { HISTORY_CHARTS } from '@/store/history-charts/constants';
import { IChartPropsQuery } from '@/store/abstract/props-scheme/types';
import { ALGORITHM_CONST } from '@/store/algorithms';
import { ALGORITHM_DATA_CONST } from '@/store/abstract/algorithm-data/constants';
import EmptyData from '@/components/basics/EmptyData.vue';
import { equipmentStates } from '@/utils';
import { REPORT_DATA, SUBSTATION_EQUIPMENT } from '@/store/report-data/constants';

type QueryType = { tab?: string };

const { ALL_TABS, TABS, GET_TABS, GET_TABS_SUCCESS } = TABS_CONST;
const { TAB_REFS, DICTIONARIES } = DICTIONARIES_CONST;
const { CLEAR_DETAILS, EQUIPMENT_DETAILS, DETAILS } = DETAILS_CONST;

@Component({
  components: {
    'empty-data': EmptyData,
  },
})
export default class DynamicTabView extends Vue {
  queryParams!: QueryParamsService<QueryType>;

  @Prop() rights: any;
  @Prop() currentEquipment: any;
  @Prop() isContainer?: boolean;

  // chart props (used on popup charts)
  @Action(GET_CHARTS_PROPS, { namespace: HISTORY_CHARTS })
  getChartProps!: (query: IChartPropsQuery) => void;

  @Getter(EQUIPMENT_DETAILS, { namespace: DETAILS })
  $equipmentDetails!: IEquipDetails;

  @Getter(ALL_TABS, { namespace: TABS })
  allTabs!: ITab[];

  @Getter(TAB_REFS, { namespace: DICTIONARIES })
  tabRefs!: any[];

  @Action(GET_TABS, { namespace: TABS })
  getAllTabs!: (query: ITabsQuery) => ITab[];

  @Mutation(GET_TABS_SUCCESS, { namespace: TABS })
  setTabs!: (any: []) => void;

  @Mutation(CLEAR_DETAILS, { namespace: DETAILS })
  clearDetails!: () => void;

  get isLoading() {
    return !(this.allTabs.length >= 1) || !this.tabRefs.length;
  }

  get tabs(): ITab[] {
    return (this.allTabs ?? []).filter(
      (tab) =>
        (this.rights && this.rights.summary && equipmentStates.some((x) => x === tab.type)) ||
        (this.rights && this.rights.operational && tab.type === 'live') ||
        (this.rights &&
          this.rights.history &&
          (tab.type === 'chart' || tab.type === 'datatable')) ||
        (this.rights && this.rights.normative && tab.type === 'datatable')
    );
  }

  get selectedTab() {
    return this.queryParams.get().tab || this.tabs[0]?.key;
  }

  get selected(): ITab {
    const { tab } = this.queryParams?.get();
    const sTab = this.tabs.find((item) => item.key === tab);

    return sTab ?? this.allTabs[0];
  }

  get selectedPhase(): number {
    // if no phases at all
    if (!this.$equipmentDetails?.statesAndPhases?.length) return -1;
    const { phase } = this.$route?.query;
    const { statesAndPhases } = this.$equipmentDetails;
    if (!statesAndPhases?.length) return -1;
    const phaseObject = statesAndPhases.find((x) => phase && x.phaseLabel === phase);
    return phaseObject?.phaseValue ?? statesAndPhases[0]?.phaseValue ?? -1;
  }

  @Getter(SUBSTATION_EQUIPMENT, { namespace: REPORT_DATA })
  equipList?: any[];

  get eqOrder(): number | null {
    const { equipId } = this.$equipmentDetails;
    const find = this.equipList?.find((i) => i.equip_id === equipId);
    return find?.eq_order ?? null;
  }

  get allTabsQuery() {
    const equipId = this.$equipmentDetails?.equipId;
    if (equipId && this.selectedPhase !== -1 && this.eqOrder) {
      return { equipId, phase: this.selectedPhase, eqOrder: this.eqOrder };
    } else {
      return null;
    }
  }

  get typeUid(): string {
    return this.$equipmentDetails.typeUid ?? '';
  }

  get typeId(): number {
    return this.$equipmentDetails.typeId ?? -1;
  }

  // @Action(ALGORITHM_DATA_CONST.RESET_DATA, { namespace: ALGORITHM_CONST.ALGORITHMS })
  // resetLastAlgoData!: () => void;

  @Watch('allTabsQuery') onAllTabsQueryChanged(val: any, old: any) {
    if (this.isContainer) {
      return;
    }

    if (val && (!old || val.equipId !== old.equipId || val.phase !== old.phase)) {
      // cleanup last algo data if not empty
      //this.resetLastAlgoData();
      const { equipId, phase } = val;
      if (!equipId || !phase) return;
      this.getAllTabs({
        ...val,
        equipmentType: this.currentEquipment.type_uid,
      });

      // pickup chart props
      this.getChartProps({
        phase: val.phase,
        equipId: val.equipId,
        typeId: this.typeId,
        typeUid: this.typeUid,
      });
    }
  }

  onChangeTab(tab: any) {
    if (this.selectedTab === tab?.name) return;
    this.queryParams.update({ tab: tab.$vnode.key }, { removeExcept: ['phase'] });
  }

  created() {
    this.queryParams = new QueryParamsService<QueryType>(() => ({
      tab: this.$route.query.tab,
    }));
  }

  beforeDestroy() {
    this.setTabs([]);
    this.clearDetails();
  }
}
