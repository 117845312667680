




































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { Sensor, Equipment } from './types';

@Component({
  components: {},
})
export default class KsoParametersView extends Vue {
  @Prop() sensors!: Sensor[] | null;
  @Prop() substation!: Equipment | null;
  @Prop() editable!: boolean;

  isEdit = false;
  isSaving = false;

  sensorsModel: Sensor[] | null = null;

  @Watch('sensors', { immediate: true }) onSensorsChange(val: any) {
    if (val) {
      this.resetModel(val);
    }
  }

  onSave() {
    this.isSaving = true;
    this.$emit('save-sensors', this.sensorsModel, () => {
      this.isEdit = false;
      this.isSaving = false;
    });
  }

  resetModel(sensors: Sensor[]) {
    this.sensorsModel = sensors.map((x) => ({ ...x, protocol: { ...x.protocol } }));
  }

  onEdit() {
    this.isEdit = true;
  }

  onCancelEdit() {
    this.resetModel(this.sensors ?? []);
    this.isEdit = false;
  }
}
