





















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { DETAILS_CONST } from '@/store/details';
import { PhaseConverter } from '@/utils/PhaseConverter';
import { IEquipDetails } from '@/store/details/types';
import moment from 'moment';

@Component
export default class EquipInfo extends Vue {
  @Getter(DETAILS_CONST.EQUIPMENT_DETAILS, { namespace: DETAILS_CONST.DETAILS })
  equipmentDetails?: IEquipDetails;

  get selectedPhase(): number | null {
    const { phase } = this.$route?.query;
    if (!phase) return null;
    return PhaseConverter.getPhase(phase as string);
  }

  get equipInfo() {
    if (!this.equipmentDetails?.equipInfo || !this.selectedPhase) return null;
    return this.equipmentDetails.equipInfo[this.selectedPhase];
  }

  get equipSerial() {
    return this.equipInfo?.serial && this.equipInfo?.serial !== '-' ? this.equipInfo?.serial : null;
  }
  get equipType() {
    return this.equipInfo?.model;
  }
  get producedDate() {
    return moment(this.equipInfo?.produced_date).format('DD.MM.YYYY');
  }
  get installedDate() {
    return moment(this.equipInfo?.installed_date).format('DD.MM.YYYY');
  }
}
