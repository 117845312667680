import { IDataExtract, IPdfBuilderOptions } from '@/components/files/services/types';
import { DataGenerator } from '@/components/files/services/data-generator';

export class PdfContentBuilder {
  private static style = {
    header: {
      fontSize: 18,
      bold: true,
      margin: [0, 0, 0, 20],
    },
    subheader: {
      fontSize: 13,
      bold: true,
      margin: [0, 0, 0, 20],
    },
    tableMrg: {
      margin: [0, 0, 0, 100],
    },
  };
  public static build = (
    { transformedData, propsNames, headers }: IDataExtract,
    { maxColumnCount, title, subTitle }: IPdfBuilderOptions
  ) => {
    // transform data
    const allData = transformedData.map((d) => DataGenerator.sortByPropName(d, propsNames, false));

    const content = [];
    if (title) {
      content.push({
        text: title,
        style: PdfContentBuilder.style.header,
      });
    }

    if (subTitle) {
      content.push({
        text: subTitle,
        style: PdfContentBuilder.style.subheader,
      });
      content.push({
        text: '\n',
      });
    }

    // all skip [0]
    for (let i = 2; i < headers.length; i += maxColumnCount) {
      const n = Math.min(headers.length, i + maxColumnCount);
      const hSlice = headers.slice(i, n);
      // data cycle
      const data = allData.map((j) => [`${j[0]}, ${j[1]}`, ...j.slice(i, n)]);
      content.push({
        layout: 'lightHorizontalLines',
        table: {
          headerRows: 1,
          body: [[`${headers[0]}, ${headers[1]}`, ...hSlice], ...data],
        },
      });
      content.push({
        text: '\n',
      });
    }
    return content;
  };
}
