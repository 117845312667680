

























































































































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { RestApiService } from '@/services';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { QueryParamsService } from '@/utils';

import Protocol5092LocalNetwork from './5092/Protocol5092LocalNetwork.vue';
import Protocol5092Sources from './5092/Protocol5092Sources.vue';
import Protocol5081LocalNetwork from './5081/Protocol5081LocalNetwork.vue';
import Protocol5081Subscriptions from './5081/Protocol5081Subscriptions.vue';
import Protocol5081Preprocessing from './5081/Protocol5081Preprocessing.vue';
import Protocol5081File from './5081/Protocol5081File.vue';
import Protocol5104LocalNetwork from './5104/Protocol5104LocalNetwork.vue';
import Protocol5104Sources from './5104/Protocol5104Sources.vue';
import Protocol5104Preprocessing from './5104/Protocol5104Preprocessing.vue';
import ProtocolModbusLocalNetwork from './modbus/ProtocolModbusLocalNetwork.vue';
import ProtocolModbusSources from './modbus/ProtocolModbusSources.vue';
import ProtocolModbusPreprocessing from './modbus/ProtocolModbusPreprocessing.vue';
import { Sensor, Reference, Equipment, Enum } from './types';
import SensorJournalView from './SensorJournalView.vue';

type QueryType = { tab?: string };

const assetsPath = getApiUrl(TR_TYPE.HTTP, '/assets');
const rest = new RestApiService();

@Component({
  components: {
    'protocol-5092-local-network': Protocol5092LocalNetwork,
    'protocol-5092-sources': Protocol5092Sources,
    'protocol-5081-local-network': Protocol5081LocalNetwork,
    'protocol-5081-subscriptions': Protocol5081Subscriptions,
    'protocol-5081-preprocessing': Protocol5081Preprocessing,
    'protocol-5081-file': Protocol5081File,
    'protocol-5104-local-network': Protocol5104LocalNetwork,
    'protocol-5104-sources': Protocol5104Sources,
    'protocol-5104-preprocessing': Protocol5104Preprocessing,
    'protocol-modbus-local-network': ProtocolModbusLocalNetwork,
    'protocol-modbus-sources': ProtocolModbusSources,
    'protocol-modbus-preprocessing': ProtocolModbusPreprocessing,
    'sensor-journal-view': SensorJournalView,
  },
})
export default class SensorView extends Vue {
  @Prop() sensors!: Sensor[] | null;
  @Prop() currentSensors!: Sensor[] | null;
  @Prop() references!: Reference[] | null;
  @Prop() equipments!: Equipment[] | null;
  @Prop() enums!: Enum[] | null;
  @Prop() editable!: boolean;
  @Prop() substation!: Equipment | null;
  @Prop() states!: Record<string, any> | null;

  queryParams!: QueryParamsService<QueryType>;
  sensorModel: Sensor | null = null;
  assets: any[] | null = null;

  get currentState() {
    const sensor = this.currentSensors?.[0];
    const state = sensor
      ? (this.states || {})[
          sensor?.protocol.protocol === 'IEC_61850_9_2'
            ? sensor?.protocol.ied || ''
            : `${sensor?.protocol.ip}${sensor?.protocol.port ? `:${sensor?.protocol.port}` : ''}`
        ]
      : null;

    return state || null;
  }

  get activeTab(): string {
    const { tab } = this.queryParams?.get();
    return tab || 'network';
  }

  get comTradeSensor() {
    const comtrades = this.sensors?.filter((x) => x.protocol.protocol === 'IEC_60255_24') || [];
    return comtrades.find(
      (x) =>
        this.currentSensors?.[0].protocol.ied &&
        this.currentSensors?.[0].protocol.ied === x.protocol.ied
    );
  }

  get protocol() {
    return this.sensorModel?.protocol.protocol ?? null;
  }

  @Watch('currentSensors', { immediate: true }) async onChangeSensor(
    val: Sensor[] | null | undefined
  ) {
    if (val && val.length > 0) {
      this.resetModel(val);
      this.assets = null;
      const sources = val.flatMap((x) => x.sources);
      const equipIds = sources.map((x) => x.equipId);
      if (equipIds.length > 0) {
        const assets = await rest.put(`${assetsPath}/equips`, equipIds);
        if (assets.status === 200) {
          this.assets = assets.data.map((x: any) => ({
            ...x,
            sensor: val,
            parentEquipment: this.equipments?.find(
              (equipment) =>
                equipment.id ===
                sources.find((source) => source.equipId === x.equipId)?.equipment.parentId
            ),
          }));
        }
      }
    } else {
      this.sensorModel = null;
    }
  }

  resetModel(sensors: Sensor[]) {
    this.sensorModel = {
      ...sensors[0],
      protocol: { ...sensors[0].protocol },
      sources: sensors.flatMap((x) => x.sources.map((y) => ({ ...y, sensor: x }))),
    };
  }

  onSaveSensor(sensor: Sensor, done: Function) {
    this.$emit('save-sensor', sensor, done);
  }

  onSaveSensorAndAssets(sensor: Sensor, assets: any[], done: Function) {
    this.$emit('save-sensor-and-assets', sensor, assets, done);
  }

  onCancelLocalNetworkEdit() {
    this.currentSensors && this.resetModel(this.currentSensors);
  }

  onChangeTab(tab: any) {
    this.queryParams.update({ tab: tab.$vnode.key }, { removeExcept: [] });
  }

  created() {
    this.queryParams = new QueryParamsService<QueryType>(() => ({
      tab: this.$route.query.tab,
    }));
  }
}
