






















import { Component, Watch, Prop } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import Vue from 'vue';
import moment from 'moment';

import { ALGORITHM_DATA_CONST } from '@/store/abstract/algorithm-data/constants';
import { IAlgorithmQuery } from '@/store/algorithms/algorithms.types';
import { TableCol } from '@/components/basics/table/basic-table.types';
import { transformData } from '@/components/items-tab/panes/algorithms/algorithms.extensions';
import DateTimeSelector from '@/components/basics/selectors/DateTimeSelector.vue';
import { ALGORITHM_CONST } from '@/store/algorithms';
import { PRELOADER_STATE } from '@/store/abstract/preloader';
import { IPreloader } from '@/store/abstract/preloader/types';

import UpdateJournalsTable from './UpdateJournalsTable.vue';
import { Enum, Reference, Equipment, Module } from './types';

@Component({
  components: {
    'update-journals-table': UpdateJournalsTable,
    'datetime-selector': DateTimeSelector,
  },
})
export default class UpdateJournalsTableView extends Vue {
  @Prop() enums!: Enum[] | null;
  @Prop() references!: Reference[] | null;
  @Prop() equipments!: Equipment[] | null;
  @Prop() modules!: Module[] | null;
  @Prop() table!: string;

  @Getter(ALGORITHM_DATA_CONST.DATA, { namespace: 'algorithms' })
  $pageData?: any;

  @Getter(PRELOADER_STATE, { namespace: ALGORITHM_CONST.ALGORITHMS })
  preloader?: IPreloader;

  @Action(ALGORITHM_DATA_CONST.LOAD_DATA, { namespace: 'algorithms' })
  $getData!: (payload: IAlgorithmQuery) => void;

  @Mutation(ALGORITHM_DATA_CONST.DATA_LOADED, { namespace: 'algorithms' })
  $setData!: (x: any) => void;

  @Watch('query', { immediate: true }) onQueryChange(val: IAlgorithmQuery | null) {
    if (val && val.start) {
      const { limit, offset } = val;
      if (!limit || !offset) return;
      this.$getData(val);
    }
  }

  get preloaderState(): boolean {
    return (
      (!this.references ||
        !this.enums ||
        !this.equipments ||
        !this.modules ||
        this.preloader?.isBusy) ??
      false
    );
  }
  get preloaderMessage(): string {
    return this.preloader?.message ?? '';
  }

  get query(): IAlgorithmQuery | null {
    return {
      ...this.$route.query,
      algorithm: this.table,
    };
  }

  get journalReference() {
    return this.references?.find((x) => x.name === this.table);
  }

  get scheme(): any {
    return {
      dbTableName: this.table,
      columns:
        this.journalReference?.props.map((x: any) => ({ ...x, dbProps: x.Name, order: x.Order })) ??
        [],
    };
  }

  get columns(): TableCol[] {
    return (
      this.journalReference?.props
        .filter((x: any) => x.Mandatory)
        .map((x: any) => ({
          ...x,
          header: x.Notes,
          dbProps: x.Name,
          property: x.Name,
          width: x.Notes.length * 11 + 50,
          formatter:
            x.Type === 'DateTime'
              ? (value: string) => moment(value).format('HH:mm:ss.S DD-MM-YYYY')
              : undefined,
        })) ?? []
    );
  }

  get guidTablesHash() {
    return (
      this.equipments && {
        substation_uuid: { items: this.equipments, property: 'name' },
        equipment_uuid: { items: this.equipments, property: 'name' },
        calculator_uuid: { items: this.modules, property: 'name' },
      }
    );
  }

  get total() {
    return this.$pageData?.count || 0;
  }

  get page() {
    if (this.$pageData?.selectedPage?.length <= 0 || !this.guidTablesHash) {
      return [];
    } else {
      const p = transformData(
        this.$pageData.selectedPage,
        this.scheme,
        this.enums?.map((x) => ({ ...x, enumUid: x.enum_uid, order: Number(x.order) })),
        this.guidTablesHash
      );

      return p ?? [];
    }
  }

  reQuery() {
    this.query && this.$getData({ ...this.query, noSpinner: true });
  }

  beforeDestroy() {
    this.$setData({ count: 0, data: [] });
  }
}
