





















import { Component, Watch, Prop } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import moment from 'moment';
import Vue from 'vue';

import { ALGORITHM_DATA_CONST } from '@/store/abstract/algorithm-data/constants';
import { IAlgorithmQuery } from '@/store/algorithms/algorithms.types';
import { transformData } from '@/components/items-tab/panes/algorithms/algorithms.extensions';
import DateTimeSelector from '@/components/basics/selectors/DateTimeSelector.vue';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { RestApiService } from '@/services';
import { splitChunks } from '@/store/helpers';
import { QueryBuilder } from '@/utils/QueryBuilder';
import { MOMENT_FORMAT } from '@/utils/datetime/DateTimeRangePresets';
import { ALGORITHM_CONST } from '@/store/algorithms';
import { PRELOADER_STATE } from '@/store/abstract/preloader';
import { IPreloader } from '@/store/abstract/preloader/types';

import SensorJournalTable from './SensorJournalTable.vue';
import { Enum, Reference, Equipment } from './types';
import ExportDataView from './ExportDataView.vue';

const path = getApiUrl(TR_TYPE.HTTP, '/click-house/paginator/selectedPage?');

const rest = new RestApiService();

const protocolToNumber: Record<string, number> = {
  ['IEC_60870_5_104']: 0,
  ['IEC_61850_8_1']: 2,
  ['IEC_61850_9_2']: 5,
  ['Modbus_TCP']: 4,
  ['NTP']: 6,
};

const protocolToReferences: Record<string, string> = {
  ['IEC_60870_5_104']: 'protocol_error_package_104',
  ['IEC_61850_8_1']: 'protocol_error_package',
  ['IEC_61850_9_2']: 'protocol_error_package_sv',
  ['Modbus_TCP']: 'protocol_error_package_modbus',
  ['NTP']: 'protocol_error_package_ntp',
};

@Component({
  components: {
    'sensor-journal-table': SensorJournalTable,
    'export-data': ExportDataView,
    'datetime-selector': DateTimeSelector,
  },
})
export default class GroupSensorsJournalView extends Vue {
  @Prop() enums!: Enum[] | null;
  @Prop() references!: Reference[] | null;
  @Prop() protocol!: string;
  @Prop() substation!: Equipment | null;

  tableName = 'protocol_error_package';

  @Getter(ALGORITHM_DATA_CONST.DATA, { namespace: 'algorithms' })
  $pageData?: any;

  @Getter(PRELOADER_STATE, { namespace: ALGORITHM_CONST.ALGORITHMS })
  preloader?: IPreloader;

  @Action(ALGORITHM_DATA_CONST.LOAD_DATA, { namespace: 'algorithms' })
  $getData!: (payload: IAlgorithmQuery) => void;

  @Mutation(ALGORITHM_DATA_CONST.DATA_LOADED, { namespace: 'algorithms' })
  $setData!: (x: any) => void;

  @Watch('query', { immediate: true }) onQueryChange(val: IAlgorithmQuery | null) {
    if (val && val.start) {
      const { limit, offset } = val;
      if (!limit || !offset) return;
      this.$getData(val);
    }
  }

  get preloaderState(): boolean {
    return this.preloader?.isBusy ?? false;
  }
  get preloaderMessage(): string {
    return this.preloader?.message ?? '';
  }

  get subQuery() {
    return {
      substation: this.substation?.uuid,
      protocol: protocolToNumber[this.protocol],
    } as any;
  }

  get query(): IAlgorithmQuery | null {
    return {
      ...this.$route.query,
      algorithm: this.tableName,
      ...this.subQuery,
    } as any;
  }

  get journalReference() {
    return this.references?.find((x) => x.name === protocolToReferences[this.protocol]);
  }

  get scheme(): any {
    return {
      dbTableName: this.tableName,
      columns:
        this.journalReference?.props.map((x: any) => ({
          ...x,
          dbProps: x.Name,
          order: x.Order,
        })) ?? [],
    };
  }

  get columns(): any[] {
    return (
      this.journalReference?.props.map((x: any) => ({
        ...x,
        header: x.Notes,
        dbProps: x.Name,
        property: x.Name,
        width: x.Notes.length * 11 + 50,
      })) ?? []
    );
  }

  get total() {
    return this.$pageData?.count || 0;
  }

  get page() {
    if (!this.$pageData?.selectedPage?.length) return [];

    const p = transformData(
      this.$pageData.selectedPage,
      this.scheme,
      this.enums?.map((x) => ({ ...x, order: Number(x.order) }))
    );

    return p ?? [];
  }

  get exportConfig() {
    const { start, end } = this.query as any;

    const s = moment(start).format(MOMENT_FORMAT.secAlter);
    const e = moment(end).format(MOMENT_FORMAT.secAlter);

    return {
      fileName: `${this.protocol}_${s}_${e}`,
      columns: this.columns.map((x) => ({
        prop: x.Name,
        name: x.Notes,
      })),
    };
  }

  async getFullTableData() {
    const query = {
      ...this.query,
      limit: undefined,
      offset: undefined,
    };

    const response = await rest.get(path + QueryBuilder.build(query));

    return (
      (response &&
        response.data &&
        transformData(
          splitChunks(response.data),
          this.scheme,
          this.enums?.map((x) => ({ ...x, order: Number(x.order) }))
        )) ||
      []
    );
  }

  reQuery() {
    this.query && this.$getData({ ...this.query, noSpinner: true });
  }

  beforeDestroy() {
    this.$setData({ count: 0, data: [] });
  }
}
