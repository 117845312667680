































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { RadioGroup, RadioButton, Button, Divider } from 'element-ui';

import { DETAILS_CONST } from '@/store/details';
import { IEquipDetails } from '@/store/details/types';
import { QueryParamsService } from '@/utils';
import RegulatoryInf from '@/components/items-tab/panes/RegulatoryInf.vue';
import NsiInfo from '@/components/items-tab/panes/nsi-info/NsiInfo.vue';
import RegInfoView from '@/components/reg-info/reg-info-view.vue';
import FileDownloader from '../files/file-downloader.vue';
import { PhaseManager } from '@/utils/phases/PhaseManager';
import {
  ALL_EQUIPMENT_PHASES,
  REPORT_DATA,
  REPORT_DATA_COLUMNS,
} from '@/store/report-data/constants';
import { IReportColumn } from '@/store/report-data/types';
import { PhaseConverter } from '@/utils/PhaseConverter';

type QueryType = {
  phase?: string;
  offset?: string;
};

@Component({
  components: {
    RegulatoryInf,
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton,
    'el-button': Button,
    'el-divider': Divider,
    'nsi-info': NsiInfo,
    'reg-info': RegInfoView,
    'file-downloader': FileDownloader,
  },
})
export default class Level1TopicCard extends Vue {
  queryParams!: QueryParamsService<QueryType>;
  regDialogVisible = false;

  @Prop() rights: any;
  @Prop() isNsiShown?: boolean;


  @Getter(DETAILS_CONST.EQUIPMENT_DETAILS, { namespace: DETAILS_CONST.DETAILS })
  equipmentDetails?: IEquipDetails;

  get phases() {
    return this.equipmentDetails?.statesAndPhases ? this.equipmentDetails.statesAndPhases : [];
  }

  get selectedPhaseName() {
    const sPhase = PhaseManager.getInitialPhase(this.phases);
    if (!this.phaseFlag && sPhase?.phase && this.queryParams.get().phase !== sPhase.phase) {
      if (sPhase) this.queryParams.update({ phase: sPhase.phase });
    }
    return this.queryParams.get().phase;
  }

  phaseFlag: string | null = null;
  onPhaseNameChange(phase: string) {
    if (phase) this.phaseFlag = phase;
    this.queryParams.update({ phase, offset: '0' });
  }

  created() {
    this.queryParams = new QueryParamsService<QueryType>(() => ({
      phase: this.$route.query.phase,
      offset: this.$route.query.offset,
    }));
  }

  mounted() {
    this.queryParams.initialise({ phase: '' });
  }
}
