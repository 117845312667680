








































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Button, Divider, RadioButton, RadioGroup } from 'element-ui';

import { DETAILS_CONST } from '@/store/details';
import { IEquipDetails } from '@/store/details/types';
import { QueryParamsService } from '@/utils';
import RegulatoryInf from '@/components/items-tab/panes/RegulatoryInf.vue';
import NsiInfo from '@/components/items-tab/panes/nsi-info/NsiInfo.vue';
import RegInfoView from '@/components/reg-info/reg-info-view.vue';
import FileDownloader from '../files/file-downloader.vue';
import EquipInfo from '@/components/equip-info/index.vue';
import {
  ALL_EQUIPMENT_PHASES,
  EQUIPMENT_TYPE_TABLE_CONFIG,
  REPORT_DATA,
  REPORT_DATA_COLUMNS,
  SELECTED_STATE_TAB,
  SUBSTATION_EQUIPMENT,
} from '@/store/report-data/constants';
import { PhaseConverter } from '@/utils/PhaseConverter';
import { IReportColumn, IReportTab } from '@/store/report-data/types';
import { AlgorithmScheme } from '@/store/algorithms/algorithms.types';
import { buildScheme } from '@/store/tabs/tabs.extensions';
import { IEquip } from '@/store/equipment/types';
import { EQUIP_STORE_CONST } from '@/store/equipment/constants';
import SummaryState from '@/components/summary-state/index.vue';

type QueryType = {
  phase?: string;
  offset?: string;
};

@Component({
  components: {
    RegulatoryInf,
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton,
    'el-button': Button,
    'el-divider': Divider,
    'nsi-info': NsiInfo,
    'reg-info': RegInfoView,
    'file-downloader': FileDownloader,
    'equip-info': EquipInfo,
    'summary-state': SummaryState,
  },
})
export default class Level2TopicCard extends Vue {
  queryParams!: QueryParamsService<QueryType>;
  regDialogVisible = false;

  @Prop() isContainer!: boolean;
  @Prop() rights: any;
  @Prop() isNsiShown?: boolean;
  @Prop() item!: IEquip | null;

  get isNsiShownByRights(): boolean {
    return this.rights?.normative;
  }

  @Getter(EQUIP_STORE_CONST.TREE, { namespace: EQUIP_STORE_CONST.TREE }) tree?: Node[];
  @Getter(SELECTED_STATE_TAB, { namespace: REPORT_DATA })
  stateTab?: IReportTab;
  @Getter(REPORT_DATA_COLUMNS, { namespace: REPORT_DATA })
  reportColumns?: IReportColumn[];
  @Getter(ALL_EQUIPMENT_PHASES, { namespace: REPORT_DATA })
  allContainerPhases!: number[];
  @Getter(EQUIPMENT_TYPE_TABLE_CONFIG, { namespace: REPORT_DATA })
  config?: any[];
  @Getter(SUBSTATION_EQUIPMENT, { namespace: REPORT_DATA })
  equipmentList!: any[];
  get equipment() {
    return (
      this.equipmentList?.filter(
        (x) =>
          x.substation_id === this.item?.substationId ||
          x.substation_id === (this.item as any)?.substation_id
      ) || []
    );
  }
  get summaryContainerPhases(): string | null {
    return this.allContainerPhases?.length <= 0
      ? null
      : `${this.allContainerPhases.map((i) => PhaseConverter.getLabel(i)).join(', ')}`;
  }
  get stateScheme(): AlgorithmScheme | null {
    if (!Array.isArray(this.config) || this.config.length <= 0) return null;
    return buildScheme(this.config[this.config.length - 1]);
  }

  @Getter(DETAILS_CONST.EQUIPMENT_DETAILS, { namespace: DETAILS_CONST.DETAILS })
  equipmentDetails?: IEquipDetails;

  get routePhase() {
    const { phase } = this.$route?.query;
    return phase ?? null;
  }

  get phases() {
    if (!this.routePhase && this.equipmentDetails?.statesAndPhases?.length) {
      const phase = this.equipmentDetails?.statesAndPhases[0].phaseLabel;
      if (phase) this.queryParams.update({ phase, offset: '0' }, null, false);
    }
    return this.equipmentDetails?.statesAndPhases ? this.equipmentDetails.statesAndPhases : [];
  }

  get selectedPhaseName() {
    if (!this.phases?.length) return null;
    if (!this.routePhase) return this.phases[0].phaseLabel;
    const find = this.phases.find((i) => i.phaseLabel === this.routePhase);
    if (!find) {
      const phase = this.phases[0].phaseLabel;
      this.queryParams.update({ phase, offset: '0' }, null, false);
      return phase;
    }
    return find.phaseLabel;
  }
  phaseFlag: string | null = null;
  onPhaseNameChange(phase: string) {
    if (phase) this.phaseFlag = phase;
    this.queryParams.update({ phase, offset: '0' });
  }

  created() {
    this.queryParams = new QueryParamsService<QueryType>(() => ({
      phase: this.$route.query.phase,
      offset: this.$route.query.offset,
    }));
  }

  mounted() {
    this.queryParams.initialise({ phase: '' });
  }
}
