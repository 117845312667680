import { FileFormat, IFileNameQuery } from '@/components/files/services/name-builder/types';
import { isEmptyOrSpaces } from '@/utils/string';
import moment from 'moment';

export class FileNameBuilder {
  private static __getNamePart = (value: unknown): string | null =>
    !value || isEmptyOrSpaces(`${value}`) ? null : `${value}`;
  public static ext = (path: string, extension: FileFormat): string => `${path}.${extension}`;
  public static getName({ tab, routeQuery, details }: IFileNameQuery): string {
    const fileName = [
      FileNameBuilder.__getNamePart(details?.header),
      FileNameBuilder.__getNamePart(tab?.name),
      FileNameBuilder.__getNamePart(routeQuery?.phase),
      FileNameBuilder.__getNamePart(routeQuery?.start),
      // FileNameBuilder.__getNamePart(routeQuery?.end),
      FileNameBuilder.__getNamePart(
        !routeQuery?.start ? moment(new Date()).format('DD/MM/YYYY_HH:mm:ss') : ''
      ),
    ].filter((i) => i !== null);

    return fileName.join('_');
  }
}
