

























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import TablePagination from '@/components/basics/table/TablePagination.vue';

import { mergeSort, numberSorter, getDeviation } from '../utils';
import ExportDataView from '../ExportDataView.vue';

@Component({
  components: {
    'table-pagination': TablePagination,
    'export-data': ExportDataView,
  },
})
export default class Protocol5092GroupConfiguration extends Vue {
  @Prop() assets!: { [key: string]: any }[] | null;

  sort: { order: string | null; prop: string } = { order: 'ascending', prop: 'ied' };

  get tableData() {
    const items: any[] = (this.assets ?? []).map((x) => ({
      ...x,
      ...x.source,
    }));

    const data = items.map((x) => ({
      ied: x.sensor?.protocol?.ied,
      mac: x.sensor?.protocol?.mac,
      name: x.parentEquipment?.name,
      windingName: x.Name?.value,
      windingAlias: x.Alias?.value,
      phase: x.phase === '32' ? 'A' : x.phase === '64' ? 'B' : 'C',
      voltage:
        x.NominalCurrent?.value != null
          ? x.NominalCurrent?.value != null
            ? `${x.NominalCurrent.value} ${x.unit}`
            : ''
          : x.NominalVoltage?.value
          ? `${x.NominalVoltage.value} ${x.unit}`
          : '',
      coeff: x.Ku?.value ?? x.Ki?.value,
      error: getDeviation(x),
    }));

    return data;
  }

  get total() {
    return this.tableData.length;
  }

  get sorter() {
    const configSorter = this.config.columns.find((x) => x.prop === this.sort.prop)?.sorter;

    return configSorter
      ? (x: any, y: any) =>
          this.sort.order === 'ascending'
            ? configSorter(x[this.sort.prop], y[this.sort.prop])
            : !configSorter(x[this.sort.prop], y[this.sort.prop])
      : (x: any, y: any) =>
          this.sort.order === 'ascending'
            ? x[this.sort.prop] < y[this.sort.prop]
            : x[this.sort.prop] > y[this.sort.prop];
  }

  get page() {
    const { limit, offset } = this.$route.query;

    const sorted =
      this.sort.order === null
        ? this.tableData
        : mergeSort(
            mergeSort([...this.tableData], (x: any, y: any) => x.name > y.name),
            this.sorter
          );

    return sorted.slice(Number(offset), Number(offset) + Number(limit));
  }

  getSorter(columnName: string, direction: string) {
    return (
      this.config.columns.find((x) => x.prop === columnName) ??
      ((x: any, y: any) => (direction === 'ascending' ? x < y : x > y))
    );
  }

  onSortChange(x: any) {
    this.sort = x;
  }

  config = {
    fileName: 'IEC_61850_9_2',
    columns: [
      { prop: 'ied', name: 'IED' },
      { prop: 'mac', name: 'MAC' },
      { prop: 'name', name: 'Диспетчерское наименование' },
      { prop: 'windingName', name: 'Наименование обмотки' },
      { prop: 'windingAlias', name: 'Обозначение обмотки' },
      { prop: 'phase', name: 'Фаза' },
      {
        prop: 'voltage',
        name: 'Номинальное напряжение/ток',
        sorter: (x: string, y: string) => numberSorter(x.split(' ')[0], y.split(' ')[0]),
      },
      { prop: 'coeff', name: 'Коэффициент трансформации', sorter: numberSorter },
      {
        prop: 'error',
        name: 'Погрешность канала, %',
        sorter: numberSorter,
      },
    ],
  };
}
