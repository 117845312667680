












































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import TopMenu from '@/components/top-menu/TopMenu.vue';
import { RestApiService } from '@/services';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { QueryParamsService } from '@/utils';

import UpdateJournalsTableView from './UpdateJournalsTableView.vue';
import { Equipment, Reference, Enum, Module } from './types';

type QueryType = { tab?: string };

const equipmentsPath = getApiUrl(TR_TYPE.HTTP, '/equipment');
const referencesPath = getApiUrl(TR_TYPE.HTTP, '/references');
const modulesPath = getApiUrl(TR_TYPE.HTTP, '/modules');
const enumsPath = getApiUrl(TR_TYPE.HTTP, '/algorithms-config/enums');
const rest = new RestApiService();

const tables = ['update_config_steps_packages', 'not_loaded_calculators_packages'];

@Component({
  components: {
    'top-menu': TopMenu,
    'update-journals-table-view': UpdateJournalsTableView,
  },
})
export default class UpdateJournalsView extends Vue {
  queryParams!: QueryParamsService<QueryType>;

  equipments: Equipment[] | null = null;
  references: Reference[] | null = null;
  enums: Enum[] | null = null;
  modules: Module[] | null = null;

  get selectedTab(): string {
    const { tab } = this.queryParams?.get();
    return tab || 'update_config_steps_packages';
  }

  onChangeTab(tab: any) {
    this.queryParams.update({ tab: tab.$vnode.key }, { removeExcept: [] });
  }

  async updateData() {
    const [equipments, references, enums, modules] = await Promise.all([
      rest.get<Equipment[]>(`${equipmentsPath}/all`),
      rest.put<Reference[]>(`${referencesPath}/by-names`, tables),
      rest.get<Enum[]>(`${enumsPath}`),
      rest.get<Module[]>(`${modulesPath}/all`),
    ]);

    if (
      equipments.status === 200 &&
      references.status === 200 &&
      enums.status === 200 &&
      modules.status === 200
    ) {
      this.equipments = equipments?.data;
      this.references = references?.data;
      this.enums = enums?.data;
      this.modules = modules?.data;
    }
  }

  created() {
    this.queryParams = new QueryParamsService<QueryType>(() => ({
      tab: this.$route.query.tab,
    }));
  }

  async mounted() {
    await this.updateData();
  }
}
