


















































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';

import TablePagination from '@/components/basics/table/TablePagination.vue';;

import ConfirmChanges from '@/components/confirm-changes/index.vue';
import {
  CHOOSE_ACTION_MSG,
  HAS_UNSAVED_CHANGES_MSG,
  NO_SAVE_MSG,
  SAVE_MSG,
  WARNING_MSG,
} from '@/components/confirm-changes/constants';

import { Reference, Sensor } from '../types';
import { calculatePhases, getEditableModel, getPage } from '../utils';
import ChannelNameValidator from './ChannelNameValidator/index.vue';
import { NameWithCheck } from './ChannelNameValidator/types';
import {
  findDirties,
  refreshDirtyState,
  updateSensorModel,
} from './ChannelNameValidator/extensions';
import ExportDataView from '../ExportDataView.vue';

@Component({
  components: {
    'channel-name-comparer': ChannelNameValidator,
    'confirm-changes': ConfirmChanges,
    'table-pagination': TablePagination,
    'export-data': ExportDataView,
  },
})
export default class Protocol5081File extends Vue {
  @Prop() sensorModel!: Sensor | null;
  @Prop() comTradeSensor!: Sensor | null;
  @Prop() references!: Reference[] | null;
  @Prop() editPermission!: boolean;

  sort: { order: string | null; prop: string } = { prop: 'equipment', order: 'ascending' };

  // cancel btn conformer
  showConfirm = false;
  confirmMessage = HAS_UNSAVED_CHANGES_MSG + ' ' + CHOOSE_ACTION_MSG;
  confirmTitle = WARNING_MSG;
  actionText = SAVE_MSG;
  cancelText = NO_SAVE_MSG;

  get config() {
    return {
      fileName: `IEC_60870_5_104_${this.sensorModel?.name}`,
      columns: [
        { prop: 'equipment', name: 'Оборудование' },
        { prop: 'phase', name: 'Фаза' },
        { prop: 'parameter', name: 'Параметр' },
        { prop: 'channel', name: 'Имя канала в файле' },
      ],
    };
  }

  // dialog feedback
  async handleConfirmAction(saveFlag: boolean) {
    this.showConfirm = false;
    if (saveFlag) {
      // save changes
      this.onSave();
    }
    this.isEdit = false;
    // reset all changes
    this.editable = { ...getEditableModel(this.tableData) };
  }

  isEdit = false;
  isSaving = false;
  rowsWithCheck: any[] = [];
  editable: Record<string, {}> = {};
  onChecked(data: Record<NameWithCheck, string | boolean>[]) {
    this.rowsWithCheck = this.tableData.map((d) => ({
      ...d,
      check: data?.find((i) => i.name === d.channel)?.state,
    }));
  }
  onChannelNameChange(key: string) {
    refreshDirtyState(key, 'channel', this.editable, this.tableData);
  }

  get channels(): string[] {
    return this.tableData ? this.tableData.map((i: any) => i.channel) : [];
  }

  @Watch('comTradeSensor') onComTradeSensor(val: any, old: any) {
    this.rowsWithCheck = [];
  }

  get tableData() {
    const sources = this.comTradeSensor?.sources.flatMap((x) => x);
    const data = (this.rowsWithCheck?.length
      ? this.rowsWithCheck
      : sources
      ? sources.map((x: any) => {
          return {
            uuid: x.uuid,
            equipment: x.equipment.name,
            phase: calculatePhases(x.flags),
            parameter: this.references?.find((ref) => ref.id === x.typeId)?.notes,
            channel: x.selector?.selector,
          };
        })
      : [
          {
            uuid: '',
            equipment: '',
            phase: '',
            parameter: '',
            channel: '',
          },
        ]
    ).sort((x, y) => (`${x.equipment}${x.phase}` < `${y.equipment}${y.phase}` ? -1 : 1));

    if (data?.length) {
      this.editable = getEditableModel(data);
    }
    return data;
  }

  get page() {
    return getPage(this.tableData, this.sort, this.$route.query);
  }

  onSortChange(x: any) {
    this.sort = x;
  }

  onSave() {
    const dirties = findDirties(this.editable);
    if (!dirties || !Array.isArray(dirties) || !this.comTradeSensor) {
      // skip save && exit
      this.isEdit = false;
      this.isSaving = false;
      return;
    }
    this.isSaving = true;
    const mutatedModel = updateSensorModel({ ...this.comTradeSensor }, dirties);
    if (mutatedModel) {
      this.$emit('save-sensor', mutatedModel, () => {
        this.isEdit = false;
        this.isSaving = false;
      });
    }
    this.isEdit = false;
    this.isSaving = false;
  }

  onEdit() {
    this.isEdit = true;
  }

  onCancelEdit() {
    const dirties = findDirties(this.editable);
    if (!dirties) {
      this.$emit('cancel-edit');
      this.isEdit = false;
      // reset editable model
      this.editable = { ...getEditableModel(this.tableData) };
    } else {
      this.showConfirm = true;
    }
  }
}
