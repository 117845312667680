


































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { RestApiService } from '@/services';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { QueryParamsService } from '@/utils';

import { Sensor, Reference, Equipment, Enum } from './types';
import { protocolTranslation } from './utils';
import GroupSensorsJournalView from './GroupSensorsJournalView.vue';
import Protocol5092GroupConfiguration from './5092/Protocol5092GroupConfiguration.vue';
import Protocol5104GroupConfiguration from './5104/Protocol5104GroupConfiguration.vue';
import Protocol5081GroupConfiguration from './5081/Protocol5081GroupConfiguration.vue';
import ProtocolModbusGroupConfiguration from './modbus/ProtocolModbusGroupConfiguration.vue';

type QueryType = { tab?: string };

const assetsPath = getApiUrl(TR_TYPE.HTTP, '/assets');
const rest = new RestApiService();

@Component({
  components: {
    'group-sensors-journal-view': GroupSensorsJournalView,
    'protocol-5092-group-configuration': Protocol5092GroupConfiguration,
    'protocol-5104-group-configuration': Protocol5104GroupConfiguration,
    'protocol-5081-group-configuration': Protocol5081GroupConfiguration,
    'protocol-modbus-group-configuration': ProtocolModbusGroupConfiguration,
  },
})
export default class GroupSensorsView extends Vue {
  @Prop() protocol!: string;
  @Prop() sensors!: Sensor[] | null;
  @Prop() comTradeSensors!: Sensor[] | null;
  @Prop() references!: Reference[] | null;
  @Prop() equipments!: Equipment[] | null;
  @Prop() substation!: Equipment | null;
  @Prop() enums!: Enum[] | null;

  queryParams!: QueryParamsService<QueryType>;

  assets: { [key: string]: any }[] | null = null;
  fullSources: { [key: string]: any }[] | null = null;
  protocolTranslation = protocolTranslation;

  @Watch('sensors', { immediate: true }) async onChangeSensor(
    val: Sensor[] | null | undefined,
    old: any
  ) {
    if (val) {
      this.assets = null;
      this.fullSources = null;
      this.fullSources = val.flatMap((x) => x.sources.map((y) => ({ ...y, sensor: x })));
      const sources = val.flatMap((x) => x.sources);
      const equipIds = sources.map((y) => y.equipId);
      if (equipIds.length > 0) {
        const assets = await rest.put(`${assetsPath}/equips`, equipIds);
        if (assets.status === 200) {
          this.assets = assets.data.map((x: any) => {
            const currentSource = sources.find(
              (source) => source.equipId === x.equipId && Number(source.flags) & Number(x['phase'])
            );

            return Object.fromEntries([
              ...x.props.map((prop: any) => [
                prop.Name,
                {
                  value: prop.Value,
                  type: prop.Type,
                  order: prop.Order,
                },
              ]),
              ['source', currentSource],
              ['sensor', val.find((sensor) => sensor.id === currentSource?.sensorId)],
              ['equipId', x.equipId],
              ['phase', x.phase],
              [
                'parentEquipment',
                this.equipments?.find(
                  (equipment) =>
                    equipment.id ===
                    sources.find((source) => source.equipId === x.equipId)?.equipment.parentId
                ),
              ],
            ]);
          });
        }
      }
    }
  }

  get activeTab(): string {
    const { tab } = this.queryParams?.get();
    return tab || 'configuration';
  }

  get fullComTradeSources() {
    return this.comTradeSensors?.flatMap((x) =>
      (x.sources ?? []).map((y) => ({ ...y, sensor: x }))
    );
  }

  onChangeTab(tab: any) {
    this.queryParams.update({ tab: tab.$vnode.key }, { removeExcept: [] });
  }

  created() {
    this.queryParams = new QueryParamsService<QueryType>(() => ({
      tab: this.$route.query.tab,
    }));
  }
}
