import { IDataExtract } from '@/components/files/services/types';
import { transformData } from '@/components/items-tab/panes/algorithms/algorithms.extensions';

export class DataExtractor {
  public static extractTimelessCsvData = async (
    data: any,
    scheme: any,
    refs: any,
    equipment?: any[]
  ): Promise<IDataExtract> => {
    return await new Promise((resolve, reject) => {
      try {
        const transformedData = transformData(data, scheme, refs, undefined, equipment);
        const sorted = scheme?.columns
          .filter((c: any) => c.order && c.order > 0)
          .sort((a: any, b: any) => a.order - b.order);
        const headers = sorted.map((c: any) => `${c.notes}${c.unit ? ', ' + c.unit : ''}`);
        const propsNames = sorted.map((s: any) => s.property);
        resolve({
          transformedData,
          headers,
          propsNames,
        });
      } catch (e) {
        reject(e);
      }
    });
  };
}
