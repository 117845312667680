import { render, staticRenderFns } from "./UpdateJournalsTable.vue?vue&type=template&id=53f37c2e&scoped=true&"
import script from "./UpdateJournalsTable.vue?vue&type=script&lang=ts&"
export * from "./UpdateJournalsTable.vue?vue&type=script&lang=ts&"
import style0 from "./UpdateJournalsTable.vue?vue&type=style&index=0&id=53f37c2e&prod&scoped=true&lang=scss&"
import style1 from "./UpdateJournalsTable.vue?vue&type=style&index=1&id=53f37c2e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53f37c2e",
  null
  
)

export default component.exports