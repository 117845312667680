

























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { Role, User, UserRole } from './types';

@Component({})
export default class UsersListView extends Vue {
  @Prop() role!: Role<User<UserRole>>;
  @Prop() roles!: Role<User<UserRole>>[];

  get tableData() {
    return this.role.users.map((user) => ({
      id: user.id,
      name: user.name,
      mail: user.mail,
      login: user.login,
      roles: user.roles.map((x) => this.roles.find((y) => x.roleId === y.id)).filter((x) => !!x),
    }));
  }
}
