






































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { Getter } from 'vuex-class';

import PropertiesGroup from '@/components/basics/PropertiesGroup.vue';
import { EQUIP_STORE_CONST } from '@/store/equipment/constants';
import { TreeState } from '@/store/equipment/types';
import { REPORT_DATA, NODE_TYPE_MAP, SUBSTATION_EQUIPMENT } from '@/store/report-data/constants';
import { tableFilters } from '@/components/basics/table/filters';

import { getStateStyle } from './shared-state';

@Component({
  filters: {
    ...tableFilters,
  },
  components: {
    'props-group': PropertiesGroup,
  },
})
export default class SubstationState extends Vue {
  @Prop() item: any;

  @Getter(EQUIP_STORE_CONST.TREE, { namespace: EQUIP_STORE_CONST.TREE }) tree?: Node[];
  @Getter(EQUIP_STORE_CONST.GET_TYPES, { namespace: EQUIP_STORE_CONST.TREE })
  types?: TreeState['equipmentTypes'];
  @Getter(NODE_TYPE_MAP, { namespace: REPORT_DATA }) typeMap?: Record<string, string>;
  @Getter(SUBSTATION_EQUIPMENT, { namespace: REPORT_DATA }) equipList?: any[];

  onGroupNameClick({ type }: any) {
    if (`${type}` === '-1' || `${type}` === 'substaion') return;
    const query = { ...this.$route?.query, tab: `${type}` };
    const nPath = `${this.$route.path}?${Object.entries(query)
      .map(([k, v]) => `${[k]}=${v}`)
      .join('&')}`;
    if (this.$route.fullPath === nPath) return;
    this.$router.push(nPath);
  }

  getStateStyle = getStateStyle;

  get equipmentGroups() {
    const groups: Record<string, any> = {};

    if (this.item && this.typeMap && this.equipList) {
      const notEmbeded = this.equipList.filter(
        (x) => !x.embed && x.substation_uid === this.item.uid
      );

      const rec = (node: any) => {
        const typeId = this.types?.[node.type_uid]?.id;

        const key = typeId && `${typeId}_${node.voltage}`;
        const allPhases = notEmbeded.filter((x) => x.equip_uuid === node.uid);

        if (key && this.typeMap && allPhases.length > 0) {
          if (!groups[key]) {
            groups[key] = [];
          }

          allPhases.forEach(() => groups[key].push(node));
        }

        node.children && node.children.forEach(rec);
      };

      rec(this.item);
    }

    const groupList = Object.entries(groups).map(([key, values]) => {
      const [type, voltage] = key.split('_');

      const count = values.length;
      const state = values.reduce(
        (x: number, y: any) => (y.state.state === undefined ? 0 : Math.min(x, y.state.state)),
        100
      );
      const quality = values.reduce(
        (x: number, y: any) => (x === 0 && y.state.quality === 0 ? 0 : 1),
        0
      );

      return {
        key: key,
        type: type,
        name: this.types?.[values[0].type_uid]?.name || '<НЕТ ДАННЫХ>',
        voltage: Number(voltage),
        count: count,
        state: state,
        quality: quality,
      };
    });

    const sortedGroups = groupList.sort((x, y) =>
      x.voltage > y.voltage ? -1 : x.voltage === y.voltage ? (x.type < y.type ? -1 : 1) : 1
    );

    return sortedGroups.length > 0
      ? [
          {
            key: 'substation',
            type: 'substaion',
            name: 'Подстанция',
            voltage: null,
            count: sortedGroups.reduce((x, y) => x + y.count, 0),
            state: null,
            quality: null,
          },
          ...sortedGroups,
        ]
      : [];
  }
}
