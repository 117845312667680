


































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Getter, Mutation, Action } from 'vuex-class';

import TopMenu from '@/components/top-menu/TopMenu.vue';
import CustomScrollBar from '@/components/basics/CustomScrollBar.vue';
import Resizable from '@/components/basics/Resizable.vue';
import { LAYOUT } from '@/store/layout/constants';
import { EQUIP_STORE_CONST } from '@/store/equipment/constants';
import { DICTIONARIES_CONST } from '@/store/dictionaries/dictionaries.const';
import { CONST as AUTH } from '@/store/auth/constants';
import { RestApiService } from '@/services';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { Roles } from '@/store/auth/types';

import LogsTreeView from './LogsTreeView.vue';
import LogView from './LogView.vue';
import { getCalculatorNameWithoutPrefix, getCalculatorNameWithoutSuffix } from './utils';

const rest = new RestApiService();

const windingIds = [
  '1112',
  '1095',
  '1096',
  '1097',
  '1106',
  '1109',
  '1107',
  '1110',
  '1115',
  '1113',
  '1116',
];

@Component({
  components: {
    'top-menu': TopMenu,
    'custom-scrollbar': CustomScrollBar,
    'logs-tree': LogsTreeView,
    'log-view': LogView,
    resizable: Resizable,
  },
})
export default class SystemLogsView extends Vue {
  currentRouteId: string | null = null;
  calculators: any[] | null = null;
  equipments: any[] | null = null;

  @Getter(AUTH.GET_ROLES, { namespace: AUTH.AUTH }) roles!: Roles;
  @Getter(LAYOUT.GET_DRAWER_WIDTH, { namespace: LAYOUT.LAYOUT }) drawerWidth!: number;
  @Getter(LAYOUT.GET_DRAWER_VISIBLE, { namespace: LAYOUT.LAYOUT }) drawerVisible!: boolean;
  @Getter(EQUIP_STORE_CONST.TREE, { namespace: EQUIP_STORE_CONST.TREE }) tree?: any[];
  @Getter(DICTIONARIES_CONST.TAB_REFS, { namespace: DICTIONARIES_CONST.DICTIONARIES })
  enums!: any[];

  @Mutation(LAYOUT.SET_DRAWER_WIDTH, { namespace: LAYOUT.LAYOUT }) setDrawerWidth!: Function;

  @Action(EQUIP_STORE_CONST.GET_TREE, { namespace: EQUIP_STORE_CONST.TREE }) getTree!: () => void;

  @Action(DICTIONARIES_CONST.GET_TAB_REFS, { namespace: DICTIONARIES_CONST.DICTIONARIES })
  getTabRefs!: () => void;

  @Watch('$route.params.id', { immediate: true }) changeRouteId(val?: string, old?: string) {
    if (val !== old) {
      this.currentRouteId = val || null;
    }
  }

  get editable() {
    return this.roles.logsEdit;
  }

  get rightContentWidth() {
    return this.drawerVisible ? `calc(100vw - ${this.drawerWidth + 51}px)` : '100vw';
  }

  get equipmentsTree() {
    if (this.calculators && this.tree) {
      const calculatorsHash: Record<string, any> = {};

      this.calculators.forEach((x) => {
        const equipment = this.equipments?.find((y) => y.uuid === x.equipmentUid);

        if (equipment) {
          const uid = windingIds.some((y) => y === equipment.typeId)
            ? this.equipments?.find((y) => y.id === equipment.parentId)?.uuid
            : x.equipmentUid;

          if (calculatorsHash[uid]) {
            calculatorsHash[uid].push({
              ...x,
              name: getCalculatorNameWithoutPrefix(x.moduleUid),
              id: x.moduleUid,
            });
          } else {
            calculatorsHash[uid] = [
              {
                ...x,
                name: getCalculatorNameWithoutPrefix(x.moduleUid),
                id: x.moduleUid,
              },
            ];
          }
        }
      });

      Object.values(calculatorsHash).forEach((x) => {
        x.sort((x: any, y: any) => (x.name < y.name ? -1 : 1));
      });

      const rec = (tree: any[]): any[] => {
        return tree.map((x) => {
          return calculatorsHash[x.uid]
            ? { ...x, children: [...calculatorsHash[x.uid], ...rec(x.children || [])] }
            : { ...x, children: rec(x.children || []) };
        });
      };

      const fullTree = rec(this.tree);

      const filterTree = (nodes: any[]): any[] => {
        return nodes
          .map((node) =>
            node.mpath === undefined
              ? node
              : {
                  ...node,
                  children: filterTree(node.children || []),
                }
          )
          .filter((node) => node.mpath === undefined || node.children.length > 0);
      };

      return filterTree(fullTree) ?? [];
    } else {
      return null;
    }
  }

  get currentCalculator() {
    return (
      this.calculators?.find((x) => x.moduleUid === this.$route.params.id) ??
      this.equipments?.find((x) => x.id === this.$route.params.id)
    );
  }

  groupCalculators(calculators: any[]) {
    const res: any[] = [];
    const hash: Record<string, boolean> = {};

    calculators.forEach((x) => {
      if (x.type !== 3) {
        const name = getCalculatorNameWithoutSuffix(x.moduleUid);
        if (!hash[name]) {
          hash[name] = true;
          res.push({ ...x, moduleUid: name });
        }
      } else {
        res.push(x);
      }
    });

    return res;
  }

  async updateData() {
    const [, calculators, equipments] = await Promise.all([
      this.getTree(),
      rest.get<any[]>(getApiUrl(TR_TYPE.HTTP, '/system-logs/all')),
      rest.get<any[]>(getApiUrl(TR_TYPE.HTTP, '/equipment/all')),
    ]);

    if (calculators.status === 200 && equipments.status === 200) {
      this.calculators = this.groupCalculators(calculators.data);
      this.equipments = equipments.data;
    }
  }

  mounted() {
    this.getTabRefs();
    this.updateData();
  }
}
