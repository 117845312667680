




































































































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { generateId } from '@/utils';

import { Role, User, UserRole, Organization, DeviceType } from './types';

@Component({})
export default class RoleView extends Vue {
  roleModel: {
    organization: null | number;
    name: string;
    summary: string;
    operational: string;
    history: string;
    normativeView: string;
    normativeEdit: string;
    normativeSettingsEdit: string;
    roleView: string;
    roleEdit: string;
    logsView: string;
    logsEdit: string;
    sensorsEdit: string;
    deviceTypes: number[];
    voltages: number[];
  } = {
    organization: null,
    name: '',
    summary: '0',
    operational: '0',
    history: '0',
    normativeView: '0',
    normativeEdit: '0',
    normativeSettingsEdit: '0',
    roleView: '0',
    roleEdit: '0',
    sensorsEdit: '0',
    logsView: '0',
    logsEdit: '0',
    deviceTypes: [] as number[],
    voltages: [] as number[],
  };

  devicesModel = {
    devices: [] as number[],
    isIndeterminate: false,
    checkAll: false,
  };

  voltagesModel = {
    voltages: [] as number[],
    isIndeterminate: false,
    checkAll: false,
  };

  voltages = [750, 500, 220, 110, 35, 10];

  editMode = true;
  addDeviceDialogVisible = false;
  addVoltageDialogVisible = false;

  @Prop() editable!: boolean;
  @Prop() role?: Role<User<UserRole>>;
  @Prop() user?: User<Role<UserRole>>;
  @Prop() organizations?: Organization[];
  @Prop() deviceTypes?: DeviceType[];
  @Prop() isAdding?: boolean;

  @Watch('role', { immediate: true }) updateRoleModel(
    role?: Role<User<UserRole>>,
    old?: Role<User<UserRole>>
  ) {
    if (role && role !== old) {
      this.resetRoleModel(role);
    }
  }

  @Watch('isAdding', { immediate: true }) createRole(cur?: boolean, old?: boolean) {
    if (cur && cur !== old) {
      this.roleModel = {
        organization: null,
        name: '',
        summary: '0',
        operational: '0',
        history: '0',
        normativeView: '0',
        normativeEdit: '0',
        normativeSettingsEdit: '0',
        roleView: '0',
        roleEdit: '0',
        sensorsEdit: '0',
        logsView: '0',
        logsEdit: '0',
        deviceTypes: [] as number[],
        voltages: [] as number[],
      };
    }
  }

  get isEdit() {
    return (this.role && this.editMode) || this.isAdding;
  }

  get deviceTags() {
    return this.roleModel.deviceTypes.map((x) => this.deviceTypes?.find((y) => x === y.id));
  }

  guard() {
    if (this.isEdit) {
      return new Promise((resolve) => {
        this.$confirm('Несохраненные данные будут потеряны. Продолжить?', 'Внимание!', {
          confirmButtonText: 'Oк',
          cancelButtonText: 'Отмена',
          type: 'warning',
        })
          .then(() => {
            this.role && this.resetRoleModel(this.role);
            resolve(false);
          })
          .catch(() => {
            resolve(true);
          });
      });
    }
  }

  updateDeviceIndeterminate(count: number) {
    this.devicesModel.checkAll = count === this.deviceTypes?.length;
    this.devicesModel.isIndeterminate = count > 0 && count < (this.deviceTypes?.length ?? 0);
  }

  addDeviceDialogOpen() {
    this.devicesModel.devices = this.roleModel.deviceTypes;
    this.updateDeviceIndeterminate(this.roleModel.deviceTypes.length);
    this.addDeviceDialogVisible = true;
  }

  addDeviceDialogSave() {
    this.roleModel.deviceTypes = this.devicesModel.devices;
    this.addDeviceDialogVisible = false;
  }

  addDeviceDialogClose() {
    this.addDeviceDialogVisible = false;
  }

  checkAllDevices(val: boolean) {
    this.devicesModel.devices = val ? this.deviceTypes?.map((x) => x.id) ?? [] : [];
    this.devicesModel.isIndeterminate = false;
  }

  checkDevice(val: number[]) {
    this.updateDeviceIndeterminate(val.length);
  }

  updateVoltageIndeterminate(count: number) {
    this.voltagesModel.checkAll = count === this.voltages.length;
    this.voltagesModel.isIndeterminate = count > 0 && count < this.voltages.length;
  }

  addVoltageDialogOpen() {
    this.voltagesModel.voltages = this.roleModel.voltages;
    this.updateVoltageIndeterminate(this.voltagesModel.voltages.length);
    this.addVoltageDialogVisible = true;
  }

  addVoltageDialogSave() {
    this.roleModel.voltages = this.voltagesModel.voltages;
    this.addVoltageDialogVisible = false;
  }

  addVoltageDialogClose() {
    this.addVoltageDialogVisible = false;
  }

  checkAllVoltages(val: boolean) {
    this.voltagesModel.voltages = val ? this.voltages : [];
    this.voltagesModel.isIndeterminate = false;
  }

  checkVoltage(val: number[]) {
    this.updateVoltageIndeterminate(val.length);
  }

  deleteDevice(id: number) {
    this.roleModel.deviceTypes = this.roleModel.deviceTypes.filter((x) => x !== id);
  }

  deleteVoltage(voltage: number) {
    this.roleModel.voltages = this.roleModel.voltages.filter((x) => x !== voltage);
  }

  resetRoleModel(role: Role<User<UserRole>>) {
    this.roleModel = {
      name: role.name,
      organization: role.orgId,
      deviceTypes: role.equips.map((x) => x.typeId),
      voltages: role.voltages.map((x) => x.voltage).sort((x, y) => (x > y ? -1 : 1)),
      roleView: role.rights & 0b1 ? '1' : '0',
      roleEdit: role.rights & 0b10 ? '1' : '0',
      summary: role.rights & 0b100 ? '1' : '0',
      operational: role.rights & 0b1000 ? '1' : '0',
      history: role.rights & 0b10000 ? '1' : '0',
      normativeView: role.rights & 0b100000 ? '1' : '0',
      normativeEdit: role.rights & 0b1000000 ? '1' : '0',
      normativeSettingsEdit: role.rights & 0b10000000 ? '1' : '0',
      sensorsEdit: role.rights & 0b100000000 ? '1' : '0',
      logsView: role.rights & 0b1000000000 ? '1' : '0',
      logsEdit: role.rights & 0b10000000000 ? '1' : '0',
    };

    this.editMode = false;
  }

  editClick() {
    this.editMode = true;
  }

  getRights() {
    return (
      (this.roleModel.roleView === '1' ? 0b1 : 0) |
      (this.roleModel.roleEdit === '1' ? 0b10 : 0) |
      (this.roleModel.summary === '1' ? 0b100 : 0) |
      (this.roleModel.operational === '1' ? 0b1000 : 0) |
      (this.roleModel.history === '1' ? 0b10000 : 0) |
      (this.roleModel.normativeView === '1' ? 0b100000 : 0) |
      (this.roleModel.normativeEdit === '1' ? 0b1000000 : 0) |
      (this.roleModel.normativeSettingsEdit === '1' ? 0b10000000 : 0) |
      (this.roleModel.sensorsEdit === '1' ? 0b100000000 : 0) |
      (this.roleModel.logsView === '1' ? 0b1000000000 : 0) |
      (this.roleModel.logsEdit === '1' ? 0b10000000000 : 0)
    );
  }

  validateRoleModel() {
    const validation = [];

    if (!this.getRights()) {
      validation.push('Любая роль должна содержать хотя бы одно разрешение!');
    }

    if (this.roleModel.organization === null) {
      validation.push('Должен быть выбран объект!');
    }

    if (this.roleModel.deviceTypes.length === 0) {
      validation.push('Должен быть выбран хотя бы один тип оборудования!');
    }

    if (this.roleModel.voltages.length === 0) {
      validation.push('Должен быть выбран хотя бы один уровень напряжения!');
    }

    if (this.roleModel.name.length === 0) {
      validation.push('Должно быть задано наименование роли!');
    }

    return validation;
  }

  saveRole() {
    const validation = this.validateRoleModel();

    if (validation.length === 0) {
      const newRole = {
        name: this.roleModel.name,
        orgId: this.roleModel.organization,
        equips: this.roleModel.deviceTypes.map((x) => ({ roleId: this.role?.id ?? 0, typeId: x })),
        voltages: this.roleModel.voltages.map((x) => ({ roleId: this.role?.id ?? 0, voltage: x })),
        rights: this.getRights(),
        ...(this.isAdding ? { uid: generateId(), typeId: 3 } : { id: this.role?.id }),
      };

      if (this.isAdding) {
        this.$emit('add-role', newRole);
      } else {
        this.$emit('save-role', newRole);
      }
    } else {
      this.$notify.error({
        title: 'Ошибка!',
        message: validation.join(' '),
      });
    }
  }

  deleteRole() {
    this.$confirm('Это действие полностью удалит выбранную роль. Продолжить?', 'Внимание!', {
      confirmButtonText: 'Oк',
      cancelButtonText: 'Отмена',
      type: 'warning',
    })
      .then(() => {
        this.$emit('delete-role', this.role);
      })
      .catch(() => {
        void 0;
      });
  }
}
