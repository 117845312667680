





























import Vue from 'vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { Mutation, Getter } from 'vuex-class';

import { ROUTES } from '@/router/router.constants';
import { BREADCRUMBS } from '@/store/breadcrumbs/constants';
import { SYSTEM_LOGS_STORE_CONST } from '@/store/system-logs/constants';

import { getCalculatorNameWithoutPrefix, getCalculatorNameWithoutSuffix } from './utils';

@Component({})
export default class LogsTreeView extends Vue {
  @Prop() routeId!: string | null;
  @Prop() equipmentsTree!: any[] | null;

  @Getter(SYSTEM_LOGS_STORE_CONST.EXPANDED_ITEMS, {
    namespace: SYSTEM_LOGS_STORE_CONST.SYSTEM_LOGS,
  })
  expanded!: Set<number | string>;

  @Mutation(BREADCRUMBS.SET_BREADCRUMBS, { namespace: BREADCRUMBS.BREADCRUMBS })
  setBreadcrumbs!: (x: string[]) => void;

  @Mutation(SYSTEM_LOGS_STORE_CONST.TREE_ITEMS_EXPAND, {
    namespace: SYSTEM_LOGS_STORE_CONST.SYSTEM_LOGS,
  })
  expandItems!: (keys: Set<number>) => void;
  @Mutation(SYSTEM_LOGS_STORE_CONST.TREE_ITEMS_COLLAPSE, {
    namespace: SYSTEM_LOGS_STORE_CONST.SYSTEM_LOGS,
  })
  collapseItems!: (keys: Set<number>) => void;

  @Ref('logsTree')
  readonly logsTree?: any;

  route = `${ROUTES.SYSTEM_LOGS}/`;

  filterString = '';

  defaultProps = {
    label: 'name',
    children: 'children',
  };

  get isLoading() {
    return !this.equipmentsTree;
  }

  get crumbsArray() {
    const id = this.$route.params.id;

    if (this.equipmentsTree && id) {
      return this.getCrumbs(id, this.equipmentsTree) || [];
    } else {
      return [];
    }
  }

  get routeIdWithData() {
    return {
      routeId: this.routeId,
      logsTree: this.logsTree,
      equipmentsTree: this.equipmentsTree,
    };
  }

  @Watch('routeIdWithData', { immediate: true }) onChangeLog(val: this['routeIdWithData']) {
    const { routeId, logsTree, equipmentsTree } = val;

    if (logsTree && equipmentsTree) {
      setTimeout((): void => {
        this.onChangeExpanded(this.expanded, new Set([]));
        this.logsTree.setCurrentKey(routeId);
      }, 0);
    }
  }

  @Watch('filterString') onChangeFilter(val?: string | null) {
    this.logsTree && this.logsTree.filter(val);
  }

  @Watch('equipmentsTree') onChangeEquipmentsTree() {
    setTimeout(() => {
      this.logsTree && this.logsTree.filter(this.filterString);
    }, 0);
  }

  @Watch('crumbsArray', { immediate: true }) onChangeCrumbsArray(val: any) {
    if (val) {
      const crumbs = val.map((x: any) => x.name);

      if (crumbs.length > 0) {
        this.setBreadcrumbs(crumbs);
      } else {
        this.setBreadcrumbs([]);
      }
    }
  }

  @Watch('expanded')
  onChangeExpanded(newExpanded: Set<number | string>, oldExpanded: Set<number | string>) {
    const nodes = this.logsTree?.store.nodesMap || {};
    const toAdd = [...newExpanded].filter((x) => !oldExpanded.has(x));
    const toDelete = [...oldExpanded].filter((x) => !newExpanded.has(x));

    toDelete.forEach((x) => {
      const node = nodes[x];
      if (node && node.expanded) {
        node.expanded = false;
      }
    });

    toAdd.forEach((x) => {
      const node = nodes[x];
      if (node && !node.expanded) {
        node.expanded = true;
      }
    });
  }

  getCrumbs(node: string, nodes: any[]): any[] | null {
    const targetNode = nodes.find((x) => x.id === node);
    if (targetNode) {
      return [targetNode];
    } else {
      return nodes
        .map((x) => {
          const crumbs = this.getCrumbs(node, x.children || []);
          return crumbs ? [x, ...crumbs] : null;
        })
        .filter((x) => !!(x && x.length))[0];
    }
  }

  onNodeExpand(data: any) {
    this.expandItems(new Set([data.id]));
  }

  onNodeCollapse(data: any) {
    this.collapseItems(new Set([data.id]));
  }

  getCurrentNode(id: string, nodes: any[]): any[] | null {
    const rec = (lst: any[]): any => {
      return lst.find((x) => x.id === id) || lst.map((x) => rec(x.children || [])).find((x) => !!x);
    };

    return rec(nodes);
  }

  filterNode(value: string | null, data: any) {
    if (!value) {
      return true;
    }
    const val = value.toLowerCase();
    return data.name.toLowerCase().includes(val);
  }

  nodeClick(item: any) {
    const current = this.$route.params.id;

    if (current !== item.id) {
      this.$router.push(`${this.route}${item.id}`).catch((err: any) => void 0);
    }
  }

  beforeDestroy() {
    this.setBreadcrumbs([]);
  }
}
