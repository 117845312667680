



















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import TablePagination from '@/components/basics/table/TablePagination.vue';

import ExportDataView from '../ExportDataView.vue';
import { calculatePhases, mergeSort, numberSorter, getDeviation, getOffset } from '../utils';

@Component({
  components: {
    'table-pagination': TablePagination,
    'export-data': ExportDataView,
  },
})
export default class Protocol5104GroupConfiguration extends Vue {
  @Prop() sources!: any[] | null;

  sort: { order: string | null; prop: string } = { order: 'ascending', prop: 'ied' };

  get tableData() {
    const data = (this.sources || []).map((x) => ({
      ied: x.sensor?.protocol?.ied,
      ip: x.sensor?.protocol?.ip,
      port: x.sensor?.protocol?.port,
      equipName: x.equipment.name,
      phase: calculatePhases(x.flags),
      parameter: x.type,
      scale: x.scale,
      offset: getOffset(x),
      substValue: x.selector.x_subst,
      substQuality: x.selector.q_subst,
      substTime: x.selector.time_subst,
      asdu: x.sensor?.protocol?.appid,
      infoObject: x.selector.selector,
      error: getDeviation(x),
    }));

    return data;
  }

  get total() {
    return this.tableData.length;
  }

  get sorter() {
    const configSorter = this.config.columns.find((x) => x.prop === this.sort.prop)?.sorter;

    return configSorter
      ? (x: any, y: any) =>
          this.sort.order === 'ascending'
            ? configSorter(x[this.sort.prop], y[this.sort.prop])
            : !configSorter(x[this.sort.prop], y[this.sort.prop])
      : (x: any, y: any) =>
          this.sort.order === 'ascending'
            ? x[this.sort.prop] < y[this.sort.prop]
            : x[this.sort.prop] > y[this.sort.prop];
  }

  get page() {
    const { limit, offset } = this.$route.query;

    const sorted =
      this.sort.order === null
        ? this.tableData
        : mergeSort(
            mergeSort([...this.tableData], (x: any, y: any) => x.sourceName > y.sourceName),
            this.sorter
          );

    return sorted.slice(Number(offset), Number(offset) + Number(limit));
  }

  onSortChange(x: any) {
    this.sort = x;
  }

  config = {
    fileName: 'IEC_60870_5_104',
    columns: [
      { prop: 'ied', name: 'IED' },
      { prop: 'ip', name: 'IP-адрес' },
      { prop: 'port', name: 'Порт', sorter: numberSorter },
      { prop: 'equipName', name: 'Диспетчерское наименование' },
      { prop: 'phase', name: 'Фаза' },
      { prop: 'parameter', name: 'Параметр' },
      { prop: 'asdu', name: 'Общий адрес ASDU', sorter: numberSorter },
      { prop: 'infoObject', name: 'Адрес объекта информации', sorter: numberSorter },
      { prop: 'error', name: 'Погрешность канала', sorter: numberSorter },
      { prop: 'scale', name: 'Масштаб', sorter: numberSorter },
      { prop: 'offset', name: 'Смещение', sorter: numberSorter },
      { prop: 'substValue', name: 'Замещающее значение', sorter: numberSorter },
      { prop: 'substQuality', name: 'Описатель качества', sorter: numberSorter },
      { prop: 'substTime', name: 'Задержка применения', sorter: numberSorter },
    ],
  };
}
