













import { Component, Watch, Prop } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import Vue from 'vue';
import { RadioGroup, RadioButton } from 'element-ui';

import { ALGORITHM_DATA_CONST } from '@/store/abstract/algorithm-data/constants';
import { IAlgorithmQuery } from '@/store/algorithms/algorithms.types';
import { TableCol } from '@/components/basics/table/basic-table.types';
import { transformData } from '@/components/items-tab/panes/algorithms/algorithms.extensions';
import DateTimeSelector from '@/components/basics/selectors/DateTimeSelector.vue';
import { ALGORITHM_CONST } from '@/store/algorithms';
import { PRELOADER_STATE } from '@/store/abstract/preloader';
import { IPreloader } from '@/store/abstract/preloader/types';

import LogsTable from './LogsTable.vue';

@Component({
  components: {
    'logs-table': LogsTable,
    'datetime-selector': DateTimeSelector,
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton,
  },
})
export default class LogsTableView extends Vue {
  tableName = 'logs_package';

  phases = ['A', 'B', 'C'];

  config = [
    {
      Name: 'ts_nano',
      Type: 'DateTime',
      Notes: 'Дата, время',
      Mandatory: true,
      Order: 0,
      Format: '.ms',
      Scale: 1e-9,
      Sort: 'desc',
    },
    {
      Name: 'message',
      Type: 'String',
      Notes: 'Сообщение',
      Mandatory: true,
      Order: 1,
    },
    {
      Name: 'report',
      Type: 'String',
      Notes: 'Отчет',
      Mandatory: true,
      Filter: 'enum',
      Order: 2,
    },
    {
      Name: 'value',
      Type: 'String',
      Notes: 'Значение',
      Mandatory: true,
      Filter: 'enum',
      Order: 3,
    },
  ];

  scheme: any = {
    dbTableName: this.tableName,
    columns: this.config.map((x: any) => ({ ...x, dbProps: x.Name, order: x.Order })) ?? [],
  };

  columns: TableCol[] = this.config.map((x: any) => ({
    ...x,
    header: x.Notes,
    dbProps: x.Name,
    property: x.Name,
    width: x.Notes.length * 11 + 50,
  }));

  @Prop() calculator: any;

  @Getter(ALGORITHM_DATA_CONST.DATA, { namespace: 'algorithms' })
  $pageData?: any;

  @Getter(PRELOADER_STATE, { namespace: ALGORITHM_CONST.ALGORITHMS })
  preloader?: IPreloader;

  @Action(ALGORITHM_DATA_CONST.LOAD_DATA, { namespace: 'algorithms' })
  $getData!: (payload: IAlgorithmQuery) => void;

  @Mutation(ALGORITHM_DATA_CONST.DATA_LOADED, { namespace: 'algorithms' })
  $setData!: (x: any) => void;

  @Watch('query', { immediate: true }) onQueryChange(val: IAlgorithmQuery | null) {
    if (val && val.start) {
      const { limit, offset } = val;
      if (!limit || !offset) return;
      this.$getData(val);
    }
  }

  get selectedPhase() {
    const { phase } = this.$route?.query;
    return phase ?? null;
  }

  get preloaderState(): boolean {
    return this.preloader?.isBusy ?? false;
  }
  get preloaderMessage(): string {
    return this.preloader?.message ?? '';
  }

  get query(): any {
    return {
      ...this.$route.query,
      phase: undefined,
      calculator_name: `${this.calculator?.moduleUid}${
        this.calculator?.type === 1
          ? `:${this.$route.query.phase}`
          : this.calculator?.type === 2
          ? ':ABC'
          : ''
      }`,
      algorithm: this.tableName,
    };
  }

  get rangeQuery() {
    return {
      calculator_uuid: this.query.calculator_name,
    };
  }

  get total() {
    return this.$pageData?.count || 0;
  }

  get page() {
    if (!this.$pageData?.selectedPage) return [];

    console.log(this.$pageData.selectedPage, transformData(this.$pageData.selectedPage, this.scheme, []));
    const p = transformData(this.$pageData.selectedPage, this.scheme, []);

    return p ?? [];
  }

  beforeDestroy() {
    this.$setData({ count: 0, data: [] });
  }
}
