






















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { LAYOUT } from '@/store/layout/constants';

@Component
export default class ContentBuilder extends Vue {
  @Getter(LAYOUT.GET_DRAWER_VISIBLE, { namespace: LAYOUT.LAYOUT })
  drawerVisible?: any;
}
