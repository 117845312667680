var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('confirm-changes',{attrs:{"action-text":_vm.actionText,"cancel-text":_vm.cancelText,"title":_vm.confirmTitle,"message":_vm.confirmMessage,"show-hide":_vm.showConfirm},on:{"on-confirm":_vm.handleConfirmAction}}),_c('el-dialog',{attrs:{"custom-class":"reg-dialog","width":"70%","title":"Нормативно-справочная информация","close-on-click-modal":!_vm.isEdit,"visible":_vm.isEditDialogShown,"before-close":_vm.closeDialog},on:{"closed":_vm.handleClosed}},[_c('el-row',[_c('div',{staticClass:"nsi-tabs",attrs:{"disabled":_vm.preloader ? _vm.preloader.isBusy : false}},[(_vm.isEmpty)?_c('empty-data',{attrs:{"icon":"el-icon-document-delete","message":"Нет данных."}}):_c('div',{staticClass:"content-container"},[_c('div',{staticClass:"tab-container"},[_c('el-tabs',_vm._l((_vm.tabs),function(tab){return _c('el-tab-pane',{key:tab.key,attrs:{"label":tab.title}},[_c('el-table',{attrs:{"stripe":"","data":tab.items,"span-method":_vm.spanMethod}},[_c('el-table-column',{attrs:{"width":"300","prop":"note","label":"Наименование"}}),_c('el-table-column',{attrs:{"label":"Значение"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                          _vm.nsiEditModel[scope.row.key] &&
                            _vm.nsiEditModel[scope.row.key].type === 'ObjectTable'
                        )?_c('div',[_c('el-table',{staticClass:"inner-table",attrs:{"data":scope.row.value}},[_c('el-table-column',{attrs:{"prop":"Duration","label":"Длительность, с"},scopedSlots:_vm._u([{key:"default",fn:function(tableScope){return [(_vm.isEdit)?_c('div',[_c('el-input-number',{attrs:{"size":"mini","controls":false},on:{"change":function($event){return _vm.handleTableNumberChange(
                                      arguments,
                                      scope.row.key,
                                      tableScope.$index,
                                      'Duration'
                                    )}},model:{value:(
                                    _vm.nsiEditModel[scope.row.key].value[tableScope.$index][
                                      'Duration'
                                    ]
                                  ),callback:function ($$v) {_vm.$set(_vm.nsiEditModel[scope.row.key].value[tableScope.$index], 
                                      'Duration'
                                    , $$v)},expression:"\n                                    nsiEditModel[scope.row.key].value[tableScope.$index][\n                                      'Duration'\n                                    ]\n                                  "}})],1):_c('div',[_vm._v(" "+_vm._s(scope.row.value[tableScope.$index]['Duration'])+" ")])]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"VoltageCool","label":"Напряжение (холодное), о.е."},scopedSlots:_vm._u([{key:"default",fn:function(tableScope){return [(_vm.isEdit)?_c('div',[_c('el-input-number',{attrs:{"size":"mini","controls":false},on:{"change":function($event){return _vm.handleTableNumberChange(
                                      arguments,
                                      scope.row.key,
                                      tableScope.$index,
                                      'VoltageCool'
                                    )}},model:{value:(
                                    _vm.nsiEditModel[scope.row.key].value[tableScope.$index][
                                      'VoltageCool'
                                    ]
                                  ),callback:function ($$v) {_vm.$set(_vm.nsiEditModel[scope.row.key].value[tableScope.$index], 
                                      'VoltageCool'
                                    , $$v)},expression:"\n                                    nsiEditModel[scope.row.key].value[tableScope.$index][\n                                      'VoltageCool'\n                                    ]\n                                  "}})],1):_c('div',[_vm._v(" "+_vm._s(scope.row.value[tableScope.$index]['VoltageCool'])+" ")])]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"VoltageHot","label":"Напряжение (горячее), о.е."},scopedSlots:_vm._u([{key:"default",fn:function(tableScope){return [(_vm.isEdit)?_c('div',[_c('el-input-number',{attrs:{"size":"mini","controls":false},on:{"change":function($event){return _vm.handleTableNumberChange(
                                      arguments,
                                      scope.row.key,
                                      tableScope.$index,
                                      'VoltageHot'
                                    )}},model:{value:(
                                    _vm.nsiEditModel[scope.row.key].value[tableScope.$index][
                                      'VoltageHot'
                                    ]
                                  ),callback:function ($$v) {_vm.$set(_vm.nsiEditModel[scope.row.key].value[tableScope.$index], 
                                      'VoltageHot'
                                    , $$v)},expression:"\n                                    nsiEditModel[scope.row.key].value[tableScope.$index][\n                                      'VoltageHot'\n                                    ]\n                                  "}})],1):_c('div',[_vm._v(" "+_vm._s(scope.row.value[tableScope.$index]['VoltageHot'])+" ")])]}}],null,true)})],1)],1):(_vm.isEdit && _vm.nsiEditModel[scope.row.key])?_c('div',[(_vm.nsiEditModel[scope.row.key].type === 'DateTime')?_c('el-date-picker',{attrs:{"type":"datetime","format":"MM/dd/yyyy hh:mm:ss A","size":"mini"},on:{"change":function($event){return _vm.onDateTimeChange(arguments, scope.row.key)}},model:{value:(_vm.nsiEditModel[scope.row.key].value),callback:function ($$v) {_vm.$set(_vm.nsiEditModel[scope.row.key], "value", $$v)},expression:"nsiEditModel[scope.row.key].value"}}):_vm._e(),(
                            _vm.hasBoundaries(scope.row.key) &&
                              (_vm.nsiEditModel[scope.row.key].type === 'Integer' ||
                                _vm.nsiEditModel[scope.row.key].type === 'Float')
                          )?_c('el-tooltip',{attrs:{"content":'от ' +
                              _vm.nsiEditModel[scope.row.key].min +
                              ' до ' +
                              _vm.nsiEditModel[scope.row.key].max,"effect":"dark","placement":"top-end"}},[(
                              _vm.nsiEditModel[scope.row.key].type === 'Integer' ||
                              _vm.nsiEditModel[scope.row.key].type === 'Float'
                            )?_c('el-input-number',{attrs:{"size":"mini","precision":_vm.nsiEditModel[scope.row.key].type === 'Integer' ? 0 : undefined,"max":_vm.nsiEditModel[scope.row.key].max + _vm.delta(scope.row),"min":_vm.nsiEditModel[scope.row.key].min - _vm.delta(scope.row),"controls":false},on:{"change":function($event){return _vm.handleNumberChange(arguments, scope.row.key)}},model:{value:(_vm.nsiEditModel[scope.row.key].value),callback:function ($$v) {_vm.$set(_vm.nsiEditModel[scope.row.key], "value", $$v)},expression:"nsiEditModel[scope.row.key].value"}}):_vm._e()],1):_vm._e(),(!_vm.hasBoundaries(scope.row.key) &&
                           ( _vm.nsiEditModel[scope.row.key].type === 'Integer' ||
                              _vm.nsiEditModel[scope.row.key].type === 'Float')
                          )?_c('el-input-number',{attrs:{"size":"mini","precision":_vm.nsiEditModel[scope.row.key].type === 'Integer' ? 0 : undefined,"controls":false},on:{"change":function($event){return _vm.handleNumberChange(arguments, scope.row.key)}},model:{value:(_vm.nsiEditModel[scope.row.key].value),callback:function ($$v) {_vm.$set(_vm.nsiEditModel[scope.row.key], "value", $$v)},expression:"nsiEditModel[scope.row.key].value"}}):_vm._e(),(_vm.nsiEditModel[scope.row.key].type === 'Enum')?_c('el-select',{attrs:{"size":"mini"},on:{"change":function($event){return _vm.handleSelectChanged(arguments, scope.row.key)}},model:{value:(_vm.nsiEditModel[scope.row.key].value),callback:function ($$v) {_vm.$set(_vm.nsiEditModel[scope.row.key], "value", $$v)},expression:"nsiEditModel[scope.row.key].value"}},_vm._l((_vm.nsiEditModel[scope.row.key].options),function(item){return _c('el-option',{key:item,attrs:{"label":item,"value":item}})}),1):_vm._e()],1):_c('div',[(scope.row.value)?_c('span',{staticClass:"bold"},[_vm._v(" "+_vm._s(_vm._f("transform")(scope.row.value,scope.row))+" ")]):_c('span',{staticClass:"bold"},[_vm._v("Нет данных")])])]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"measure","label":"Единица измерения"}})],1)],1)}),1)],1),(_vm.isEditable)?_c('div',{staticClass:"button-container"},[(!_vm.isEdit)?_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.startEdit()}}},[_vm._v("Редактировать")]):_c('icon-btn',{attrs:{"dirty":_vm.hasDirtyItems,"type":_vm.hasDirtyItems ? 'primary' : null,"busy":_vm.preloader ? _vm.preloader.isBusy : false,"content":_vm.preloader ? (_vm.preloader.isBusy ? _vm.BTN_LOADING : _vm.BTN_SAVE) : _vm.BTN_SAVE,"icon":"las la-save"},on:{"click":_vm.onSaveHandle}}),_c('el-button',{attrs:{"size":"small","disabled":!_vm.isEdit},on:{"click":function($event){return _vm.cancelEdit()}}},[_vm._v("Отмена")])],1):_vm._e()])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }