

















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import TablePagination from '@/components/basics/table/TablePagination.vue';

import ExportDataView from '../ExportDataView.vue';
import { Reference } from '../types';
import { calculatePhases, mergeSort, numberSorter, getOffset, getDeviation } from '../utils';

@Component({
  components: {
    'table-pagination': TablePagination,
    'export-data': ExportDataView,
  },
})
export default class Protocol5081GroupConfiguration extends Vue {
  @Prop() sources!: any[] | null;
  @Prop() comTradeSources!: any[] | null;
  @Prop() references!: Reference[] | null;
  @Prop() assets!: { [key: string]: any }[] | null;

  sort: { order: string | null; prop: string } = { order: 'ascending', prop: 'ied' };
  get tableData() {
    const assetsMap = this.assets?.reduce((acc, x) => ({
      ...acc,
      [x.equipId]: x
    }), {});

    const data = (this.sources || []).map((x) => {
      const [report, dataSet, position, attribute] = (x.selector.selector ?? '').split(';');

      const comTradeSource = (this.comTradeSources || []).find(
        (y) =>
          x.sensor?.protocol?.ied === y.sensor?.protocol?.ied &&
          x.sensor?.protocol?.ip === y.sensor?.protocol?.ip &&
          x.sensor?.protocol?.port === y.sensor?.protocol?.port &&
          x.equipment.name === y.equipment.name &&
          x.flags === y.flags
      );

      return {
        ied: x.sensor?.protocol?.ied,
        ip: x.sensor?.protocol?.ip,
        port: x.sensor?.protocol?.port,
        name: x.equipment.name,
        windingAlias: assetsMap?.[x.equipId]?.Alias?.value,
        phase: calculatePhases(x.flags),
        parameter: this.references?.find((ref) => ref.id === x.typeId)?.notes,
        report: report,
        dataSet: dataSet,
        position: position,
        attribute: attribute,
        type: x.sensor.protocol.appid,
        error: getDeviation(x),
        scale: x.scale,
        offset: getOffset(x),
        substValue: x.selector.x_subst,
        substQuality: x.selector.q_subst,
        substTime: x.selector.time_subst,
        channel: comTradeSource?.selector?.selector ?? '',
      };
    });

    return data;
  }

  get total() {
    return this.tableData.length;
  }

  get sorter() {
    const configSorter = this.config.columns.find((x) => x.prop === this.sort.prop)?.sorter;

    return configSorter
      ? (x: any, y: any) =>
          this.sort.order === 'ascending'
            ? configSorter(x[this.sort.prop], y[this.sort.prop])
            : !configSorter(x[this.sort.prop], y[this.sort.prop])
      : (x: any, y: any) =>
          this.sort.order === 'ascending'
            ? x[this.sort.prop] < y[this.sort.prop]
            : x[this.sort.prop] > y[this.sort.prop];
  }

  get page() {
    const { limit, offset } = this.$route.query;

    const sorted =
      this.sort.order === null
        ? this.tableData
        : mergeSort(
            mergeSort([...this.tableData], (x: any, y: any) => x.name > y.name),
            this.sorter
          );

    return sorted.slice(Number(offset), Number(offset) + Number(limit));
  }

  onSortChange(x: any) {
    this.sort = x;
  }

  config = {
    fileName: 'IEC_61850_8_1',
    columns: [
      { prop: 'ied', name: 'IED' },
      { prop: 'ip', name: 'IP-адрес' },
      { prop: 'port', name: 'Порт', sorter: numberSorter },
      { prop: 'name', name: 'Диспетчерское наименование' },
      { prop: 'windingAlias', name: 'Обозначение обмотки' },
      { prop: 'phase', name: 'Фаза' },
      { prop: 'parameter', name: 'Параметр' },
      { prop: 'scale', name: 'Масштаб', sorter: numberSorter },
      { prop: 'offset', name: 'Смещение', sorter: numberSorter },
      { prop: 'substValue', name: 'Замещающее значение', sorter: numberSorter },
      { prop: 'substQuality', name: 'Описатель качества', sorter: numberSorter },
      { prop: 'substTime', name: 'Задержка применения', sorter: numberSorter },
      { prop: 'report', name: 'Отчет' },
      { prop: 'dataSet', name: 'Набор данных' },
      { prop: 'position', name: 'Положение' },
      { prop: 'attribute', name: 'Атрибут данных' },
      { prop: 'type', name: 'Тип данных' },
      { prop: 'error', name: 'Погрешность канала', sorter: numberSorter },
      { prop: 'channel', name: 'Имя канала в файле' },
    ],
  };
}
