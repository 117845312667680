import moment from 'moment';
import XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';

import { LocaleManager } from '@/services/locale/locale-manager';
import { transformData } from '@/components/items-tab/panes/algorithms/algorithms.extensions';
import { MOMENT_FORMAT } from '@/utils/datetime/DateTimeRangePresets';

const getChartRow = (item: any, fields: string[]): string => {
  return [
    ...fields.map((i) => {
      const j = i.split(',');
      if (j?.length === 2) {
        return item[j[0]] ?? '';
      } else {
        return item.time ?? '';
      }
    }),
  ].join(';');
};

const getChartRow2 = (item: any, fields: string[]): string => {
  return [...fields.map((i) => item[i])].join(';');
};

const parseData = (item: any): string => {
  return Object.values(item).join(';');
};

const csvSimpleContentGenerator = (dataset: any) => {
  const { data, fields, headers } = dataset;
  let csvContent = '';
  csvContent += [headers.join(';'), ...data.map((i: any) => parseData(i))].join('\n');
  return URL.createObjectURL(
    new Blob(['\ufeff' + csvContent], { type: ` text/csv;charset=${LocaleManager.getCharset()}` })
  );
};

const csvContentGenerator2 = (dataSet: any) => {
  const { data, fields, headers, interval } = dataSet;
  let csvContent = '';
  csvContent += [headers.join(';'), ...data.map((i: any) => getChartRow2(i, fields))].join('\n');

  return URL.createObjectURL(
    new Blob(['\ufeff' + csvContent], { type: ` text/csv;charset=${LocaleManager.getCharset()}` })
  );
};
const csvContentGenerator = (dataSet: any) => {
  const { data, fields, headers, interval } = dataSet;
  let csvContent = '';
  csvContent += [
    headers.join(';'),
    ...data.map((i: any) =>
      interval === 'ms' ? getChartRow(i, headers) : getChartRow2(i, fields)
    ),
  ].join('\n');

  return URL.createObjectURL(
    new Blob(['\ufeff' + csvContent], { type: ` text/csv;charset=${LocaleManager.getCharset()}` })
  );
};

const pdfContentGenerator = (dataSet: any) => {
  const { data, fields, headers, interval } = dataSet;

  return pdfMake.createPdf({
    content: {
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        body: [
          headers,
          ...data.map((item: any) =>
            interval === 'ms'
              ? fields.map((field: any) => {
                  const j = field.split(',');
                  return j?.length === 2 ? item[j[0]] ?? '' : item.time ?? '';
                })
              : fields.map((field: any) => item[field] ?? '')
          ),
        ],
      },
    },
    pageOrientation: 'landscape',
  });
};

const xlsContentGenerator2 = (dataSet: any) => {
  const { data, fields, headers } = dataSet;

  const xlsData = [headers, ...data.map((item: any) => fields.map((field: any) => item[field]))];

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(xlsData);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Данные');

  return workbook;
};

const xlsContentGenerator = (dataSet: any) => {
  const { data, fields, headers, interval } = dataSet;

  const xlsData = [
    headers,
    ...data.map((item: any) =>
      interval === 'ms'
        ? headers.map((field: any) => {
            const j = field.split(',');
            return j?.length === 2 ? item[j[0]] ?? '' : item.time ?? '';
          })
        : fields.map((field: any) => item[field])
    ),
  ];

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(xlsData);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Данные');

  return workbook;
};

export const getTableContent = (
  data: any,
  scheme: any,
  refs: any,
  query: any,
  contentGenerator: (x: any) => any
) => {
  if (!scheme || !refs) return;

  const transformed = transformData(data, scheme, refs);
  if (!transformed?.length) return;

  const headers = scheme.columns
    .filter((i: any) => i.order && i.order > 0)
    .map((c: any) => `${c.notes}${c.unit ? ', ' + c.unit : ''}`);

  const name = `${query?.algorithm}_${moment(query?.start).format(MOMENT_FORMAT.secAlter)}_${moment(
    query?.end
  ).format(MOMENT_FORMAT.secAlter)}`;

  return contentGenerator({
    data: transformed,
    fields: query?.select?.split(',') ?? [],
    headers,
    name,
    interval: query?.interval ?? '',
  });
};

export const getSimpleCsvContent = (data: any, scheme: any, refs: any, query: any) => {
  return getTableContent(data, scheme, refs, query, csvSimpleContentGenerator);
};

export const getTableCsvContent = (data: any, scheme: any, refs: any, query: any) => {
  return getTableContent(data, scheme, refs, query, csvContentGenerator);
};

export const getTablePdfContent = (data: any, scheme: any, refs: any, query: any) => {
  return getTableContent(data, scheme, refs, query, pdfContentGenerator);
};

export const getTableXlsContent = (data: any, scheme: any, refs: any, query: any) => {
  return getTableContent(data, scheme, refs, query, xlsContentGenerator);
};

export const getChartCsvContent = (dataSet: any) => {
  return csvContentGenerator2(dataSet);
};

export const getChartXlsContent = (dataSet: any) => {
  return xlsContentGenerator2(dataSet);
};
