
















































































































































import Vue from 'vue';
import { Component, Prop, Watch, Ref } from 'vue-property-decorator';

import { RestApiService } from '@/services';
import { getApiUrl, TR_TYPE } from '@/store/tools';

import { Role, User, UserRole } from './types';

const rest = new RestApiService();

@Component({})
export default class DataAccessView extends Vue {
  @Prop() user!: User<Role<UserRole>>;

  @Ref('dataAccessTree')
  readonly dataAccessTree?: any;

  deviceTree: any[] = [];
  selectedDevice: any = null;
  filterText = '';
  isLoading = false;

  defaultProps = {
    label: 'name',
    children: 'children',
  };

  roleModel = {
    summary: '0',
    operational: '0',
    history: '0',
    normativeView: '0',
    normativeEdit: '0',
    normativeSettingsEdit: '0',
    logsView: '0',
    logsEdit: '0',
  };

  @Watch('filterText') async onChangeFilter(val?: string) {
    this.dataAccessTree && this.dataAccessTree.filter(val);
  }

  @Watch('deviceTree') async onChangeDeviceTree() {
    setTimeout(() => {
      this.dataAccessTree && this.dataAccessTree.filter(this.filterText);
    }, 0);
  }

  @Watch('user', { immediate: true }) async onChangeUser(
    cur?: User<Role<UserRole>>,
    prev?: User<Role<UserRole>>
  ) {
    if (cur && cur !== prev) {
      this.isLoading = true;
      this.deviceTree = [];

      const result = await rest.get(
        getApiUrl(TR_TYPE.HTTP, `/equipment/userTree?userId=${cur.id}`)
      );

      this.isLoading = false;

      if (result.status === 200) {
        this.deviceTree = result.data;
        this.selectedDevice = null;

        this.roleModel = {
          summary: '0',
          operational: '0',
          history: '0',
          normativeView: '0',
          normativeEdit: '0',
          normativeSettingsEdit: '0',
          logsView: '0',
          logsEdit: '0',
        };
      }
    }
  }

  filterNode(value: any, data: any) {
    if (!value) return true;
    return data?.name?.toLowerCase().indexOf(value.toLowerCase()) > -1;
  }

  deviceClick(device: any) {
    this.selectedDevice = device;

    this.roleModel = {
      summary: device.rights & 0b100 ? '1' : '0',
      operational: device.rights & 0b1000 ? '1' : '0',
      history: device.rights & 0b10000 ? '1' : '0',
      normativeView: device.rights & 0b100000 ? '1' : '0',
      normativeEdit: device.rights & 0b1000000 ? '1' : '0',
      normativeSettingsEdit: device.rights & 0b10000000 ? '1' : '0',
      logsView: device.rights & 0b100000000 ? '1' : '0',
      logsEdit: device.rights & 0b1000000000 ? '1' : '0',
    };
  }
}
