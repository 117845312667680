




























import { Component, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { Action, Getter } from 'vuex-class';

import Level2TopicCard from '@/components/equipment-details/Level2TopicCard.vue';
import Level1TopicCard from '@/components/equipment-details/Level1TopicCard.vue';
import Level0TopicCard from '@/components/equipment-details/Level0TopicCard.vue';
import Level0ContentCard from '@/components/equipment-details/Level0ContentCard.vue';
import { DETAILS_CONST } from '@/store/details';
import EmptyData from '@/components/basics/EmptyData.vue';
import { EQUIP_STORE_CONST } from '@/store/equipment/constants';
import { GroupType, INode } from '@/store/equipment/types';
import DynamicTabView from '@/components/items-tab/dynamic-tab/dynamic-tab.view.vue';
import ContentBuilder from '@/components/dynamics/ContentBuilder.vue';
import { REG_INFO_CONST } from '@/store/reg-info/reg-info.const';
import Level0TopicCard2 from '@/components/equipment-details/level0-topic-card2.vue';
import Level0ContentCard2 from '@/components/equipment-details/level0-content-card2.vue';
import { NODE_TYPE_MAP, REPORT_DATA } from '@/store/report-data/constants';

@Component({
  components: {
    'level0-topic-card2': Level0TopicCard2,
    'level0-content-card2': Level0ContentCard2,
    'topic-card-0': Level0TopicCard,
    'content-card-0': Level0ContentCard,
    'topic-card-1': Level1TopicCard,
    'topic-card-2': Level2TopicCard,
    'content-builder': ContentBuilder,
    'dynamic-tab': DynamicTabView,
    'empty-data': EmptyData,
  },
})
export default class DetailsContent extends Vue {
  @Getter(EQUIP_STORE_CONST.TREE, { namespace: EQUIP_STORE_CONST.TREE }) tree?: INode[];

  @Getter(REG_INFO_CONST.REG_INFO_TABS, { namespace: REG_INFO_CONST.REG_INFO })
  regInfoTabs!: any[];

  @Getter(NODE_TYPE_MAP, { namespace: REPORT_DATA })
  nodeTypeMap!: any;
  get equipId() {
    const { id } = this.$route.params;
    return id;
  }
  get isContainer(): boolean {
    return  this.nodeTypeMap[this.equipId as string] === 'container';
    //&& this.selectedItem?.groupType !== GroupType.bay;
  }

  get isNsiShown(): boolean {
    return Array.isArray(this.regInfoTabs) && this.regInfoTabs?.length > 0;
  }

  get selectedItem(): INode | null {
    const id = this.$route.params.id;
    if (!id || !this.tree?.length) return null;
    const numId = Number(id);
    const rec = (tree: INode[]): INode | null => {
      return (
        tree.find((x) => `${x.id}` === `${numId}`) ||
        tree.map((x) => rec(x.children)).find((x) => !!x) ||
        null
      );
    };

    return rec(this.tree);
  }

  get rights() {
    const item = this.selectedItem;
    return item
      ? {
          summary: item.rights & 0b100 ? true : false,
          operational: item.rights & 0b1000 ? true : false,
          history: item.rights & 0b10000 ? true : false,
          normative: item.rights & 0b100000 ? true : false,
        }
      : null;
  }

  @Action(DETAILS_CONST.GET_DETAILS, { namespace: DETAILS_CONST.DETAILS })
  getDetails: any;

  @Watch('selectedItem', { immediate: true })
  onSelectedChildrenChanged(val?: any, old?: any) {
    if (!val || val === old) {
      return;
    }
    this.getDetails(val.id);
  }
}
