


















import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';

import {
  CHOOSE_ACTION_MSG,
  HAS_UNSAVED_CHANGES_MSG,
  NO_SAVE_MSG,
  SAVE_MSG,
  WARNING_MSG,
} from './constants';

@Component
export default class ConfirmChanges extends Vue {
  @Prop() title?: string;
  @Prop() message?: string;
  @Prop() actionText!: string;
  @Prop() cancelText!: string;
  @Prop() showHide!: () => boolean;

  constantConfirmMessage = HAS_UNSAVED_CHANGES_MSG + ' ' + CHOOSE_ACTION_MSG;
  constantConfirmTitle = WARNING_MSG;
  constantActionText = SAVE_MSG;
  constantCancelText = NO_SAVE_MSG;

  handleConfirm() {
    this.$emit('on-confirm', true);
  }
  handleCancel() {
    this.$emit('on-confirm', false);
  }
  handleBeforeClose() {
    this.$emit('on-confirm', false);
  }
}
