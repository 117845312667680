






































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Getter, Mutation, Action } from 'vuex-class';
import { Subject, Subscription } from 'rxjs';
import { sampleTime } from 'rxjs/operators';

import TopMenu from '@/components/top-menu/TopMenu.vue';
import CustomScrollBar from '@/components/basics/CustomScrollBar.vue';
import Resizable from '@/components/basics/Resizable.vue';
import { LAYOUT } from '@/store/layout/constants';
import { CONST as AUTH } from '@/store/auth/constants';
import { Roles } from '@/store/auth/types';
import { DICTIONARIES_CONST } from '@/store/dictionaries/dictionaries.const';
import { SENSORS_STORE_CONST } from '@/store/sensors/constants';
import { addSubject, removeSubject } from '@/socket';

import { Sensor, Equipment, Reference, Enum } from './types';
import SensorsTreeView from './SensorsTreeView.vue';
import SensorView from './SensorView.vue';
import GroupSensorsView from './GroupSensorsView.vue';
import KsoParametersView from './KsoParametersView.vue';

@Component({
  components: {
    'top-menu': TopMenu,
    'custom-scrollbar': CustomScrollBar,
    'sensors-tree': SensorsTreeView,
    'group-sensors-view': GroupSensorsView,
    'kso-parameters-view': KsoParametersView,
    sensor: SensorView,
    resizable: Resizable,
  },
})
export default class SensorsView extends Vue {
  currentRouteId: string | null = null;
  currentSubstationId: string | null = null;

  subject = 'protocol_error_package';
  updateSubject = new Subject();
  updateSubjectSubscription: Subscription | null = null;

  @Getter(LAYOUT.GET_DRAWER_WIDTH, { namespace: LAYOUT.LAYOUT }) drawerWidth!: number;
  @Getter(LAYOUT.GET_DRAWER_VISIBLE, { namespace: LAYOUT.LAYOUT }) drawerVisible!: boolean;
  @Getter(AUTH.GET_ROLES, { namespace: AUTH.AUTH }) roles!: Roles;

  @Getter(DICTIONARIES_CONST.TAB_REFS, { namespace: DICTIONARIES_CONST.DICTIONARIES })
  enums!: Enum[];

  @Getter(SENSORS_STORE_CONST.SENSORS_DATA, { namespace: SENSORS_STORE_CONST.SENSORS })
  sensors!: Sensor[] | null;

  @Getter(SENSORS_STORE_CONST.EQUIPMENTS_DATA, { namespace: SENSORS_STORE_CONST.SENSORS })
  equipments!: Equipment[] | null;

  @Getter(SENSORS_STORE_CONST.REFERENCES_DATA, { namespace: SENSORS_STORE_CONST.SENSORS })
  references!: Reference[] | null;

  @Getter(SENSORS_STORE_CONST.SENSORS_STATES_DATA, { namespace: SENSORS_STORE_CONST.SENSORS })
  sensorsStates!: Record<string, any> | null;

  @Mutation(LAYOUT.SET_DRAWER_WIDTH, { namespace: LAYOUT.LAYOUT }) setDrawerWidth!: Function;

  @Action(SENSORS_STORE_CONST.GET_ALL_DATA, { namespace: SENSORS_STORE_CONST.SENSORS })
  getAllData!: () => Promise<void>;

  @Action(SENSORS_STORE_CONST.GET_SENSORS, { namespace: SENSORS_STORE_CONST.SENSORS })
  getSensors!: () => Promise<void>;

  @Action(SENSORS_STORE_CONST.GET_SENSORS_STATES, { namespace: SENSORS_STORE_CONST.SENSORS })
  getSensorsStates!: () => Promise<void>;

  @Action(SENSORS_STORE_CONST.SAVE_SENSOR, { namespace: SENSORS_STORE_CONST.SENSORS })
  saveSensor!: (payload: {
    sensor: Sensor;
    currentSensors: Sensor[] | null;
    comTradeSensors: Sensor[] | null;
  }) => Promise<void>;

  @Action(SENSORS_STORE_CONST.SAVE_SENSORS, { namespace: SENSORS_STORE_CONST.SENSORS })
  saveSensors!: (payload: { sensors: Sensor[] }) => Promise<void>;

  @Action(SENSORS_STORE_CONST.SAVE_SENSOR_AND_ASSETS, { namespace: SENSORS_STORE_CONST.SENSORS })
  saveSensorAndAssets!: (payload: { sensor: Sensor; assets: any[] }) => Promise<void>;

  @Action(DICTIONARIES_CONST.GET_TAB_REFS, { namespace: DICTIONARIES_CONST.DICTIONARIES })
  getTabRefs!: () => void;

  @Watch('$route.params.id', { immediate: true }) changeRouteId(val?: string, old?: string) {
    if (val !== old) {
      this.currentRouteId = val || null;
    }
  }

  @Watch('$route.params.stationId', { immediate: true }) changeStationId(
    val?: string,
    old?: string
  ) {
    if (val && val !== old) {
      this.currentSubstationId = val;
    }
  }

  get rightContentWidth() {
    return this.drawerVisible ? `calc(100vw - ${this.drawerWidth + 51}px)` : '100vw';
  }

  get editable() {
    return this.roles.sensorsEdit;
  }

  get currentSensors() {
    const curr = this.sensors?.find((x) => x.id === this.currentRouteId);
    const currentGroup =
      this.sensors?.filter(
        (x) =>
          curr &&
          x.protocol.protocol !== 'IEC_60255_24' &&
          (x.protocol.protocol === 'IEC_61850_9_2'
            ? x.protocol.ied === curr.protocol.ied
            : `${x.protocol.ip}:${x.protocol.port}` === `${curr.protocol.ip}:${curr.protocol.port}`)
      ) ?? null;

    return currentGroup;
  }

  get currentProtocol() {
    const protocol = this.currentRouteId;
    return isNaN(Number(protocol)) ? protocol : null;
  }

  get currentSubstation() {
    return this.equipments && this.currentSubstationId !== null
      ? this.equipments.find((x) => x.id === this.currentSubstationId) || null
      : null;
  }

  get comTradeSensors() {
    const sensors = this.sensors
      ? this.sensors.filter((x) => x.protocol.protocol === 'IEC_60255_24')
      : null;

    return sensors;
  }

  get ksoServerSensors() {
    const sensors = this.sensors
      ? this.sensors.filter((x) => x.protocol.protocol === 'KSO_SERVER')
      : null;

    return sensors;
  }

  get currentKsoServerSensors() {
    const substation = this.currentSubstation;

    const sensors = substation
      ? this.ksoServerSensors?.filter((x) =>
          x.sources.some((y) => y.equipment.id === substation.id)
        )
      : null;

    return sensors;
  }

  get protocolSensors() {
    const sensors = this.sensors
      ? this.sensors.filter((x) => {
          if (x.sources.length > 0) {
            const substation = this.getSubstation(x.sources[0].equipment);

            return (
              substation &&
              x.protocol.protocol === this.currentProtocol &&
              this.currentSubstationId === substation.id
            );
          } else {
            return false;
          }
        })
      : null;

    return sensors;
  }

  unsubscribe() {
    this.$socket.client.off(this.subject);
    removeSubject(this.subject);
    this.updateSubjectSubscription?.unsubscribe();
  }

  getSubstation(x: Equipment): Equipment | null {
    if (!this.equipments) {
      return null;
    } else if (x.parentId === null) {
      return x;
    } else {
      const parent = this.equipments.find((y) => y.id === x.parentId);
      return parent ? this.getSubstation(parent) : null;
    }
  }

  subscribe() {
    addSubject({
      theme: this.subject,
      keys: [],
    });

    this.$socket.client.on(this.subject, (x: any) => {
      this.updateSubject.next(x);
    });

    this.updateSubjectSubscription = this.updateSubject
      .pipe(sampleTime(1000))
      .subscribe(() => this.updateStates());

    this.updateSubject.next();
  }

  async updateStates() {
    await this.getSensorsStates();
  }

  async updateData() {
    this.getTabRefs();
    await this.getAllData();
  }

  async updateSensors() {
    await this.getSensors();
  }

  async onSaveSensor(sensor: Sensor, done: Function) {
    try {
      await this.saveSensor({
        sensor: sensor,
        currentSensors: this.currentSensors,
        comTradeSensors: this.comTradeSensors,
      });
      await this.updateSensors();
    } finally {
      done();
    }
  }

  async onSaveSensors(sensors: Sensor[], done: Function) {
    try {
      await this.saveSensors({ sensors: sensors });
      await this.updateSensors();
    } finally {
      done();
    }
  }

  async onSaveSensorAndAssets(sensor: Sensor, assets: any[], done: Function) {
    try {
      await this.saveSensorAndAssets({ sensor: sensor, assets: assets });
      await this.updateSensors();
    } finally {
      done();
    }
  }

  mounted() {
    this.subscribe();
    this.updateData();
  }

  beforeDestroy() {
    this.unsubscribe();
  }
}
