







































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { DETAILS_CONST } from '@/store/details';
import { IEquipDetails } from '@/store/details/types';
import { IEquip } from '@/store/equipment/types';
import { DICTIONARIES_CONST } from '@/store/dictionaries/dictionaries.const';
import { IEquipmentType } from '@/store/dictionaries/dictionaries.types';

@Component({})
export default class Level0ContentCard extends Vue {
  @Prop() item!: IEquip | null;

  @Getter(DETAILS_CONST.EQUIPMENT_DETAILS, { namespace: DETAILS_CONST.DETAILS })
  equipmentDetails?: IEquipDetails;

  @Getter(DICTIONARIES_CONST.EQUIPMENT_TYPES, { namespace: DICTIONARIES_CONST.DICTIONARIES })
  equipmentTypes?: IEquipmentType[];

  get selectedDevice() {
    const { tab } = this.$route.query;

    if (tab) {
      return this.item?.children.find((x) => x.id === Number(tab)) ?? null;
    } else {
      return null;
    }
  }

  get tableData() {
    return (
      this.selectedDevice &&
      this.selectedDevice.children.map((device) => ({
        id: device.id,
        name: device.name,
        status: device.inService,
        type: this.equipmentTypes?.find((x) => x.id === device.typeId)?.notes ?? '',
      }))
    );
  }

  onRowClick(row: any) {
    this.$router.push(`./${row.id}`);
  }
}
