















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { QueryParamsService } from '@/utils';

import { getCalculatorNameWithoutPrefix } from './utils';
import LogsTableView from './LogsTableView.vue';
import SubstationLogsTableView from './SubstationLogsTableView.vue';
import LogSettings from './LogSettings.vue';

type QueryType = {
  phase?: string;
  offset?: string;
};

@Component({
  components: {
    'logs-table-view': LogsTableView,
    'substation-logs-table-view': SubstationLogsTableView,
    'log-settings': LogSettings,
  },
})
export default class LogView extends Vue {
  getCalculatorNameWithoutPrefix = getCalculatorNameWithoutPrefix;
  queryParams!: QueryParamsService<QueryType>;
  phases = ['A', 'B', 'C'];
  activeTab = 'protocol';

  @Prop() calculator!: any;
  @Prop() editable!: boolean;
  @Prop() enums!: any[];

  get selectedPhase() {
    const { phase } = this.$route?.query;
    return phase ?? null;
  }

  onPhaseNameChange(phase: string) {
    this.queryParams.update({ phase, offset: '0' });
  }

  onChangeTab(tab: any) {
    this.activeTab = tab.$vnode.key;
  }

  created() {
    this.queryParams = new QueryParamsService<QueryType>(() => ({
      phase: this.$route.query.phase,
      offset: this.$route.query.offset,
    }));
  }

  mounted() {
    if (this.calculator.type === 1) {
      this.queryParams.initialise({ phase: 'A' });
    }
  }
}
