import { render, staticRenderFns } from "./level0-topic-card2.vue?vue&type=template&id=90c36e3e&scoped=true&"
import script from "./level0-topic-card2.vue?vue&type=script&lang=ts&"
export * from "./level0-topic-card2.vue?vue&type=script&lang=ts&"
import style0 from "./level0-topic-card2.vue?vue&type=style&index=0&id=90c36e3e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90c36e3e",
  null
  
)

export default component.exports