export const isEmptyOrSpaces = (str: string): boolean => {
  return str === null || str.match(/^ *$/) !== null;
};

export const propertyToLowerCase = (data: Record<string, any>, deleteInitial = true): Record<string, any> => {
  return Object.entries(data).reduce(
    (acc: Record<string, any>, curr) => { 
      acc[curr[0][0].toLowerCase() + curr[0].slice(1)] = curr[1];
      !deleteInitial && (acc[curr[0]] = curr[1]);
      return acc; 
    }, {}
  );
}
