














import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { DETAILS_CONST } from '@/store/details';
import { IEquipDetails, RegulatoryInformation } from '@/store/details/types';
import { Table, TableColumn } from 'element-ui';
import { PropertyView } from '@/components/items-tab/panes/nsi-info/nsi-info.types';
@Component({
  components: {
    'el-table': Table,
    'el-table-column': TableColumn,
  },
})
export default class NsiInfo extends Vue {
  @Getter(DETAILS_CONST.EQUIPMENT_DETAILS, { namespace: DETAILS_CONST.DETAILS })
  equipmentDetails!: IEquipDetails;

  get getDetails(): PropertyView[] {
    const item: any = this.equipmentDetails?.make[0] ?? {};
    const props: RegulatoryInformation[] = item?.props ?? [];
    const list = props.map((p: any) => {
      let value = p.Value;
      if (p.Name === 'MaxUPhase' || p.Name === 'NominalUPhase') {
        value = Math.round(value).toLocaleString();
      }
      if (p.Name === 'PTType' || p.Name == 'Insulation') {
        value = p.Format;
      }

      return { name: p.Notes, value: value, code: p.Name, measure: p.Unit };
    });
    return list;
  }
}
