import { Sensor } from '../../types';
import { NameWithCheck } from './types';

function isEmpty(str?: string): boolean {
  return !str || str.length === 0;
}

const parseText = (text: string): string[] => {
  return [...text.split('\r\n')].map((i) => i.split(',')[1]).filter((i) => !isEmpty(i));
};

export const matchNames = async (
  text: string,
  names: string[]
): Promise<Record<NameWithCheck, string | boolean>[]> => {
  return await new Promise((resolve, reject) => {
    try {
      const result = [];
      const parsed = parseText(text);
      for (const name of names) {
        const find = parsed?.find((p) => p === name);
        if (find) result.push({ name, state: true });
        else result.push({ name, state: false });
      }
      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const updateSensorModel = (sensor: Sensor, dirties: {}[]): Sensor => {
  for (const d of dirties) {
    const s = sensor.sources.find((i) => (i as any).uuid === (d as any).uuid);
    if (!s) continue;
    s.selector.selector = (d as any).channel;
  }
  return sensor;
};

export const refreshDirtyState = (
  key: string,
  prop: string,
  model: Record<string, {}>,
  data: any[]
) => {
  const item = model[key];
  if (!item) return;
  const value = (item as any)[prop];
  const initialValue = data.find((d) => d.uuid === key);
  if (!initialValue) return;
  (item as any).isDirty = value !== initialValue;
};

export const findDirties = (editableModel: Record<string, {}>): {}[] | boolean => {
  const dirties = Object.values(editableModel).filter((i: any) => i.isDirty);
  if (!dirties?.length) return false;
  return dirties;
};
