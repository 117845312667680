




























































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import ConfirmChanges from '@/components/confirm-changes/index.vue';
import TablePagination from '@/components/basics/table/TablePagination.vue';
import { getModelsDiff, getDeviation, getPage } from '../utils';
import ExportDataView from '../ExportDataView.vue';

import { Sensor } from '../types';

@Component({
  components: {
    'confirm-changes': ConfirmChanges,
    'table-pagination': TablePagination,
    'export-data': ExportDataView,
  },
})
export default class Protocol5092Sources extends Vue {
  @Prop() sensorModel!: Sensor | null;
  @Prop() assets!: any[] | null;
  @Prop() editPermission!: boolean;

  showConfirm = false;
  isEdit = false;
  isSaving = false;
  editable: Record<string, {}> = {};

  sort: { order: string | null; prop: string } = { prop: 'name', order: 'ascending' };

  assetValidators = {
    ['coeff']: (row: any) => !isNaN(Number(row.coeff)),
    ['voltage']: (row: any) => !isNaN(Number(row.voltage)),
  };

  sourceValidators = {
    ['error']: (row: any) => !isNaN(Number(row.error)),
  };

  get config() {
    return {
      fileName: `IEC_61850_9_2_${this.sensorModel?.name}`,
      columns: [
        { prop: 'name', name: 'Диспетчерское наименование' },
        { prop: 'windingName', name: 'Наименование обмотки' },
        { prop: 'windingAlias', name: 'Обозначение обмотки' },
        { prop: 'phase', name: 'Фаза' },
        { prop: 'voltage', name: 'Номинальное напряжение/ток' },
        { prop: 'coeff', name: 'Коэффициент трансформации' },
        { prop: 'error', name: 'Погрешность канала, %' },
      ],
    };
  }

  get isValid() {
    const assetValidatorsEntries = Object.entries(this.assetValidators);
    const sourceValidatorsEntries = Object.entries(this.sourceValidators);

    const entries = Object.entries(this.editable);
    const sourceRows = entries.filter(([key, row]: [string, any]) => row.type === 'source');
    const assetRows = entries.filter(([key, row]: [string, any]) => row.type === 'asset');

    return (
      this.editable &&
      !assetRows.some(([, x]) => assetValidatorsEntries.some(([key, value]) => !value(x))) &&
      !sourceRows.some(([, x]) => sourceValidatorsEntries.some(([key, value]) => !value(x)))
    );
  }

  get tableData() {
    const sources = this.sensorModel?.sources.flatMap((x) => x) ?? [];
    const items: any[] = (this.assets ?? []).map((x) => ({
      ...x,
      source: sources.find(
        (source) => source.equipId === x['equipId'] && Number(source.flags) & Number(x['phase'])
      ),
    }));

    const getProp = (x: any, name: string, field?: string) =>
      x.props?.find((prop: any) => prop.Name === name)?.[field || 'Value'];

    const data = items
      .map((x) => ({
        name: x.parentEquipment?.name,
        windingName: getProp(x, 'Name'),
        windingAlias: getProp(x, 'Alias'),
        phase: x.phase === '32' ? 'A' : x.phase === '64' ? 'B' : 'C',
        isCurrent: getProp(x, 'NominalCurrent') != null,
        voltage:
          getProp(x, 'NominalCurrent') != null
            ? `${getProp(x, 'NominalCurrent')} ${getProp(x, 'NominalCurrent', 'Unit')}`
            : getProp(x, 'NominalVoltage') != null
            ? `${getProp(x, 'NominalVoltage')} ${getProp(x, 'NominalVoltage', 'Unit')}`
            : '',
        coeff: getProp(x, 'Ku') ?? getProp(x, 'Ki'),
        error: getDeviation(x.source),
        uuidAsset: x.uuid,
        uuidSource: x.source?.uuid,
      }))
      .sort((x, y) => (`${x.name}${x.phase}` < `${y.name}${y.phase}` ? -1 : 1));

    return data;
  }

  get page() {
    return getPage(this.tableData, this.sort, this.$route.query);
  }

  onSortChange(x: any) {
    this.sort = x;
  }

  getEditableModel(data: any[]) {
    const modelHash: Record<string, any> = {};

    data.forEach((x) => {
      modelHash[x.uuidAsset] = {
        ...(modelHash[x.uuidAsset] || { type: 'asset' }),
        isCurrent: x.isCurrent,
        voltage: (x.voltage || '').split(' ')[0],
        coeff: x.coeff,
        isDirty: false,
      };

      modelHash[x.uuidSource] = {
        ...(modelHash[x.uuidSource] || { type: 'source' }),
        error: x.error,
        isDirty: false,
      };
    });

    return modelHash;
  }

  async handleConfirmAction(saveFlag: boolean) {
    this.showConfirm = false;
    if (saveFlag) {
      this.onSave();
    } else {
      this.isEdit = false;
    }
  }

  getNewSensorModel(sensor: Sensor, data: Record<string, any>) {
    const entries = Object.entries(data);

    const newSensor = {
      ...sensor,
      sources: entries
        .filter(([key, row]) => row.type === 'source')
        .map(([key, row]: [string, any]) => {
          const oldSource = sensor.sources.find((x) => x.uuid === key);

          return {
            ...oldSource,
            deviation: Number(row.error),
          };
        }),
    };

    return newSensor;
  }

  getNewAssets(assets: any[], data: Record<string, any>) {
    const newAssets = assets.map((x) => {
      const model = data[x.uuid];
      if (model) {
        const newProps = [...x.props.map((y: any) => ({ ...y }))];

        const setProp = (value: any, field: string) => {
          const prop = newProps.find((x) => x.Name === field);
          prop.Value = value;
        };

        if (model.isCurrent) {
          setProp(model.coeff, 'Ki');
          setProp(model.voltage, 'NominalCurrent');
        } else {
          setProp(model.coeff, 'Ku');
          setProp(model.voltage, 'NominalVoltage');
        }

        return { ...x, props: newProps, sensor: undefined, parentEquipment: undefined };
      } else {
        return x;
      }
    });

    return newAssets;
  }

  onSave() {
    const dirties = getModelsDiff(this.getEditableModel(this.tableData), this.editable);
    if (!dirties.length || !Array.isArray(dirties) || !this.sensorModel) {
      this.isEdit = false;
      this.isSaving = false;
    } else {
      this.isSaving = true;
      const newSensor = this.getNewSensorModel(this.sensorModel, this.editable);
      const newAssets = this.getNewAssets(this.assets || [], this.editable);

      this.$emit('save-sensor', newSensor, newAssets, () => {
        this.isEdit = false;
        this.isSaving = false;
      });
    }
  }
  onEdit() {
    this.editable = this.getEditableModel(this.tableData);
    this.isEdit = true;
  }

  onCancelEdit() {
    const dirties = getModelsDiff(this.getEditableModel(this.tableData), this.editable);
    if (!dirties.length || !this.isValid) {
      this.$emit('cancel-edit');
      this.isEdit = false;
    } else {
      this.showConfirm = true;
    }
  }
}
