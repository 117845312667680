

































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

import FilterFooter from '@/components/basics/table/FilterFooter.vue';

@Component({
  components: {
    'filter-footer': FilterFooter,
  },
})
export default class LogJournalTableFilter extends Vue {
  @Prop() filtersMap!: Record<string, any>;
  @Prop() filtersState!: Record<string, any>;
  @Prop() column!: any;

  async initialiseFilter(filter: any) {
    if (filter.type === 'Enum') {
      this.filtersState.filterOptions = filter.options.map((x: any) => ({ ...x }));
    }
  }

  async filterToggle(filter: any) {
    const oldFilterState = filter.visible;
    this.filtersHide();
    if (!oldFilterState) {
      await this.initialiseFilter(filter);
    }
    filter.visible = !oldFilterState;
  }

  filtersHide() {
    Object.entries(this.filtersMap).forEach(([, value]) => value && (value.visible = false));
  }
}
