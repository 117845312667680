






































import { Component, Watch, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import moment from 'moment';

import { RestApiService } from '@/services';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import TableSorter from '@/components/basics/table/TableFilter.vue';
import TablePagination from '@/components/basics/table/TablePagination.vue';
import { splitChunks } from '@/store/helpers';
import DateTimeSelector from '@/components/basics/selectors/DateTimeSelector.vue';
import { QueryBuilder } from '@/utils/QueryBuilder';
import { updateTimeOffset } from '@/utils/datetime/DateTimeCorrector';
import BasicTable from '@/components/basics/table/BasicTable.vue';
import { AlgorithmScheme } from '@/store/algorithms/algorithms.types';

import TableFilter from './LogJournalTableFilter.vue';
import { Role, User, UserRole } from './types';

const rest = new RestApiService();

@Component({
  components: {
    'table-sorter': TableSorter,
    'table-filter': TableFilter,
    'table-pagination': TablePagination,
    'datetime-selector': DateTimeSelector,
    'basic-table': BasicTable,
  },
})
export default class LogJournalView extends Vue {
  @Prop() roles!: Role<UserRole>[];
  @Prop() users!: User<UserRole>[];
  @Prop() user!: User<Role<UserRole>>;

  get scheme(): AlgorithmScheme | null {
    return null;
  }

  page: any[] | null = [];
  isLoading = true;
  total = 0;

  filtersMap: Record<string, any> = {};
  filtersState: Record<string, any> = {
    filterOptions: [],
  };

  resultConsts: Record<string, any> = {
    '0': 'Ошибка',
    '1': 'Успешно',
  };

  typeConstants: Record<string, any> = {
    openSession: 'подключение',
    closeSession: 'отключение',
    createRole: 'создание роли',
    editRole: 'редактирование роли',
    deleteRole: 'удаление роли',
    createUser: 'создание пользователя',
    editUser: 'редактирование прав пользователя',
    deleteUser: 'удаление пользователя',
    changeNsiConfig: 'изменение НСИ',
  };

  operandsConst: Record<string, any> = {
    openSession: 'пользователь',
    closeSession: 'пользователь',
    createRole: 'роль',
    editRole: 'роль',
    deleteRole: 'роль',
    createUser: 'пользователь',
    editUser: 'пользователь',
    deleteUser: 'пользователь',
  };

  columns: any[] = [
    { key: 1, header: 'Дата', dbProps: 'datetime', width: '200px' },
    {
      key: 2,
      header: 'Тип операции',
      dbProps: 'type',
      filterType: 'Enum',
      filterOptions: Object.entries(this.typeConstants).map(([key, value]) => ({
        value: key,
        notes: value,
      })),
      width: '200px',
    },
    // { key: 3, header: 'Адрес', dbProps: 'url' },
    { key: 5, header: 'Клиент', dbProps: 'user_agent', width: '320px' },
    {
      key: 6,
      header: 'Результат',
      dbProps: 'result',
      // filterType: 'Enum',
      // filterOptions: Object.entries(this.resultConsts).map(([key, value]) => ({
      //   value: key,
      //   notes: value,
      // })),
    },
  ];

  get params() {
    const { limit, offset, start, end, filter } = this.$route.query as any;

    if (limit && start && end) {
      return { limit, offset, start, end, filter, userId: this.user.id };
    } else {
      return null;
    }
  }

  @Watch('params', { immediate: true }) async onRouteChanged(x: any) {
    if (x) {
      this.loadData(x);
    }
  }

  @Watch('$route.query.filter', { immediate: true }) onFilterChange(val: any) {
    const queryFilters = val
      ? Object.fromEntries(JSON.parse(decodeURIComponent(val)).map((x: any) => [x.name, x]))
      : {};

    this.columns.forEach((column: any) => {
      const currentFilter = queryFilters && queryFilters[column.dbProps];

      Vue.set(
        this.filtersMap,
        column.dbProps,
        column.filterType === 'Enum'
          ? {
              type: 'Enum',
              name: column.dbProps,
              visible: false,
              active: !!currentFilter,
              options: column.filterOptions.map((x: any) => ({
                ...x,
                active: currentFilter
                  ? currentFilter.options.some((option: any) => option === x.value)
                  : false,
              })),
            }
          : undefined
      );
    });
  }

  async loadData(params: Record<string, any>) {
    const query = QueryBuilder.build(params);
    const [data, count] = await Promise.all([
      rest.get(getApiUrl(TR_TYPE.HTTP, `/user-activity/selected-page?${query}`)),
      rest.get(getApiUrl(TR_TYPE.HTTP, `/user-activity/total-count?${query}`)),
    ]);

    if (data.status === 200 && count.status === 200) {
      this.page = splitChunks(data.data).map((x) => {
        const value = x.result;
        return {
          ...x,
          datetime: moment(updateTimeOffset(new Date(x.datetime))).format('HH:mm:ss DD-MM-YYYY'),
          result: value,
          type: this.typeConstants[x.type as string],
        };
      });
      const total = splitChunks(count.data);
      this.total = total[0]?.total ?? 0;
      this.isLoading = false;
    }
  }
}
