export const NORMAL_RESOURCE = 'normal-resource';
export const WARNING_RESOURCE = 'warning-resource';
export const DANGEROUS_RESOURCE = 'dangerous-resource';

export const getStateStyle = (state: any, baseStyle?: string) => {
  let style = baseStyle || 'resource-container';
  if (state === undefined || state === null) return style;
  if (Number(state) >= 50) {
    style += ` ${NORMAL_RESOURCE}`;
  } else if (Number(state) >= 25) {
    style += ` ${WARNING_RESOURCE}`;
  } else {
    style += ` ${DANGEROUS_RESOURCE}`;
  }
  return style;
};
