import { EChartOption } from 'echarts';
import { IBarDataItem, IUsageChartSettings } from '@/services/usage/types';
import moment from 'moment';

const colors = {
  normal: '#13dc32',
  warning: '#f5d503',
  error: '#ff4d47',
  neutral: 'rgb(195, 195, 195, 0.2)',
};

const getPrc = (current: number, limit: number) => (100 * current) / limit;

const defineColor = (value: number, limit: number) => {
  const prc = getPrc(value, limit);
  return prc <= 40 ? colors.normal : prc <= 80 ? colors.warning : colors.error;
};

const buildSeriesItems = (data: IBarDataItem[], limit: number, opacity = 0.85) => {
  return data.map((item) => ({
    value: item.value,
    itemStyle: {
      opacity,
      color: defineColor(item.value, limit),
    },
  }));
};

export class OptionsBuilder {
  public static build(
    data: IBarDataItem[],
    { title, limit, unit }: IUsageChartSettings
  ): EChartOption {
    const _limit: number = limit ?? 1;
    const rectangle =
      'path://M0 4.5C0 2.01472 2.01472 0 4.5 0H40.5C42.9853 0 45 2.01472 45 4.5V40.5C45 42.9853 42.9853 45 40.5 45H4.5C2.01472 45 0 42.9853 0 40.5V4.5Z';
    return {
      animation: false,
      title: {
        left: 'center',
        top: 5,
        text: `${title ?? ''}${title && unit ? ', ' : ''} ${unit ?? ''}`,
        textStyle: {
          fontSize: 14,
          fontWeight: 'normal',
        },
      },
      tooltip: {
        trigger: 'axis',
        formatter: (params: any) => {
          const r = `${params[0].marker}${Math.round(params[0].data.value * 100) / 100}${unit}`;
          let ts;
          if (params?.length === 1) {
            ts = moment(params[0].value[0]).format('HH:mm:ss.S');
          } else if (params?.length > 1) {
            ts = moment(params[0].value[0]).format('DD/MM HH:mm:ss');
          }
          return [`<i class="las la-clock"></i> ${ts}`, r].join(`<br/>`);
        },
        renderMode: 'html',
      },
      xAxis: {
        max: _limit,
        splitLine: {
          show: false,
        },
        offset: 10,
        axisLine: {
          lineStyle: {
            color: '#999',
          },
        },
        axisLabel: {
          margin: 10,
          formatter: (value: any) => {
            return Math.floor(value);
          },
        },
      },
      yAxis: {
        data: [...data.map((d) => d.name)],
        inverse: true,
        axisTick: { show: false },
        axisLine: { show: false },
        axisLabel: {
          margin: 10,
          color: '#999',
          fontSize: 10,
          show: true,
        },
      },
      grid: {
        top: 'center',
        height: 15 * data?.length,
        left: 50,
        right: 50,
      },
      series: [
        {
          type: 'pictorialBar',
          symbol: rectangle,
          symbolRepeat: 'fixed',
          symbolMargin: '10%',
          symbolClip: true,
          symbolBoundingData: _limit,
          data: [...buildSeriesItems(data, limit ?? 100)],
          z: 10,
        },
        {
          type: 'pictorialBar',
          itemStyle: {
            opacity: 0.3,
            color: colors.neutral,
          },
          animationDuration: 0,
          symbolRepeat: 'fixed',
          symbolMargin: '10%',
          symbol: rectangle,
          symbolBoundingData: _limit,
          data: [...data.map((d) => d.value)],
          z: 5,
        },
      ],
    };
  }
}
