








import Vue from 'vue';
import { ElUpload } from 'element-ui/types/upload';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';

import { Decoder } from '@/utils/text-decoder/Decoder';

import { loadParsedText, saveParsedText } from './services';
import { matchNames } from './extensions';

@Component
export default class ChannelNameValidator extends Vue {
  @Ref('file-keeper') fileKeeper!: ElUpload;
  @Prop() name!: string;
  @Prop() channels!: string[];
  files?: any = [];
  loaded?: string = '';

  autoCheck = false;
  handleChange(file: any, fileList: any) {
    if (!file) return;
    this.read(file.raw);
    this.fileKeeper.clearFiles();
    this.autoCheck = false;
  }

   @Watch('channels') async onChannels(val: string[], old: string[]) {
    if (val === old) return;
    if (!this.autoCheck) {
      this.autoCheck = true;
      const text = loadParsedText();
      if(!text)
        return;
      const matched = (await matchNames(text, val)) ?? val;
      this.$emit('checked', matched);
    }
  }

  read(file: any) {
    try {
      Decoder.DecodeAsDataArray(file).subscribe(async (text) => {
        if (text) saveParsedText(text);
        const matched = (await matchNames(text, this.channels)) ?? this.channels;
        this.$emit('checked', matched);
      });
    } catch (e) {
      console.log(e.message);
    }
  }
  onBtnClick(e: MouseEvent) {
    e.preventDefault();
  }
}
