export class LocaleManager {
  public static getLang() {
    if (window.navigator.languages?.length) {
      return window.navigator.languages[0];
    } else {
      return window.navigator.language;
    }
  }
  public static getCharset() {
    const lang = LocaleManager.getLang();
    switch (lang) {
      case 'en-GB':
      case 'en-US':
        return 'utf-8';
      case 'ru-RU':
      case 'ru':
      case 'RU':
      default:
        return 'utf-8';
    }
  }
}
