































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import moment from 'moment';

import IconBtn from '@/components/basics/buttons/icon-btn.vue';
import { TABS_CONST } from '@/store/tabs/tabs.const';
import { ITabRef } from '@/store/dictionaries/dictionaries.types';
import { DICTIONARIES_CONST } from '@/store/dictionaries/dictionaries.const';

import { getStateData, getTableData } from './load-data';
import {
  getChartCsvContent,
  getChartXlsContent,
  getTableCsvContent,
  getTablePdfContent,
  getTableXlsContent,
} from './format-data';
import { saveData, savePdf, saveScreen, saveXls } from './save-data';
import { IPropertyScheme } from '@/store/history-data/history-data.types';
import { CHART_PROPS } from '@/store/abstract/props-scheme/constants';
import { HISTORY_CHARTS } from '@/store/history-charts/constants';
import { IReportColumn, IReportTab } from '@/store/report-data/types';
import { AlgorithmScheme } from '@/store/algorithms/algorithms.types';
import { DataExtractor } from '@/components/files/services/data-extractor';
import { DataGenerator } from '@/components/files/services/data-generator';
import { CHART_DATA_EXPORT, GET_ALL_CHART_DATA } from '@/store/chart-data-export/constants';
import { IHistoryChartsQuery } from '@/store/history-charts/types';
import { IEquipDetails } from '@/store/details/types';
import { DETAILS_CONST } from '@/store/details';
import { FileNameBuilder } from '@/components/files/services/name-builder/file-name-builder';
import { FileFormat, ImageFileType } from '@/components/files/services/name-builder/types';

const { ALL_TABS, TABS } = TABS_CONST;
const { TAB_REFS, DICTIONARIES } = DICTIONARIES_CONST;

@Component({
  components: {
    'icon-btn': IconBtn,
  },
})
export default class FileDownloader extends Vue {
  @Prop() content!: string;
  @Prop() stateTab?: IReportTab;
  @Prop() reportColumns?: IReportColumn[];
  @Prop() stateScheme?: AlgorithmScheme;
  @Prop() equipment?: any[];

  isBusy = false;
  saveDialogVisible = false;
  fileName = '';

  @Getter(DETAILS_CONST.EQUIPMENT_DETAILS, { namespace: DETAILS_CONST.DETAILS })
  equipmentDetails!: IEquipDetails;
  @Getter(TAB_REFS, { namespace: DICTIONARIES })
  tabRefs?: ITabRef[];
  @Getter(ALL_TABS, { namespace: TABS })
  allTabs?: any[];
  @Getter(CHART_PROPS, { namespace: HISTORY_CHARTS })
  chartProps: any;

  get phase() {
    const { phase } = this.$route.query;
    if (phase === 'A') return 32;
    if (phase === 'B') return 64;
    if (phase === 'C') return 128;
    return undefined;
  }

  get selectedTab() {
    const { tab } = this.$route.query;
    if (!this.allTabs?.length || !tab) return null;

    const sTab = this.allTabs.find((t) => t.key === tab);
    return sTab ?? null;
  }

  cn = 'calculator_name';
  get query() {
    if (!this.selectedTab) return null;
    const { scheme } = this.selectedTab;
    if (!scheme) return null;
    const { columns } = scheme;
    if (!columns?.length) return null;
    const select = this.propsSelector(columns);
    if (!select) return null;
    const { start, end } = this.$route.query;
    if (!start || !end) return null;
    return {
      phase: this.phase,
      start: start as string,
      end: end as string,
      algorithm: scheme.dbTableName,
      [this.cn]: scheme.moduleUid,
      select,
    };
  }

  propsSelector(columns: any[]) {
    return columns
      .filter((j: any) => j.order > 0)
      .map((c: any) => c.dbProps)
      .join(',');
  }

  openDialog() {
    this.saveDialogVisible = true;
    this.fileName = FileNameBuilder.getName({
      details: this.equipmentDetails,
      routeQuery: this.$route.query,
      tab: this.selectedTab ?? this.stateTab,
    });
  }

  async saveFile(saver: () => any) {
    this.saveDialogVisible = false;
    this.isBusy = true;
    try {
      await saver();
    } finally {
      this.isBusy = false;
    }
  }

  get assetUuid(): string {
    return (this.equipmentDetails as any)?.assetUids[this.phase ?? ''] ?? '';
  }

  @Action(GET_ALL_CHART_DATA, { namespace: CHART_DATA_EXPORT })
  getAllChartsData!: ({
    query,
    props,
  }: {
    query: IHistoryChartsQuery;
    props: IPropertyScheme[];
  }) => Promise<any>;

  async saveCsv() {
    if (this.stateTab && this.reportColumns && this.stateScheme) {
      const data = await getStateData(this.stateTab, this.reportColumns);
      if (data) {
        const extracted = await DataExtractor.extractTimelessCsvData(
          data,
          this.stateScheme,
          this.tabRefs,
          this.equipment
        );
        const generated = await DataGenerator.generateTimelessCsv(extracted);
        saveData(FileNameBuilder.ext(this.fileName, FileFormat.SCV), generated);
      }
      return;
    }

    const sTab = this.selectedTab;
    if (sTab?.type === 'chart') {
      const { start, end, interval } = this.$route.query as any;
      const data = await this.getAllChartsData({
        query: { start, end, interval, asset_uuid: this.assetUuid },
        props: this.chartProps,
      });
      if (data?.interval && data?.data) {
        saveData(
          FileNameBuilder.ext(this.fileName, FileFormat.SCV),
          getChartCsvContent(data as any)
        );
      }
    } else {
      if (!this.query) return;
      const data = await getTableData(this.$route, sTab.scheme);

      if (data) {
        saveData(
          FileNameBuilder.ext(this.fileName, FileFormat.SCV),
          getTableCsvContent(data as any, sTab.scheme, this.tabRefs, this.query)
        );
      }
    }
  }

  async saveXls() {
    if (this.stateTab && this.reportColumns && this.stateScheme) {
      const data = await getStateData(this.stateTab, this.reportColumns);
      if (data) {
        const extracted = await DataExtractor.extractTimelessCsvData(
          data,
          this.stateScheme,
          this.tabRefs,
          this.equipment
        );
        const generated = await DataGenerator.generateTimelessXls(extracted);
        saveXls(FileNameBuilder.ext(this.fileName, FileFormat.XLS), generated);
      }
      return;
    }

    const sTab = this.selectedTab;

    if (sTab?.type === 'chart') {
      const { start, end, interval } = this.$route.query as any;
      const xlsChartData = await this.getAllChartsData({
        query: { start, end, interval, asset_uuid: this.assetUuid },
        props: this.chartProps,
      });

      if (xlsChartData && xlsChartData.interval && xlsChartData.data) {
        saveXls(
          FileNameBuilder.ext(this.fileName, FileFormat.XLS),
          getChartXlsContent(xlsChartData as any)
        );
      }
    } else {
      if (!this.query) return;
      const data = await getTableData(this.$route, sTab.scheme);

      if (data) {
        saveXls(
          FileNameBuilder.ext(this.fileName, FileFormat.XLS),
          getTableXlsContent(data as any, sTab.scheme, this.tabRefs, this.query)
        );
      }
    }
  }

  async savePdf() {
    if (this.stateTab && this.reportColumns && this.stateScheme) {
      const data = await getStateData(this.stateTab, this.reportColumns);
      if (data) {
        const extracted = await DataExtractor.extractTimelessCsvData(
          data,
          this.stateScheme,
          this.tabRefs,
          this.equipment
        );
        const generated = await DataGenerator.generateTimelessPdf(extracted, {
          maxColumnCount: 4,
          title: 'Сводный отчет',
          subTitle: `тип: ${this.stateTab.name}, дата/время: ${moment(new Date()).format(
            'DD.MM.YYYY HH:mm:ss'
          )}`,
        });
        try {
          savePdf(FileNameBuilder.ext(this.fileName, FileFormat.PDF), generated);
        } catch (e) {
          //
        }
      }
      return;
    }

    const sTab = this.selectedTab;

    if (sTab?.type === 'chart') {
      await saveScreen(FileNameBuilder.ext(this.fileName, FileFormat.PDF), 'pdf');
    } else {
      if (!this.query) return;
      const data = await getTableData(this.$route, sTab.scheme);

      if (data) {
        savePdf(
          FileNameBuilder.ext(this.fileName, FileFormat.PDF),
          getTablePdfContent(data as any, sTab.scheme, this.tabRefs, this.query)
        );
      }
    }
  }

  saveImage(fileType: ImageFileType) {
    return async () => {
      await saveScreen(FileNameBuilder.ext(this.fileName, fileType), fileType);
    };
  }
  get equipId(): number | null {
    const { id } = this.$route.params;
    const eId = Number(id);
    return !isNaN(eId) ? eId : null;
  }
}
