
































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { User, Role, UserRole } from './types';

@Component({})
export default class BaseInfoView extends Vue {
  userModel = {
    name: '',
    mail: '',
    notes: '',
    roles: [] as number[],
  };
  userModelValidity: Record<string, boolean> = {
    name: false,
    mail: false,
  };

  rules = {
    name: [{ required: true, message: 'Введите имя.', trigger: 'change' }],
    mail: [{ required: true, message: 'Введите адрес электронной почты.', trigger: 'change' }],
    roles: [{ required: true, message: 'Выберите хотя бы одну роль.', trigger: 'change' }],
  };

  isEdit = false;

  @Prop() editable!: boolean;
  @Prop() user!: User<Role<UserRole>>;
  @Prop() roles!: Role<User<UserRole>>[];

  @Watch('user', { immediate: true }) updateRoleModel(
    user: User<Role<UserRole>>,
    old?: User<Role<UserRole>>
  ) {
    if (user !== old) {
      this.resetUserModel(user);
    }
  }

  get isValid() {
    return !Object.entries(this.userModelValidity).some(([, value]) => !value);
  }

  guard() {
    if (this.isEdit) {
      return new Promise((resolve) => {
        this.$confirm('Несохраненные данные будут потеряны. Продолжить?', 'Внимание!', {
          confirmButtonText: 'Oк',
          cancelButtonText: 'Отмена',
          type: 'warning',
        })
          .then(() => {
            this.user && this.resetUserModel(this.user);
            resolve(false);
          })
          .catch(() => {
            resolve(true);
          });
      });
    }
  }

  onValidate(field: string, validity: boolean) {
    this.userModelValidity[field] = validity;
  }

  resetUserModel(user: User<Role<UserRole>>) {
    this.userModel = {
      name: user.name,
      mail: user.mail,
      notes: user.notes,
      roles: user.roles.map((x) => x.id),
    };

    this.isEdit = false;
  }

  editClick() {
    this.isEdit = true;
    setTimeout(() => {
      (this.$refs['userModel'] as any).validate();
    }, 0);
  }

  saveUser() {
    const newUser = {
      id: this.user?.id,
      name: this.userModel.name,
      mail: this.userModel.mail,
      notes: this.userModel.notes,
      roles: this.userModel.roles.map((x) => ({ userId: this.user.id, roleId: x })),
    };

    this.$emit('save-user', newUser);
  }

  deleteUser() {
    this.$confirm(
      'Это действие полностью удалит выбранного пользователя. Продолжить?',
      'Внимание!',
      {
        confirmButtonText: 'Oк',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }
    )
      .then(() => {
        this.$emit('delete-user', this.user);
      })
      .catch(() => {
        void 0;
      });
  }
}
