


























import { Component, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import TopMenu from '@/components/top-menu/TopMenu.vue';
import { Action, Getter } from 'vuex-class';
import {
  GET_USAGE_INFO,
  GET_USAGE_INFO_CONFIG,
  IUsageConfigItem,
  IUsageMessage,
  USAGE_INFO,
} from '@/store/usage-info';
import EChartSample from '@/components/basics/charts/history-chart/e-chart-sample.vue';
import HorizontalBars from '@/components/basics/charts/horizontal-bars/HorizontalBars.vue';
import { IUsageServerGroup } from '@/services/usage/types';
import { DataManager } from '@/services/usage/DataManager';
import UsageDashboardItem from '@/components/usage-dashboard-item/index.vue';
import { CronManager, CronPresets } from '@/services/cron/CronManager';
import { Subject } from 'rxjs';

@Component({
  components: {
    UsageDashboardItem,
    HorizontalBars,
    EChartSample,
    'top-menu': TopMenu,
  },
})
export default class UsageDashboard extends Vue {
  @Action(GET_USAGE_INFO, { namespace: USAGE_INFO })
  getUsageInfo!: () => void;
  @Getter(GET_USAGE_INFO, { namespace: USAGE_INFO })
  data!: IUsageMessage[];
  @Action(GET_USAGE_INFO_CONFIG, { namespace: USAGE_INFO })
  getUsageInfoConfig!: () => void;
  @Getter(GET_USAGE_INFO_CONFIG, { namespace: USAGE_INFO })
  config!: IUsageConfigItem[];
  cron = new CronManager();

  get groups(): IUsageServerGroup[] {
    return this.config && this.data && [...DataManager.buildServerGroups(this.config, this.data)];
  }

  resizer: Subject<boolean> = new Subject<boolean>();

  async reQuery() {
    await this.getUsageInfoConfig();
    await this.getUsageInfo();
  }

  mounted() {
    window.onresize = () => setTimeout(() => this.resizer.next(true), 100);
    this.tryStartTimer();
    this.reQuery();
  }

  beforeDestroy() {
    try {
      this.cron.removeSchedule();
    } catch {
      //
    }
  }

  tryStartTimer() {
    try {
      this.cron.removeSchedule();
      this.cron.createSchedule(CronPresets.EveryMinute).subscribe((datetime) => {
        this.getUsageInfoConfig();
        this.getUsageInfo();
      });
    } catch {
      //
    }
  }
}
