
export const WARNING_MSG = 'Внимание!';
export const HAS_UNSAVED_CHANGES_MSG = 'Есть не сохраненные изменения.'
export const CHOOSE_ACTION_MSG = 'Выберете дальнейшее действие.'
export const ARE_U_SURE_MSG = 'Вы уверены?'
export const CANCEL_MSG = 'Отмена';
export const SAVE_MSG = 'Сохранить';
export const NO_SAVE_MSG = 'Не сохранять';
export const RESET_MSG = 'Сбросить';
export const EDIT_MSG = 'Редактировать';
export const RETURN_MSG = 'Вернуться';

// action
export const onConfirmEvent= 'onConfirmEvent';

