






























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { RestApiService } from '@/services';
import { getApiUrl, TR_TYPE } from '@/store/tools';

const rest = new RestApiService();

@Component({
  components: {},
})
export default class LogView extends Vue {
  module: any | null = null;
  isEdit = false;
  isSaving = false;

  currentRejimeModel: number | null = null;

  @Prop() calculator!: any;
  @Prop() editable!: any;
  @Prop() enums!: any[];

  get logLevelProp() {
    // Value returns as string, this is the feature for golang types check
    const tmp = this.module?.props?.Input?.find((x: any) => x.Name === 'log_level');
    tmp.Value = Number(tmp.Value)
    return tmp
  }

  get logLevelEnums() {
    return (
      this.enums
        ?.filter((x) => x.enumUid === this.logLevelProp?.Uid)
        ?.sort((x, y) => (Number(x.order) < Number(y.order) ? -1 : 1)) || []
    );
  }

  get rejimeOptions() {
    return this.logLevelEnums.map((x) => ({ label: x.name, value: Number(x.order) })); // numeric value in enums is named "order"
  }

  async updateData() {
    const phase = this.$route.query.phase;

    const module = await rest.get<any[]>(
      getApiUrl(
        TR_TYPE.HTTP,
        `/modules/by-uid/${this.calculator.moduleUid}${
          this.calculator.type === 1 ? `:${phase}` : this.calculator.type === 2 ? ':ABC' : ''
        }`
      )
    );

    if (module.status === 200) {
      this.module = module.data;
    }
  }

  onEdit() {
    this.isEdit = true;
    this.currentRejimeModel = this.logLevelProp ? this.logLevelProp.Value : 0
  }

  onCancelEdit() {
    this.isEdit = false;
  }

  onRejimeChange(evt: number) {
    this.currentRejimeModel = evt;
  }

  async onSave() {
    try {
      this.isSaving = true;

      const result = await rest.put(getApiUrl(TR_TYPE.HTTP, '/modules'), {
        ...this.module,
        props: {
          ...this.module.props,
          Input: this.module.props.Input.map((x: any) =>
            x.Name === 'log_level' ? { ...x, Value: this.currentRejimeModel?.toString() /* should save as string */ } : x
          ),
        },
      });

      if (result.status === 200) {
        await this.updateData();
      } else {
        throw result.statusText;
      }
    } finally {
      this.isEdit = false;
      this.isSaving = false;
    }
  }

  mounted() {
    this.updateData();
  }
}
