



















import { Component, Prop } from 'vue-property-decorator';
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';
import Vue from 'vue';

@Component({
  components: {
    spinner: ScaleLoader,
  },
})
export default class IconBtn extends Vue {
  @Prop() content!: string;
  @Prop() icon?: string;
  @Prop() busy!: boolean;
  @Prop() dirty!: boolean;
  @Prop() type?: string;
  click() {
    this.$emit('click');
  }
  get iconStyle(): string {
    return this.icon ? [this.icon, 'btn-icon'].join(' ') : '';
  }
}
