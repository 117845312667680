







































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { Sensor } from '../types';

@Component({
  components: {},
})
export default class ProtocolModbusLocalNetwork extends Vue {
  @Prop() sensorModel!: Sensor | null;
  @Prop() editPermission!: boolean;

  isEdit = false;
  isSaving = false;

  onSave() {
    this.isSaving = true;
    this.$emit('save-sensor', this.sensorModel, () => {
      this.isEdit = false;
      this.isSaving = false;
    });
  }

  onEdit() {
    this.isEdit = true;
  }

  onCancelEdit() {
    this.$emit('cancel-edit');
    this.isEdit = false;
  }
}
