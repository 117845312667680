








































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import ConfirmChanges from '@/components/confirm-changes/index.vue';
import TablePagination from '@/components/basics/table/TablePagination.vue';

import { Sensor, Reference } from '../types';
import { calculatePhases, getModelsDiff, getEditableModel, getPage } from '../utils';
import ExportDataView from '../ExportDataView.vue';

@Component({
  components: {
    'confirm-changes': ConfirmChanges,
    'table-pagination': TablePagination,
    'export-data': ExportDataView,
  },
})
export default class Protocol5081Subscriptions extends Vue {
  @Prop() sensorModel!: Sensor | null;
  @Prop() references!: Reference[] | null;
  @Prop() editPermission!: boolean;
  @Prop() assets!: any[] | null;

  showConfirm = false;
  isEdit = false;
  isSaving = false;
  editable: Record<string, {}> = {};

  sort: { order: string | null; prop: string } = { prop: 'name', order: 'ascending' };

  validators = {
    ['report']: (row: any) => true,
    ['dataSet']: (row: any) => true,
    ['position']: (row: any) => true,
    ['attribute']: (row: any) => true,
    ['type']: (row: any) => true,
    ['error']: (row: any) => row.error === undefined || !isNaN(Number(row.error)),
  };

  get config() {
    return {
      fileName: `IEC_61850_8_1_${this.sensorModel?.name}`,
      columns: [
        { prop: 'name', name: 'Диспетчерское наименование' },
        { prop: 'windingAlias', name: 'Обозначение обмотки' },
        { prop: 'phase', name: 'Фаза' },
        { prop: 'parameter', name: 'Параметр' },
        { prop: 'report', name: 'Отчет' },
        { prop: 'dataSet', name: 'Набор данных' },
        { prop: 'position', name: 'Положение' },
        { prop: 'attribute', name: 'Атрибут данных' },
        { prop: 'type', name: 'Тип данных' },
        { prop: 'error', name: 'Погрешность канала' },
      ],
    };
  }

  get isValid() {
    const validatorsEntries = Object.entries(this.validators);
    const entries = Object.entries(this.editable);

    return (
      this.editable && !entries.some(([, x]) => validatorsEntries.some(([key, value]) => !value(x)))
    );
  }

  get tableData() {
    const sources = this.sensorModel?.sources.flatMap((x) => x) ?? [];
    const assetsMap = this.assets?.reduce((acc, x) => ({
      ...acc,
      [x.equipId]: x
    }), {});

    const getProp = (x: any, name: string, field?: string) =>
      x.props?.find((prop: any) => prop.Name === name)?.[field || 'Value'];
      
    const data = sources
      .map((x) => {
        const [report, dataSet, position, attribute] = (x.selector.selector ?? '').split(';');
        const asset = assetsMap?.[x.equipId];

        return {
          name: x.equipment.name,
          windingAlias: asset ? getProp(asset, 'Alias') : '',
          phase: calculatePhases(x.flags),
          parameter: this.references?.find((ref) => ref.id === x.typeId)?.notes,
          report: report,
          dataSet: dataSet,
          position: position,
          attribute: attribute,
          type: x.sensor?.protocol?.appid,
          error: Number(x.deviation).toString(),
          uuid: x.uuid,
          sensorUuid: x.sensor?.uuid,
        };
      })
      .sort((x, y) => (`${x.name}${x.phase}` < `${y.name}${y.phase}` ? -1 : 1));

    return data;
  }

  get page() {
    return getPage(this.tableData, this.sort, this.$route.query);
  }

  onSortChange(x: any) {
    this.sort = x;
  }

  async handleConfirmAction(saveFlag: boolean) {
    this.showConfirm = false;
    if (saveFlag) {
      this.onSave();
    } else {
      this.isEdit = false;
    }
  }

  getNewModel(sensor: Sensor, data: Record<string, any>) {
    const entries = Object.entries(data);

    const newSensor = {
      ...sensor,
      sources: entries
        .filter(([key, row]) => sensor.sources.find((y) => y.uuid === row.uuid))
        .map(([key, row]: [string, any]) => {
          const oldSource = sensor.sources.find((x) => x.uuid === row.uuid);

          return {
            ...oldSource,
            deviation: Number(row.error),
            selector: {
              ...oldSource?.selector,
              selector: `${row.report};${row.dataSet};${row.position};${row.attribute}`,
            },
            sensor: {
              ...oldSource?.sensor,
              protocol: { ...oldSource?.sensor?.protocol, appid: data[row.sensorUuid].type },
            },
          };
        }),
    };

    return newSensor;
  }

  getEditableModel(data: any[]) {
    const sensorsUuids = data.map((x) => x.sensorUuid);

    return {
      ...getEditableModel(data),
      ...Object.fromEntries(
        sensorsUuids.map((x) => [
          x,
          {
            type: data.find((y) => x === y.sensorUuid)?.type,
          },
        ])
      ),
    };
  }

  onSave() {
    const dirties = getModelsDiff(this.getEditableModel(this.tableData), this.editable);
    if (!dirties.length || !Array.isArray(dirties) || !this.sensorModel) {
      this.isEdit = false;
      this.isSaving = false;
    } else {
      this.isSaving = true;
      const newModel = this.getNewModel(this.sensorModel, this.editable);
      this.$emit('save-sensor', newModel, () => {
        this.isEdit = false;
        this.isSaving = false;
      });
    }
  }

  onEdit() {
    this.editable = this.getEditableModel(this.tableData);
    this.isEdit = true;
  }

  onCancelEdit() {
    const dirties = getModelsDiff(this.getEditableModel(this.tableData), this.editable);
    if (!dirties.length || !this.isValid) {
      this.$emit('cancel-edit');
      this.isEdit = false;
    } else {
      this.showConfirm = true;
    }
  }
}
