


















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';

import { DETAILS_CONST } from '@/store/details';
import { IEquipDetails } from '@/store/details/types';
import { QueryParamsService } from '@/utils';
import { IEquip } from '@/store/equipment/types';
import { EQUIP_STORE_CONST } from '@/store/equipment/constants';
import FileDownloader from '../files/file-downloader.vue';

type QueryType = {
  tab?: string;
};

@Component({
  components: {
    'file-downloader': FileDownloader,
  },
})
export default class Level0TopicCard extends Vue {
  queryParams!: QueryParamsService<QueryType>;

  @Prop() item!: IEquip | null;

  @Getter(DETAILS_CONST.EQUIPMENT_DETAILS, { namespace: DETAILS_CONST.DETAILS })
  equipmentDetails?: IEquipDetails;

  @Getter(EQUIP_STORE_CONST.EXPANDED_ITEMS, { namespace: EQUIP_STORE_CONST.TREE }) expanded!: Set<number>;

  @Mutation(EQUIP_STORE_CONST.TREE_ITEMS_EXPAND, { namespace: EQUIP_STORE_CONST.TREE }) expandItems!: (
    keys: Set<number>
  ) => void;
  @Mutation(EQUIP_STORE_CONST.TREE_ITEMS_COLLAPSE, { namespace: EQUIP_STORE_CONST.TREE }) collapseItems!: (
    keys: Set<number>
  ) => void;

  get level1Devices() {
    return this.item?.children.filter((x) => x.children.length) ?? [];
  }

  get selectedDevice() {
    const { tab } = this.queryParams.get();

    if (tab) {
      return this.item?.children.find((x) => x.id === Number(tab)) ?? null;
    } else {
      return null;
    }
  }

  getDevices(nodes: IEquip[], predicate: (x: IEquip) => boolean) {
    const rec = (nodes: IEquip[]): number =>
      nodes.filter(predicate).length +
      nodes.map((x) => rec(x.children)).reduce((x, acc) => x + acc, 0);

    return rec(nodes);
  }

  getAllCount(device: IEquip) {
    return this.getDevices(device.children, (x) => x.children.length === 0);
  }

  getActiveCount(device: IEquip) {
    return this.getDevices(device.children, (x) => x.children.length === 0 && x.inService);
  }

  getDisabledCount(device: IEquip) {
    return this.getDevices(device.children, (x) => x.children.length === 0 && !x.inService);
  }

  onDeviceClick(device: IEquip) {
    this.expandChoosenItems(device);
    this.queryParams.update({ tab: device.id.toString() });
  }

  created() {
    this.queryParams = new QueryParamsService<QueryType>(() => ({
      tab: this.$route.query.tab,
    }));
  }

  expandChoosenItems(device?: IEquip | null) {
    if (this.item && device) {
      this.expandItems(new Set([this.item.id]));
      this.collapseItems(new Set(this.item.children.map((x) => x.id)));
      this.expandItems(new Set([device.id]));
    }
  }

  mounted() {
    this.expandChoosenItems(this.selectedDevice);
  }
}
