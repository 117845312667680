





























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';

import { LocaleManager } from '@/services/locale/locale-manager';
import IconBtn from '@/components/basics/buttons/icon-btn.vue';

import { saveData, savePdf, saveXls, saveScreen } from '@/components/files/save-data';

@Component({
  components: {
    'icon-btn': IconBtn,
  },
})
export default class ExportDataView extends Vue {
  @Prop() content!: string;
  @Prop() getData!: () => Promise<any[]>;
  @Prop() config!: {
    fileName: string;
    columns: { prop: string; name: string }[];
  };
  @Prop() pdfFontSize!: number | undefined;

  isBusy = false;
  saveDialogVisible = false;
  fileName = '';

  propsSelector(columns: any[]) {
    return columns
      .filter((j: any) => j.order > 0)
      .map((c: any) => c.dbProps)
      .join(',');
  }

  openDialog() {
    this.fileName = this.config.fileName;
    this.saveDialogVisible = true;
  }

  async saveFile(saver: () => any) {
    this.isBusy = true;
    this.saveDialogVisible = false;
    try {
      await saver();
    } finally {
      this.isBusy = false;
    }
  }

  csvContent(data: any[]) {
    let csvContent = '';
    csvContent += [
      this.config.columns.map((x) => x.name).join(';'),
      ...data.map((item) => this.config.columns.map((column) => item[column.prop] || '').join(';')),
    ].join('\n');

    return URL.createObjectURL(
      new Blob(['\ufeff' + csvContent], { type: ` text/csv;charset=${LocaleManager.getCharset()}` })
    );
  }

  async saveCsv() {
    saveData(`${this.fileName}.csv`, this.csvContent(await this.getData()));
  }

  xlsContent(data: any[]) {
    const xlsData = [
      this.config.columns.map((x) => x.name),
      ...data.map((item) => this.config.columns.map((column) => item[column.prop] || '')),
    ];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(xlsData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Данные');

    return workbook;
  }

  async saveXls() {
    saveXls(`${this.fileName}.xls`, this.xlsContent(await this.getData()));
  }

  saveImage(fileType: string) {
    return async () => {
      await saveScreen(`${this.fileName}.${fileType}`, fileType);
    };
  }

  pdfContent(data: any[]) {
    return pdfMake.createPdf({
      content: {
        layout: 'lightHorizontalLines',
        table: {
          headerRows: 1,
          body: [
            this.config.columns.map((x) => x.name),
            ...data.map((item) => this.config.columns.map((column) => item[column.prop] || '')),
          ],
        },
      },
      pageOrientation: 'landscape',
      defaultStyle: {
        fontSize: this.pdfFontSize || 10,
        bold: false,
      },
    });
  }

  async savePdf() {
    savePdf(`${this.fileName}.pdf`, this.pdfContent(await this.getData()));
  }
}
