import pdfMake from 'pdfmake/build/pdfmake';
import { capture, OutputType, LogLevel } from 'html-screen-capture-js';
import XLSX from 'xlsx';

import { RestApiService } from '@/services';
import { getApiUrl, TR_TYPE } from '@/store/tools';

import { fontStyles } from './font-styles';

const path = getApiUrl(TR_TYPE.HTTP, '/create-screen');
const rest = new RestApiService();

export const savePdf = (name: string, doc: any) => {
  doc.download(`${name}`);
};

export const saveXls = (name: string, doc: any) => {
  XLSX.writeFile(doc, `${name}`);
};

const dataURItoBlob = (dataURI: string) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([ab], { type: mimeString });
  return blob;
};

export const saveData = (name: string, content: any) => {
  if (navigator.msSaveOrOpenBlob) {
    const blob = dataURItoBlob(content);
    navigator.msSaveOrOpenBlob(blob, name);
  } else {
    const link = document.createElement('a');
    link.href = content;
    link.download = name;
    link.click();
  }
};

const loadImage = (fileType: string, width: number, height: number): Promise<any> => {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        const str = capture(OutputType.STRING, document, {
          logLevel: LogLevel.OFF,
          classesOfIgnoredDocBodyElements: ['main-header', 'main-tree', 'el-dialog__wrapper'],
          rulesToAddToDocStyle: [
            fontStyles,
            `body { width: ${width}px !important; height: ${height}px !important; }`,
          ],
        });

        const result = await rest.put(path, { html: str, type: fileType });
        resolve(result);
      } catch (ex) {
        reject(ex);
      }
    }, 500);
  });
};

export const saveScreen = async (name: string, fileType: string) => {
  const node = document.getElementById('right-content');

  if (node) {
    const ratio = (navigator.msSaveOrOpenBlob as any) ? 1 : window.devicePixelRatio;
    const result = await loadImage('png', node.clientWidth * ratio, node.clientHeight * ratio);

    if (result.status === 200) {
      if (fileType === 'pdf') {
        pdfMake
          .createPdf({
            content: { image: result.data.image, fit: [node.clientWidth, node.clientHeight] },
            pageMargins: [0, 0, 0, 0],
            pageSize: { width: node.clientWidth, height: node.clientHeight },
            pageOrientation: 'landscape',
          })
          .download(name);
      } else {
        saveData(name, result.data.image);
      }
    }
  }
};
