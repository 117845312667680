import { render, staticRenderFns } from "./SensorJournalTable.vue?vue&type=template&id=66229aa1&scoped=true&"
import script from "./SensorJournalTable.vue?vue&type=script&lang=ts&"
export * from "./SensorJournalTable.vue?vue&type=script&lang=ts&"
import style1 from "./SensorJournalTable.vue?vue&type=style&index=1&id=66229aa1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66229aa1",
  null
  
)

export default component.exports