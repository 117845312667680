












import Vue from 'vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import echarts, { ECharts, init } from 'echarts';
import { OptionsBuilder } from '@/components/basics/charts/horizontal-bars/OptionsBuilder';
import { IUsageMessage } from '@/store/usage-info';
import { DataManager } from '@/services/usage/DataManager';
import theme from '@/assets/themes/echarts/supreme.json';
import { IBarDataItem, IUsageChartSettings } from '@/services/usage/types';
import { Subject } from 'rxjs';

echarts.registerTheme('SUPREME', theme);

@Component
export default class HorizontalBars extends Vue {
  @Prop() settings?: IUsageChartSettings;
  @Prop() message!: IUsageMessage;
  @Prop() resizer!: Subject<boolean>;
  @Ref('chart') chartDiv: any;
  horizontalBarsChart?: ECharts;

  mounted() {
    this.resizer.subscribe((flag) => {
      if (flag && this.horizontalBarsChart) {
        this.horizontalBarsChart.resize();
      }
    });

    if (this.settings) this.buildChart(this.settings, this.items);
  }

  get items(): IBarDataItem[] {
    if (this.settings && this.message) {
      return DataManager.extract(this.settings, this.message);
    }
    return [];
  }

  chartHeight(): string {
    const count = this.items?.length;
    return count >= 1 ? `${count * 15 + 75}px` : `50px`;
  }

  buildChart(settings: IUsageChartSettings, items: IBarDataItem[]) {
    if (this.horizontalBarsChart) this.horizontalBarsChart.dispose();
    if (this.chartDiv) {
      this.horizontalBarsChart = init(this.chartDiv, 'SUPREME');
      this.horizontalBarsChart?.setOption(OptionsBuilder.build(items, settings));
    }
  }

  @Watch('message', { immediate: true }) onData(val: IUsageMessage, old: IUsageMessage) {
    if (val === old || !val) return;
    if (this.horizontalBarsChart) this.horizontalBarsChart.dispose();
    if (this.chartDiv && this.settings) {
      this.horizontalBarsChart = init(this.chartDiv, 'SUPREME');
      if (this.items?.length)
        this.horizontalBarsChart?.setOption(OptionsBuilder.build(this.items, this.settings));
    }
  }
}
