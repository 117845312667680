
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { IUsageChartSettings } from '@/services/usage/types';
import HorizontalBars from '@/components/basics/charts/horizontal-bars/HorizontalBars.vue';
import { IUsageMessage } from '@/store/usage-info';
import { Subject } from 'rxjs';

@Component({
  name: 'usage-dashboard-item',
  components: {
    HorizontalBars,
  },
})
export default class UsageDashboardItem extends Vue {
  @Prop() title!: string;
  @Prop() resizer!: Subject<boolean>;
  // server data
  @Prop() message?: IUsageMessage;
  @Prop() settings?: IUsageChartSettings[];

  get itemSettings() {
    return this.settings ?? [];
  }

  get itemData() {
    return this.message;
  }
}
