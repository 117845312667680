


















































































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import ConfirmChanges from '@/components/confirm-changes/index.vue';
import TablePagination from '@/components/basics/table/TablePagination.vue';
import TableFilter from '@/components/basics/table/TableFilter.vue';
import TableSorter from '@/components/basics/table/TableSorter.vue';
import { getColumnFilter } from '@/components/basics/table/utils';

import { Sensor } from '../types';
import { calculatePhases, getModelsDiff, getEditableModel, getDeviation, mergeSort, numberSorter } from '../utils';
import ExportDataView from '../ExportDataView.vue';

@Component({
  components: {
    'confirm-changes': ConfirmChanges,
    'table-pagination': TablePagination,
    'table-filter': TableFilter,
    'table-sorter': TableSorter,
    'export-data': ExportDataView,
  },
})
export default class Protocol5104Sources extends Vue {
  @Prop() sensorModel!: Sensor | null;
  @Prop() editPermission!: boolean;

  showConfirm = false;
  isEdit = false;
  isSaving = false;
  editable: Record<string, {}> = {};

  sort: { order: string | null; prop: string } = { prop: 'name', order: 'ascending' };

  filtersMap: any = {};
  filtersState = {
    filterMin: {},
    filterMax: {},
    filterOptions: [],
    filterQueryOptions: [],
    numberFilterOptions: [],
    stringFilterOptions: [],
    filterGuidOptions: [],
  };

  validators = {
    ['infoObject']: (row: any) =>
      row.infoObject === undefined ||
      (!isNaN(Number(row.infoObject)) &&
        Number(row.infoObject) > 0 &&
        Number(row.infoObject) % 1 === 0),
    ['error']: (row: any) => row.error === undefined || !isNaN(Number(row.error)),
    ['type']: (row: any) =>
      row.type === undefined || (!isNaN(Number(row.type)) && Number(row.type) > 0),
  };

  columns = {
    ['name']: { Type: 'Enum', dbProps: 'name', Uid: 'name' },
    ['phase']: { Type: 'Enum', dbProps: 'phase', Uid: 'phase' },
    ['parameter']: { Type: 'Enum', dbProps: 'parameter', Uid: 'parameter' },
  };

  get config() {
    return {
      fileName: `IEC_60870_5_104_${this.sensorModel?.name}`,
      columns: [
        { prop: 'name', name: 'Диспетчерское наименование' },
        { prop: 'phase', name: 'Фаза' },
        { prop: 'parameter', name: 'Параметр' },
        { prop: 'type', name: 'Общий адрес ASDU', sorter: numberSorter },
        { prop: 'infoObject', name: 'Адрес объекта информации', sorter: numberSorter },
        { prop: 'error', name: 'Погрешность, %', sorter: numberSorter },
      ],
    };
  }

  sortColumn(a: any, b: any) {
    return Number(a[this.sort.prop]) - Number(b[this.sort.prop]);
  }

  get isValid() {
    const validatorsEntries = Object.entries(this.validators);
    const entries = Object.entries(this.editable);

    return (
      this.editable && !entries.some(([, x]) => validatorsEntries.some(([key, value]) => !value(x)))
    );
  }

  get tableData() {
    const sources = this.sensorModel?.sources.flatMap((x) => x) ?? [];

    const data = sources
      .map((x) => ({
        name: x.equipment.name,
        phase: calculatePhases(x.flags),
        parameter: x.type,
        type: x.sensor?.protocol.appid,
        infoObject: x.selector.selector,
        error: getDeviation(x),
        uuid: x.uuid,
        sensorUuid: x.sensor?.uuid,
      }))
      .sort((x, y) => (`${x.name}${x.phase}` < `${y.name}${y.phase}` ? -1 : 1));

    return data;
  }

  get filteredData() {
    const queryFilters =
      this.queryFilter &&
      Object.fromEntries(
        JSON.parse(decodeURIComponent(this.queryFilter)).map((x: any) => [x.name, x])
      );

    return !queryFilters
      ? this.tableData
      : this.tableData.filter((data: any) =>
          Object.values(queryFilters).reduce(
            (has, filter: any) =>
              has &&
              filter.type === 'Enum' &&
              (!filter.options.length || filter.options.includes(data[filter.name])),
            true
          )
        );
  }

  get queryFilter(): any {
    return this.$route.query.filter;
  }

  get sorter() {
    const configSorter = this.config.columns.find((x) => x.prop === this.sort.prop)?.sorter;

    return configSorter
      ? (x: any, y: any) =>
          this.sort.order === 'ascending'
            ? configSorter(x[this.sort.prop], y[this.sort.prop])
            : configSorter(y[this.sort.prop], x[this.sort.prop])
      : (x: any, y: any) =>
          this.sort.order === 'ascending'
            ? x[this.sort.prop] < y[this.sort.prop]
            : y[this.sort.prop] < x[this.sort.prop];
  }

  get page() {
    const sorted =
      this.sort.order === null
        ? this.filteredData
        : mergeSort(
            mergeSort([...this.filteredData], (x: any, y: any) => x.name > y.name),
            this.sorter
          );

    if (sorted.length <= 10) {
      return sorted;
    } else {
      const { limit, offset } = this.$route.query;
      return sorted.slice(Number(offset), Number(offset) + Number(limit));
    }
  }

  @Watch('queryFilter', { immediate: true }) onQueryFilterChange(val: any) {
    const queryFilters =
      val && Object.fromEntries(JSON.parse(decodeURIComponent(val)).map((x: any) => [x.name, x]));

    const columns = Object.values(this.columns);

    const refs = columns.flatMap((x) =>
      [...new Set(this.filteredData.map((y: any) => y[x.dbProps]))].map((y) => ({
        enumUid: x.Uid,
        order: y,
        notes: y,
      }))
    );

    columns.forEach((column: any) => {
      const currentFilter = queryFilters && queryFilters[column.dbProps];
      const oldFilter: any = this.filtersMap[column.dbProps];

      const keyValue = getColumnFilter({ query: val, columns: columns, refs: refs }, column, oldFilter, currentFilter);
      if(column.dbProps === 'parameter') {
        keyValue?.options.sort((a: any, b: any) => new Intl.Collator().compare(a.value, b.value));
      }
      
      Vue.set(
        this.filtersMap,
        column.dbProps,
        keyValue
      );
    });
  }

  onSortChange(x: any) {
    this.sort = x;
  }

  async handleConfirmAction(saveFlag: boolean) {
    this.showConfirm = false;
    if (saveFlag) {
      this.onSave();
    } else {
      this.isEdit = false;
    }
  }

  getNewModel(sensor: Sensor, data: Record<string, any>) {
    const entries = Object.entries(data);

    const newSensor = {
      ...sensor,
      sources: entries
        .filter(([key, row]) => sensor.sources.find((y) => y.uuid === row.uuid))
        .map(([key, row]: [string, any]) => {
          const oldSource = sensor.sources.find((x) => x.uuid === row.uuid);

          return {
            ...oldSource,
            deviation: Number(row.error),
            selector: { ...oldSource?.selector, selector: row.infoObject },
            sensor: {
              ...oldSource?.sensor,
              protocol: { ...oldSource?.sensor?.protocol, appid: data[row.sensorUuid].type },
            },
          };
        }),
    };

    return newSensor;
  }

  getEditableModel(data: any[]) {
    const sensorsUuids = data.map((x) => x.sensorUuid);

    return {
      ...getEditableModel(data),
      ...Object.fromEntries(
        sensorsUuids.map((x) => [
          x,
          {
            type: data.find((y) => x === y.sensorUuid)?.type,
          },
        ])
      ),
    };
  }

  onSave() {
    const dirties = getModelsDiff(this.getEditableModel(this.tableData), this.editable);
    if (!dirties.length || !Array.isArray(dirties) || !this.sensorModel) {
      this.isEdit = false;
      this.isSaving = false;
    } else {
      this.isSaving = true;
      const newModel = this.getNewModel(this.sensorModel, this.editable);
      this.$emit('save-sensor', newModel, () => {
        this.isEdit = false;
        this.isSaving = false;
      });
    }
  }

  onEdit() {
    this.editable = this.getEditableModel(this.tableData);
    this.isEdit = true;
  }

  onCancelEdit() {
    const dirties = getModelsDiff(this.getEditableModel(this.tableData), this.editable);
    if (!dirties.length || !this.isValid) {
      this.$emit('cancel-edit');
      this.isEdit = false;
    } else {
      this.showConfirm = true;
    }
  }
}
