


























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import {
  REPORT_DATA,
  EQUIPMENT_CHILDREN,
  REPORT_TABS,
  SELECTED_STATE_TAB,
} from '@/store/report-data/constants';
import { IReportTab } from '@/store/report-data/types';
import DetailsReport from '@/components/details-report/index.vue';
import { IReportQuery } from '@/store/report-data/types';
import { QueryParamsService } from '@/utils';

import SubstationState from '../items-tab/panes/equip-state/SubstationState.vue';

type QueryType = { tab?: string };

@Component({
  components: {
    'details-report': DetailsReport,
  },
})
export default class Level0ContentCard2 extends Vue {
  queryParams!: QueryParamsService<QueryType>;

  @Prop() item: any;

  @Action(EQUIPMENT_CHILDREN, { namespace: REPORT_DATA })
  getEquipmentChildren!: (payload: IReportQuery) => void;

  @Getter(REPORT_TABS, { namespace: REPORT_DATA })
  reportTabs!: IReportTab[];

  get allTabs() {
    return this.reportTabs && this.item && this.item.groupType === 'substation'
      ? [
          {
            key: '-1',
            name: 'Сводное состояние',
            component: SubstationState,
            type: 'state',
          },
          ...this.reportTabs,
        ]
      : this.reportTabs;
  }

  get selectedTab() {
    return this.queryParams.get().tab || this.allTabs[0]?.key || '';
  }
  @Action(SELECTED_STATE_TAB, { namespace: REPORT_DATA })
  setSelectedTab!: (payload: IReportTab) => void;
  get selected(): IReportTab | null {
    const find = this.allTabs.find((i) => i.key === this.selectedTab);
    if (!find) return null;
    this.setSelectedTab(find);
    return { ...find };
  }

  get scheme() {
    return this.selected?.scheme;
  }

  onChangeTab(tab: any) {
    this.queryParams.update({ tab: tab.$vnode.key }, { removeExcept: [] });
  }

  mounted() {
    const r = this.$route;
    this.item && this.getEquipmentChildren({ uid: this.item.uid, equipId: this.item?.id });
  }

  created() {
    this.queryParams = new QueryParamsService<QueryType>(() => ({
      tab: this.$route.query.tab,
    }));
  }
}
