





















import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import TopMenu from '@/components/top-menu/TopMenu.vue';
import { RestApiService } from '@/services';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { QueryParamsService } from '@/utils';

import EventJournalTableView from './EventJournalTableView.vue';
import { Reference, Enum, Module, EquipmentWithAssetId } from './types';
import { Mutation } from 'vuex-class';
import { DICTIONARIES_CONST } from '@/store/dictionaries/dictionaries.const';
import { ITabRef } from '@/store/dictionaries/dictionaries.types';
import { DataConverter } from '../basics/table/converters/DataConverter';

type QueryType = { tab?: string };

const equipmentsPath = getApiUrl(TR_TYPE.HTTP, '/equipment');
const referencesPath = getApiUrl(TR_TYPE.HTTP, '/references');
const enumsPath = getApiUrl(TR_TYPE.HTTP, '/algorithms-config/enums');
const rest = new RestApiService();

const tables = ['equipment_change_state'];

const { GET_TAB_REFS_SUCCESS, DICTIONARIES } = DICTIONARIES_CONST;

@Component({
  components: {
    'top-menu': TopMenu,
    'event-journal-table-view': EventJournalTableView,
  },
})
export default class UpdateJournalsView extends Vue {
  queryParams!: QueryParamsService<QueryType>;

  equipments: EquipmentWithAssetId[] | null = null;
  references: Reference[] | null = null;
  enums: Enum[] | null = null;
  modules: Module[] | null = null;

  get selectedTab(): string {
    const { tab } = this.queryParams?.get();
    return tab || 'equipment_change_state';
  }

  onChangeTab(tab: any) {
    this.queryParams.update({ tab: tab.$vnode.key }, { removeExcept: [] });
  }

  async updateData() {
    const [equipments, references, enums] = await Promise.all([
      rest.get<EquipmentWithAssetId[]>(`${equipmentsPath}/all-with-asset-id`),
      rest.put<Reference[]>(`${referencesPath}/by-names`, tables),
      rest.get<Enum[]>(`${enumsPath}`)
    ]);

    if (
      equipments.status === 200 &&
      references.status === 200 &&
      enums.status === 200
      // modules.status === 200
    ) {
      this.equipments = equipments?.data;
      DataConverter.createAssetConverter(equipments?.data);
      this.references = references?.data;
      this.enums = enums?.data;
      // this.modules = modules?.data;
      
      this.setRefsStore(enums?.data?.map((d: any) => {
        const { enum_uid, ...rest } = d;
        return {
          enumUid: enum_uid,
          ...rest,
        };
      }));
    }
  }

  created() {
    this.queryParams = new QueryParamsService<QueryType>(() => ({
      tab: this.$route.query.tab,
    }));
  }

  async mounted() {
    await this.updateData();
  }

  @Mutation(GET_TAB_REFS_SUCCESS, { namespace: DICTIONARIES }) //GET_TAB_REFS_SUCCESS
  setRefsStore!: (data: ITabRef[]) => void;

}
