
























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { Role, User, UserRole } from './types';

type QueryType = { id?: string; mode?: string };

@Component({})
export default class RolesView extends Vue {
  @Prop() editable!: boolean;
  @Prop() roles!: Role<User<UserRole>>[];
  @Prop() users!: User<Role<UserRole>>[];
  @Prop() selectedRole!: Role<User<UserRole>> | null;

  onRoleClick(role: Role<User<UserRole>>) {
    this.$emit('select-role', role);
  }

  addRole() {
    this.$emit('add-role');
  }
}
