var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-row',{staticClass:"main-header"},[_c('el-col',{attrs:{"span":24}},[_c('div',{staticClass:"grid-content bg-purple-dark"},[_c('top-menu')],1)])],1),_c('div',{staticClass:"body"},[_c('resizable',{staticClass:"main-tree",style:({ display: _vm.drawerVisible ? undefined : 'none' }),attrs:{"width":_vm.drawerWidth},on:{"on-resize":_vm.setDrawerWidth}},[_c('sensors-tree',{attrs:{"sensors":_vm.sensors,"equipments":_vm.equipments,"protocol":_vm.currentProtocol,"routeId":_vm.currentRouteId,"substation":_vm.currentSubstation,"states":_vm.sensorsStates}})],1),_c('div',{style:({ width: _vm.rightContentWidth }),attrs:{"id":"right-content"}},[_c('custom-scrollbar',{staticStyle:{"width":"100%"},attrs:{"size-adder":"10vh"}},[(_vm.currentSensors && _vm.currentSensors[0])?_c('sensor',{key:_vm.currentSensors && _vm.currentSensors[0] && _vm.currentSensors[0].id,attrs:{"slot":"content","protocol":_vm.currentProtocol,"sensors":_vm.sensors,"currentSensors":_vm.currentSensors,"equipments":_vm.equipments,"references":_vm.references,"enums":_vm.enums,"editable":_vm.editable,"substation":_vm.currentSubstation,"states":_vm.sensorsStates},on:{"save-sensor":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.onSaveSensor.apply(void 0, args);
},"save-sensor-and-assets":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.onSaveSensorAndAssets.apply(void 0, args);
}},slot:"content"}):(_vm.currentProtocol)?_c('group-sensors-view',{key:_vm.currentProtocol,attrs:{"slot":"content","protocol":_vm.currentProtocol,"sensors":_vm.protocolSensors,"comTradeSensors":_vm.comTradeSensors,"equipments":_vm.equipments,"references":_vm.references,"substation":_vm.currentSubstation,"enums":_vm.enums},slot:"content"}):(_vm.currentSubstation)?_c('kso-parameters-view',{key:("substation_" + (_vm.currentSubstation.id)),attrs:{"slot":"content","sensors":_vm.currentKsoServerSensors,"substation":_vm.currentSubstation,"editable":_vm.editable},on:{"save-sensors":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.onSaveSensors.apply(void 0, args);
}},slot:"content"}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }