export function getCalculatorNameWithoutPrefix(name: string) {
  return name
    .split(':')
    .slice(1)
    .join(':');
}

export function getCalculatorNameWithoutSuffix(name: string) {
  const splitted = name.split(':');
  const last = splitted[splitted.length - 1];

  return name
    .split(':')
    .slice(0, last === 'A' || last === 'B' || last === 'C' || last === 'ABC' ? -1 : undefined)
    .join(':');
}
