import { Encoding } from '@/store/text-decoder/constants';
import { Subject } from 'rxjs';
import jschardet from 'jschardet';

export class Decoder {
  private static _fr: FileReader;
  private static fileReader(): FileReader {
    if (!Decoder._fr) {
      Decoder._fr = new FileReader();
    }
    return Decoder._fr;
  }
  public static DecodeAsText(file: any, encoding = Encoding.IBM866): Subject<string> {
    const subject = new Subject<string>();
    try {
      this.fileReader().readAsText(file, encoding);
      this.fileReader().onload = (ev) => {
        subject.next(ev?.target?.result as string);
      };
      this.fileReader().onerror = (ev) => {
        subject.next('Error');
      };
    } catch (e) {
      subject.next(e.message);
    }
    return subject;
  }

  public static GetEncoding(arrayBuffer: ArrayBuffer) {
    const buffer = new Buffer(arrayBuffer);
    if (buffer[0] === 0xef && buffer[1] === 0xbb && buffer[2] === 0xbf) {
      return Encoding.UTF8;
    }
    if (buffer[0] >= 0x80 && buffer[0] < 0xb0) {
      return Encoding.IBM866;
    }
    // trying auto detection
    const e = jschardet.detect(buffer);
    if(e){
      return e.encoding;
    }
    return Encoding.ASCII;
  }

  public static DecodeAsDataArray(file: any): Subject<string> {
    const subject = new Subject<string>();
    try {
      this.fileReader().readAsArrayBuffer(file);
      this.fileReader().onload = (ev) => {
        const buffer = ev?.target?.result;
         const en = Decoder.GetEncoding(buffer as ArrayBuffer);
        const decoder = new TextDecoder(en as string);
        subject.next(decoder.decode(buffer as ArrayBuffer) as string);
      };
    } catch (e) {
      subject.error(e);
    }
    return subject;
  }
}
