













































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import { Ref } from 'vue-property-decorator';

import TopMenu from '@/components/top-menu/TopMenu.vue';
import { RestApiService } from '@/services';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { QueryParamsService } from '@/utils';
import { CONST } from '@/store/auth/constants';
import { User as StoreUser } from '@/store/auth/types';
import { refreshToken } from '@/services/interceptors';

import RolesView from './RolesView.vue';
import UsersView from './UsersView.vue';
import UsersListView from './UsersListView.vue';
import BaseInfoView from './BaseInfoView.vue';
import LogJournalView from './LogJournalView.vue';
import PermissionsView from './PermissionsView.vue';
import DataAccessView from './DataAccessView.vue';
import { Role, User, Organization, UserRole, DeviceType } from './types';

const rest = new RestApiService();

type QueryType = { left?: string; right?: string; id?: string; mode?: string };

@Component({
  components: {
    'top-menu': TopMenu,
    'roles-view': RolesView,
    'users-view': UsersView,
    'users-list-view': UsersListView,
    'base-info-view': BaseInfoView,
    'log-journal-view': LogJournalView,
    'permissions-view': PermissionsView,
    'data-access-view': DataAccessView,
  },
})
export default class AdminView extends Vue {
  queryParams!: QueryParamsService<QueryType>;
  roles: Role<UserRole>[] | null = null;
  users: User<UserRole>[] | null = null;
  organizations: Organization[] | null = null;
  deviceTypes: DeviceType[] | null = null;
  roleCreated = false;

  // need for resolve
  // https://github.com/ElemeFE/element/issues/12052
  @Ref('listTabs') listTabs: any;
  @Ref('rolesTabs') rolesTabs: any;
  @Ref('usersTabs') usersTabs: any;

  @Getter(CONST.GET_USER, { namespace: CONST.AUTH }) user!: StoreUser | null;

  get rights() {
    return (this.user?.roles || []).map((x) => x.role.rights).reduce((x, y) => x | y, 0);
  }

  get readRole() {
    return !!(this.rights & 0b1);
  }

  get writeRole() {
    return !!(this.rights & 0b10);
  }

  get selectedListTab() {
    return this.queryParams.get().left;
  }

  get selectedContentTab() {
    return this.queryParams.get().right;
  }

  get rolesWithUsers() {
    const users = this.users?.filter((x) => this.readRole || x.id === this.user?.id);
    const roles = this.roles?.filter(
      (x) => this.readRole || this.user?.roles.some((y) => y.roleId === x.id)
    );

    return roles?.map((role) => ({
      ...role,
      users: users?.filter((user) => user.roles.some((x) => x.roleId === role.id)),
    }));
  }

  get usersWithRoles() {
    const users = this.users?.filter((x) => this.readRole || x.id === this.user?.id);
    const roles = this.roles?.filter(
      (x) => this.readRole || this.user?.roles.some((y) => y.roleId === x.id)
    );

    return users?.map((user) => ({
      ...user,
      roles: roles?.filter((role) => role.users.some((y) => y.userId === user.id)),
    }));
  }

  get selectedRole() {
    const roleId = Number(this.queryParams.get().id);
    const role = this.rolesWithUsers?.find((x) => x.id === roleId);
    return role || null;
  }

  get selectedUser() {
    const userId = Number(this.queryParams.get().id);
    const user = this.usersWithRoles?.find((x) => x.id === userId);
    return user || null;
  }

  get isRoleAdding() {
    return !this.roleCreated && this.queryParams.get().mode === 'add';
  }

  onChangeListTab(tab: any) {
    if (this.listTabs) {
      this.listTabs.currentName = this.selectedListTab;
    }

    this.queryParams.update(
      tab.$vnode.key === 'roles'
        ? {
            left: tab.$vnode.key,
            id: this.roles ? this.roles[0].id?.toString() : undefined,
            right: 'permissions',
          }
        : {
            left: tab.$vnode.key,
            id: this.users ? this.users[0].id?.toString() : undefined,
            right: 'base-info',
          },
      { removeExcept: [] }
    );
  }

  onChangeContentTab(tab: any) {
    if (this.selectedListTab === 'users') {
      if (this.usersTabs) {
        this.usersTabs.currentName = this.selectedContentTab;
      }
    }

    if (this.selectedListTab === 'roles') {
      if (this.rolesTabs) {
        this.rolesTabs.currentName = this.selectedContentTab;
      }
    }

    this.queryParams.update({ right: tab.$vnode.key, mode: undefined });
  }

  onChangeRole(role: Role<User<UserRole>>) {
    this.queryParams.update({ id: role.id.toString(), mode: undefined });
  }

  onChangeUser(user: User<Role<UserRole>>) {
    this.queryParams.update({ id: user.id.toString(), mode: undefined });
  }

  async onDeleteRole(role: any) {
    const result = await rest.delete(getApiUrl(TR_TYPE.HTTP, `/roles/${role.id}`));
    if (result.status !== 200) {
      this.$notify.error({
        title: 'Ошибка!',
        message: '',
      });
    } else {
      this.reloadData();
    }
  }

  async onSaveRole(role: any) {
    const result = await rest.put(getApiUrl(TR_TYPE.HTTP, '/roles'), role);
    if (result.status !== 200) {
      this.$notify.error({
        title: 'Ошибка!',
        message: '',
      });
    } else {
      await refreshToken();
      await this.reloadData();
    }
  }

  async onDeleteUser(user: any) {
    const result = await rest.delete(getApiUrl(TR_TYPE.HTTP, `/users/${user.id}`));
    if (result.status !== 200) {
      this.$notify.error({
        title: 'Ошибка!',
        message: '',
      });
    } else {
      this.reloadData();
    }
  }

  async onSaveUser(user: any) {
    const result = await rest.put(getApiUrl(TR_TYPE.HTTP, '/users'), user);
    if (result.status !== 200) {
      this.$notify.error({
        title: 'Ошибка!',
        message: '',
      });
    }
    await this.reloadData();
  }

  onAddRoleClick() {
    this.roleCreated = false;
    this.queryParams.update({ mode: 'add', id: undefined });
  }

  async onAddRole(role: any) {
    const result = await rest.post(getApiUrl(TR_TYPE.HTTP, '/roles'), role);
    if (result.status !== 201) {
      this.$notify.error({
        title: 'Ошибка!',
        message: '',
      });
    } else {
      await this.reloadData();
      this.roleCreated = true;
      setTimeout(() => {
        this.queryParams.update({ id: result.data.id.toString(), mode: undefined });
      }, 0);
    }
  }

  async onAddUser(user: any) {
    const result = await rest.post(getApiUrl(TR_TYPE.HTTP, '/users'), user);
    if (result.status !== 201) {
      this.$notify.error({
        title: 'Ошибка!',
        message: '',
      });
    } else {
      await this.reloadData();
      this.queryParams.update({
        id: result.data.id.toString(),
        right: 'base-info',
        mode: undefined,
      });
    }
  }

  async loadRoles() {
    const roles = await rest.get<Role<UserRole>[]>(getApiUrl(TR_TYPE.HTTP, '/roles'));

    if (!this.queryParams.get().mode && this.queryParams.get().left === 'roles') {
      if (roles.data[0]) {
        if (!this.queryParams.get().id) {
          this.queryParams.update({ id: roles.data[0]?.id.toString(), mode: undefined });
        } else {
          const id = Number(this.queryParams.get().id);
          if (roles.data.length && !roles.data.find((x) => x.id === id)) {
            this.queryParams.update({ id: roles.data[0]?.id.toString(), mode: undefined });
          }
        }
      }
    }

    return roles.data;
  }

  async loadUsers() {
    const users = await rest.get<User<UserRole>[]>(getApiUrl(TR_TYPE.HTTP, '/users'));

    if (!this.queryParams.get().mode && this.queryParams.get().left === 'users') {
      if (users.data[0]) {
        if (!this.queryParams.get().id) {
          this.queryParams.update({ id: users.data[0]?.id.toString(), mode: undefined });
        } else {
          const id = Number(this.queryParams.get().id);
          if (users.data.length && !users.data.find((x) => x.id === id)) {
            this.queryParams.update({ id: users.data[0]?.id.toString(), mode: undefined });
          }
        }
      }
    }

    return users.data;
  }

  async reloadData() {
    const [users, roles] = await Promise.all([this.loadUsers(), this.loadRoles()]);
    this.users = users;
    this.roles = roles;
  }

  private async checkGuards(to: any, from: any) {
    const guards = Object.entries(this.$refs as any)
      .map(([key, val]: any) => val?.guard)
      .filter((x: any) => !!x);

    const results = await Promise.all(guards.map(async (x) => await x(to, from)));

    return !results.some((x) => !!x);
  }

  async beforeRouteLeave(to: any, from: any, next: any) {
    const check = await this.checkGuards(to, from);

    if (check) {
      next();
    }
  }

  async beforeRouteUpdate(to: any, from: any, next: any) {
    if (Object.entries(to.query).length === 0) {
      return;
    }

    const check = await this.checkGuards(to, from);

    if (check) {
      next();
    }
  }

  getOrganizations(organizations: any[]) {
    const moskowPMES = '770.784.794.';
    const filtered = organizations.filter((x) => x.mpath.includes(moskowPMES));

    const map: any = {};
    const roots: any[] = [];

    filtered.forEach((x, i) => {
      map[x.id] = i;
      x.children = [];
    });

    filtered.forEach((x, i) => {
      if (x.parent_id !== null && map[x.parent_id] !== undefined) {
        filtered[map[x.parent_id]].children.push(x);
      } else {
        roots.push(x);
      }
    });

    const lst: any[] = [];

    const rec = (items: any[], level: number) => {
      items.forEach((x) => {
        x.level = level;
        lst.push(x);
        rec(x.children, level + 1);
      });
    };

    rec(roots, 0);

    return lst;
  }

  created() {
    this.queryParams = new QueryParamsService(() => ({
      left: this.$route.query.left,
      right: this.$route.query.right,
      id: this.$route.query.id,
      mode: this.$route.query.mode,
    }));
  }

  async mounted() {
    if (!this.queryParams.get().left) {
      this.queryParams.update({ left: 'roles', right: 'permissions', mode: undefined });
    }

    try {
      const [roles, users, deviceTypes, organizations] = await Promise.all([
        this.loadRoles(),
        this.loadUsers(),
        rest.get(getApiUrl(TR_TYPE.HTTP, '/device-types')),
        rest.get(getApiUrl(TR_TYPE.HTTP, '/organizations')),
      ]);

      this.roles = roles;
      this.users = users;
      this.deviceTypes = deviceTypes.data
        .filter((i: any) => i.id <= 9)
        .sort((a: any, b: any) => a.id - b.id);
      this.organizations = this.getOrganizations(organizations.data);
    } catch (e) {
      void 0;
    }
  }
}
