
















import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { EQUIP_STORE_CONST } from '@/store/equipment/constants';
import { ALGORITHM_DATA_CONST } from '@/store/abstract/algorithm-data/constants';
import { ALGORITHM_CONST } from '@/store/algorithms';
import moment from 'moment';
import {
  BASIC_STATE,
  QUALITY_0,
  QUALITY_1,
  STATE_0,
  STATE_0_60,
  STATE_100,
  STATE_60_100,
} from '@/components/summary-state/constants';
import { tableFilters } from '@/components/basics/table/filters';
@Component({
  filters: {
    ...tableFilters
  },
})
export default class SummaryState extends Vue {
  undef = 'не определено';
  @Getter(ALGORITHM_DATA_CONST.DATA, { namespace: ALGORITHM_CONST.ALGORITHMS })
  $pageData?: any;

  @Getter(EQUIP_STORE_CONST.SELECTED_TREE_NODE, { namespace: EQUIP_STORE_CONST.TREE })
  selectedNode: any;
  @Getter(ALGORITHM_DATA_CONST.LAST_UPDATE, { namespace: ALGORITHM_CONST.ALGORITHMS })
  lastUpdate?: Date | string | null;
  get state() {
    return !this.selectedNode?.state?.state ? this.undef : `${this.selectedNode?.state?.state}%`;
  }
  get quality() {
    return this.selectedNode?.state?.quality ?? this.undef;
  }

  get last() {
    if (!this.lastUpdate) {
      if (!this.$pageData?.selectedPage?.length) return this.undef;
      const lt = this.$pageData?.selectedPage.map((i: any) => i.data_time);
      if (!Array.isArray(lt) || !lt?.length) return this.undef;
      const max = Math.max(...lt);
      return moment(new Date(max / 1_000_000)).format('DD/MM/YY HH:mm:ss');
    }
    return moment(this.lastUpdate as Date).format('DD/MM/YY HH:mm:ss');
  }

  get getStateStyle() {
    let style = BASIC_STATE;
    if (!this.selectedNode?.state?.state) return BASIC_STATE;
    const { state } = this.selectedNode?.state;
    if (state === null || state === undefined) return null;
    if (Number(state) === 0) {
      style += ` ${STATE_0}`;
    } else if (Number(state) <= 60) {
      style += ` ${STATE_0_60}`;
    } else if (Number(state) < 100) {
      style += ` ${STATE_60_100}`;
    } else if (Number(state) >= 100) {
      style += ` ${STATE_100}`;
    }
    return style;
  }

  get getQualityStyle() {
    if (!this.selectedNode?.state?.quality) return BASIC_STATE;
    const { quality } = this.selectedNode?.state;
    const s = quality <= 0 ? QUALITY_0 : QUALITY_1;
    return `${BASIC_STATE} ${s}`;
  }
}
