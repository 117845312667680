



































import { Component, Prop, Watch, Ref } from 'vue-property-decorator';
import Vue from 'vue';
import { TableCol } from '@/components/basics/table/basic-table.types';
import { buildProp } from '@/components/items-tab/panes/algorithms/algorithm.convertors';
import TableSorter from '@/components/basics/table/TableSorter.vue';
import TablePagination from '@/components/basics/table/TablePagination.vue';
import TableFilter from '@/components/basics/table/TableFilter.vue';
import { FiltersState, FiltersMap } from '@/components/basics/table/filter.types';
import { getColumnFilter } from '@/components/basics/table/utils';

import { Enum, Reference } from './types';

@Component({
  components: {
    'table-sorter': TableSorter,
    'table-filter': TableFilter,
    'table-pagination': TablePagination,
  },
})
export default class SensorJournalTable extends Vue {
  @Ref('table') table: any;

  @Prop() total?: number;
  @Prop() columns?: TableCol[];
  @Prop() page?: any[];
  @Prop() enums!: Enum[] | null;
  @Prop() scheme: any;
  @Prop() query: any;
  @Prop() references!: Reference[] | null;

  selectedItem: any = {};
  buildProp = buildProp;

  filtersMap: FiltersMap = {};
  filtersState: FiltersState = {
    filterMin: {},
    filterMax: {},
    filterOptions: [],
    filterQueryOptions: [],
    filterGuidOptions: [],
    numberFilterOptions: [],
    stringFilterOptions: [],
  };

  get refsWithColumnsAndQuery() {
    return (
      this.enums &&
      this.columns && { refs: this.enums, columns: this.columns, query: this.$route.query.filter }
    );
  }

  get minWidths() {
    return (this.columns || []).map((x, i): number =>
      Math.max(
        x.width || 0,
        ...(this.page || []).map((row) => (row[x.property || '']?.length || 0) * 10 + 20)
      )
    );
  }

  @Watch('refsWithColumnsAndQuery', { immediate: true }) onRefsColumnsChange(val: any) {
    if (val) {
      const queryFilters =
        val.query &&
        Object.fromEntries(JSON.parse(decodeURIComponent(val.query)).map((x: any) => [x.name, x]));

      val.columns.forEach((column: any) => {
        if (column.Mandatory && !Array.isArray(column.dbProps)) {
          const currentFilter = queryFilters && queryFilters[column.dbProps];
          const oldFilter: any = this.filtersMap[column.dbProps];

          Vue.set(
            this.filtersMap,
            column.dbProps,
            getColumnFilter(val, column, oldFilter, currentFilter)
          );
        }
      });
    }
  }
}
