



























































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import { Action, Getter, Mutation, namespace } from 'vuex-class';
import * as scroll from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

import { REG_INFO_CONST } from '@/store/reg-info/reg-info.const';
import { RegInfoQuery } from '@/store/reg-info/reg-info.types';

import { IEquipDetails } from '@/store/details/types';
import { DETAILS_CONST } from '@/store/details';
import { CONST } from '@/store/auth/constants';
import { User } from '@/store/auth/types';
import EmptyData from '@/components/basics/EmptyData.vue';
import { ITabRef } from '@/store/dictionaries/dictionaries.types';
import { DICTIONARIES_CONST } from '@/store/dictionaries/dictionaries.const';
import { SAVE_CHANGED_VALUES } from '@/store/abstract/config-updater/constants';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { PRELOADER_STATE } from '@/store/abstract/preloader';
import { IPreloader } from '@/store/abstract/preloader/types';
import IconBtn from '@/components/basics/buttons/icon-btn.vue';
import {
  ADD_DIRTY_ITEM,
  IS_DIRTY,
  REMOVE_DIRTY_ITEM,
  RESET_DIRTY,
  SET_CLEAR_ITEMS,
  UPDATE_DIRTY,
} from '@/store/abstract/dirty/constants';
import ConfirmChanges from '@/components/confirm-changes/index.vue';
import {
  CANCEL_MSG,
  CHOOSE_ACTION_MSG,
  EDIT_MSG,
  HAS_UNSAVED_CHANGES_MSG,
  NO_SAVE_MSG,
  RESET_MSG,
  SAVE_MSG,
  WARNING_MSG,
} from '@/components/confirm-changes/constants';
import { DirtyEnum, DirtyTypes } from '@/store/abstract/dirty/types';
import {
  findAssetUid,
  getDelta,
  getEnumOrder,
  getTabItems,
  resetModel,
} from '@/components/reg-info/extensions';
import moment from 'moment';

const { REG_INFO_TABS, REG_INFO, GET_REG_INFO_TABS, CLEAR_REG_INFO_TABS } = REG_INFO_CONST;
const { EQUIPMENT_DETAILS, DETAILS } = DETAILS_CONST;
const { DICTIONARIES, TAB_REFS } = DICTIONARIES_CONST;
const _key = DirtyEnum;

Vue.use(scroll.default);
@Component({
  components: {
    'empty-data': EmptyData,
    'icon-btn': IconBtn,
    'confirm-changes': ConfirmChanges,
  },
  filters: {
    transform: (val: any, row: any) => {
      const { type } = row;
      if (type === 'DateTime') return moment(new Date(new Date(val).getTime() - (new Date().getTimezoneOffset() * 60000)).toString()).format("MM/DD/YYYY hh:mm:ss A");
      return val;
    },
  },
})


export default class RegInfoView extends Vue {
  BTN_SAVE = 'Сохранить изменения';
  BTN_LOADING = 'Загрузка...';
  BTN_CANCEL = 'Отмена';

  @Prop() visible!: boolean;

  isEdit = false;
  nsiEditModel: Record<string, any> = {};

  @Getter(PRELOADER_STATE, { namespace: REG_INFO })
  preloader?: IPreloader;

  @Action(GET_REG_INFO_TABS, { namespace: REG_INFO })
  getRegInfoTabs!: (query: RegInfoQuery) => void;

  @Mutation(CLEAR_REG_INFO_TABS, { namespace: REG_INFO })
  clearInfoTabs!: () => void;

  @Getter(EQUIPMENT_DETAILS, { namespace: DETAILS })
  details!: IEquipDetails;

  @Getter(TAB_REFS, { namespace: DICTIONARIES })
  tabRefs!: ITabRef[];

  @Getter(REG_INFO_TABS, { namespace: REG_INFO })
  regInfoTabs!: any[];

  @Getter(CONST.GET_USER, { namespace: CONST.AUTH })
  user!: User | null;

  showConfirm = false;
  confirmMessage = HAS_UNSAVED_CHANGES_MSG + ' ' + CHOOSE_ACTION_MSG;
  confirmTitle = WARNING_MSG;
  actionText = SAVE_MSG;
  cancelText = NO_SAVE_MSG;

  // dialog feedback
  async handleConfirmAction(saveFlag: boolean) {
    this.showConfirm = false;
    if (saveFlag) {
      // save changes
      await this.onSaveHandle();
    }
    this.isEdit = false;
    this.resetNsiModel(this.tabs);
    // reset changes
    this.resetDirty();
  }

  // ESC key pressed
  handleClosed() {
    if (this.hasDirtyItems) {
      // call dialog no_save / save
      this.showConfirm = true;
    } else {
      // exit
      // reset changes
      this.resetDirty();
      this.resetNsiModel(this.tabs);
      this.isEdit = false;
    }
  }

  get isEditDialogShown(): boolean {
    return this.visible;
  }

  get isEmpty(): boolean {
    return this.tabs?.length <= 0;
  }

  delta(rec: any): number {
    return getDelta(rec);
  }

  get isEditable() {
    const rights = (this.user?.roles ?? []).map((x) => x.role.rights).reduce((x, y) => x | y, 0);
    return rights & 0b1000000;
  }

  get tabs() {
    if (this.regInfoTabs) {
      return this.regInfoTabs?.map((tab) => ({
        ...tab,
        items: getTabItems(tab, this.tabRefs),
      }));
    } else {
      return [];
    }
  }

  get phase(): number {
    if (!this.details?.statesAndPhases?.length) return -1;
    const { phase } = this.$route.query;
    const { statesAndPhases } = this.details;
    let phaseObject = statesAndPhases?.find((x) => x.phaseLabel === phase);
    if(!phaseObject && statesAndPhases?.length)
      phaseObject = statesAndPhases[0];
    return phaseObject?.phaseValue ?? -1;
  }

  get regInfoQuery() {
    return this.details && this.phase !== -1
      ? { equipId: this.details.equipId, phase: this.phase }
      : null;
  }

  @Watch('regInfoQuery', { immediate: true }) onDetailsChanged(val: any, old: any) {
    // console.log(val, !val || (old && val.equipId === old.equipId && val.phase === old.phase));
    const check = !val || (old && val.equipId === old.equipId && val.phase === old.phase);
    if (check || check === undefined) return;
    this.getRegInfoTabs({ equipId: val.equipId, phase: val.phase });
  }

  @Watch('tabs', { immediate: true }) onTabsChanged(val: any, prev: any) {
    if (val && val !== prev) {
      this.resetNsiModel(val);
    }
  }

 

  async onDateTimeChange([cur, prev]: [string?, string?], key: string) {
    if (!cur) {
      this.nsiEditModel[key].value = moment(prev).format("MM/DD/YYYY hh:mm:ss A");
      return;
    }
    cur = new Date(new Date(cur).getTime() + (new Date().getTimezoneOffset() * 60000)).toString();
    cur = moment(cur).format("MM/DD/YYYY hh:mm:ss A")
    await this.refreshDirty(this.tabs, key, cur);
  }

  closeDialog() {
    this.$emit('close-dialog');
  }

  spanMethod(data: any): any {
    if ((data.row.type === 'NormTable' || data.row.type === 'ObjectTable') && data.columnIndex) {
      return [1, 2];
    }
    return [1, 1];
  }

  async handleTableNumberChange(
    [cur, prev]: [number?, number?],
    key: string,
    row: number,
    field: string
  ) {
    if (cur === undefined) {
      setTimeout(() => {
        this.nsiEditModel[key].value[row][field] = prev;
        this.resetNsiModel(this.tabs);
      }, 0);
      return;
    }

    const nsiValue = this.nsiEditModel[key].value;
    const newValue = nsiValue.map((x: any, i: number) => (i === row ? { ...x, [field]: cur } : x));

    await this.refreshDirty(this.tabs, key, newValue);
  }

   hasBoundaries(key: string) {
    const item = this.nsiEditModel[key];

    return !isNaN(item.min) && !isNaN(item.max);
  }

  async handleNumberChange([cur, prev]: [number?, number?], key: string) {
    if (cur === undefined) {
      this.nsiEditModel[key].value = prev?.toString();
    }
    const v = this.nsiEditModel[key];
    if (this.hasBoundaries(key))
    {  
       if (v?.value && v.max && v.min) {
        if (v.value > v.max || v.value < v.min) {
          this.nsiEditModel[key].value = prev;
          this.resetNsiModel(this.tabs);
        }
      }
    }
    if (!cur) return;
    // dirty service
    await this.refreshDirty(this.tabs, key, cur);
  }

  async handleSelectChanged([cur, prev]: [string?, string?], key: string) {
    if (cur === undefined) {
      this.nsiEditModel[key].value = prev?.toString();
    }
    if (!cur) return;
    // dirty service
    const enumOrder = await getEnumOrder(this.nsiEditModel, key, cur);
    if (!enumOrder) return;
    await this.refreshDirty(this.tabs, key, enumOrder);
  }

  async refreshDirty(tabs: any[], uid: string, value: string | number) {
    const assetUid = await findAssetUid(this.tabs, uid);
    if (!assetUid) return;
    this.updateDirty({
      [_key.AssetUid]: assetUid,
      [_key.Uid]: uid,
      [_key.Value]: value,
    });
  }

  resetNsiModel(val: any) {
    if (!this.tabs?.length) return;
    this.nsiEditModel = resetModel(val, this.tabRefs);
    this.setClearValues(
      Object.fromEntries(Object.entries(this.nsiEditModel).map(([key, value]) => [key, value]))
    );
  }

  startEdit() {
    this.isEdit = true;
  }

  // TODO hardcode on back-end + boris
  configName = 'nsi';

  @Action(UPDATE_DIRTY, { namespace: REG_INFO })
  updateDirty!: (payload: Record<DirtyTypes, string | number | null>) => void;
  @Action(SET_CLEAR_ITEMS, { namespace: REG_INFO })
  setClearValues!: (values: Record<string, any>) => void;
  @Getter(IS_DIRTY, { namespace: REG_INFO })
  isDirty!: boolean;
  get hasDirtyItems(): boolean {
    return this.isDirty;
  }

  @Action(SAVE_CHANGED_VALUES, { namespace: REG_INFO })
  saveNsiConfigChanges!: ({
    url,
    phase,
    configName,
  }: {
    url: string;
    phase: number;
    configName: string;
  }) => Promise<void>;
  url = getApiUrl(TR_TYPE.HTTP, '/reg-info/update');

  async onSaveHandle() {
    await this.saveNsiConfigChanges({
      url: this.url,
      phase: this.phase,
      configName: this.configName,
    });
    this.isEdit = false;
  }

  @Action(RESET_DIRTY, { namespace: REG_INFO })
  resetDirty!: () => void;

  cancelEdit() {
    this.isEdit = false;
    this.resetNsiModel(this.tabs);
    // reset dirty collection
  }

  mounted() {
    this.resetDirty();
  }

  beforeDestroy() {
    this.clearInfoTabs();
  }

  async beforeRouteLeave(to: any, from: any, next: any) {
    void 0;
  }

  async beforeRouteUpdate(to: any, from: any, next: any) {
    void 0;
  }
}
