









import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import FileDownloader from '@/components/files/file-downloader.vue';
import { IEquip } from '@/store/equipment/types';

@Component({
  components: {
    'file-downloader': FileDownloader,
  },
})
export default class Level0TopicCard2 extends Vue {
  @Prop() item!: IEquip | null;
}
