var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.sensorModel && _vm.references)?_c('div',{staticClass:"sensor"},[_c('div',{staticClass:"header"},[_vm._v(" "+_vm._s(_vm.currentSensors && _vm.currentSensors.length > 0 && _vm.currentSensors[0].protocol.ied)+" "),_c('div',{staticClass:"sensor-state-status",class:[_vm.currentState ? _vm.currentState.status : 'undefined'],staticStyle:{"margin-left":"24px"}}),(_vm.currentState)?_c('div',{staticClass:"sensor-status-time"},[_vm._v(" ("+_vm._s(_vm.currentState.time.format('DD.MM.YYYY HH:mm.ss.S'))+") ")]):_vm._e()]),(_vm.protocol === 'IEC_61850_9_2')?_c('div',[_c('el-tabs',{attrs:{"value":_vm.activeTab},on:{"tab-click":_vm.onChangeTab}},[_c('el-tab-pane',{key:"network",attrs:{"label":"Локальная сеть","name":"network"}},[(_vm.activeTab === 'network')?_c('protocol-5092-local-network',{attrs:{"sensorModel":_vm.sensorModel,"editPermission":_vm.editable},on:{"save-sensor":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.onSaveSensor.apply(void 0, args);
},"cancel-edit":_vm.onCancelLocalNetworkEdit}}):_vm._e()],1),_c('el-tab-pane',{key:"sources",attrs:{"label":"Источники","name":"sources"}},[(_vm.activeTab === 'sources')?_c('protocol-5092-sources',{attrs:{"sensorModel":_vm.sensorModel,"editPermission":_vm.editable,"assets":_vm.assets},on:{"save-sensor":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.onSaveSensorAndAssets.apply(void 0, args);
}}}):_vm._e()],1),_c('el-tab-pane',{key:"journal",attrs:{"label":"Журнал работы","name":"journal"}},[(_vm.activeTab === 'journal')?_c('sensor-journal-view',{attrs:{"enums":_vm.enums,"references":_vm.references,"protocol":_vm.protocol,"substation":_vm.substation,"currentSensors":_vm.currentSensors}}):_vm._e()],1)],1)],1):_vm._e(),(_vm.protocol === 'IEC_61850_8_1')?_c('div',[_c('el-tabs',{attrs:{"value":_vm.activeTab},on:{"tab-click":_vm.onChangeTab}},[_c('el-tab-pane',{key:"network",attrs:{"label":"Локальная сеть","name":"network"}},[(_vm.activeTab === 'network')?_c('protocol-5081-local-network',{attrs:{"sensorModel":_vm.sensorModel,"editPermission":_vm.editable},on:{"save-sensor":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.onSaveSensor.apply(void 0, args);
},"cancel-edit":_vm.onCancelLocalNetworkEdit}}):_vm._e()],1),_c('el-tab-pane',{key:"subscription",attrs:{"label":"Подписка на отчеты","name":"subscription"}},[(_vm.activeTab === 'subscription')?_c('protocol-5081-subscriptions',{attrs:{"sensorModel":_vm.sensorModel,"assets":_vm.assets,"editPermission":_vm.editable,"references":_vm.references},on:{"save-sensor":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.onSaveSensor.apply(void 0, args);
}}}):_vm._e()],1),_c('el-tab-pane',{key:"file",attrs:{"label":"Файл","name":"file"}},[(_vm.activeTab === 'file')?_c('protocol-5081-file',{attrs:{"sensorModel":_vm.sensorModel,"editPermission":_vm.editable,"comTradeSensor":_vm.comTradeSensor,"references":_vm.references},on:{"save-sensor":_vm.onSaveSensor}}):_vm._e()],1),_c('el-tab-pane',{key:"preprocessing",attrs:{"label":"Предобработка","name":"preprocessing"}},[(_vm.activeTab === 'preprocessing')?_c('protocol-5081-preprocessing',{attrs:{"sensorModel":_vm.sensorModel,"assets":_vm.assets,"editPermission":_vm.editable,"references":_vm.references},on:{"save-sensor":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.onSaveSensor.apply(void 0, args);
}}}):_vm._e()],1),_c('el-tab-pane',{key:"journal",attrs:{"label":"Журнал работы","name":"journal"}},[(_vm.activeTab === 'journal')?_c('sensor-journal-view',{attrs:{"enums":_vm.enums,"references":_vm.references,"currentSensors":_vm.currentSensors,"protocol":_vm.protocol,"substation":_vm.substation}}):_vm._e()],1)],1)],1):_vm._e(),(_vm.protocol === 'IEC_60870_5_104')?_c('div',[_c('el-tabs',{attrs:{"value":_vm.activeTab},on:{"tab-click":_vm.onChangeTab}},[_c('el-tab-pane',{key:"network",attrs:{"label":"Локальная сеть","name":"network"}},[(_vm.activeTab === 'network')?_c('protocol-5104-local-network',{attrs:{"sensorModel":_vm.sensorModel,"editPermission":_vm.editable},on:{"save-sensor":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.onSaveSensor.apply(void 0, args);
},"cancel-edit":_vm.onCancelLocalNetworkEdit}}):_vm._e()],1),_c('el-tab-pane',{key:"sources",attrs:{"label":"Источники","name":"sources"}},[(_vm.activeTab === 'sources')?_c('protocol-5104-sources',{attrs:{"sensorModel":_vm.sensorModel,"editPermission":_vm.editable,"assets":_vm.assets},on:{"save-sensor":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.onSaveSensor.apply(void 0, args);
}}}):_vm._e()],1),_c('el-tab-pane',{key:"preprocessing",attrs:{"label":"Предобработка","name":"preprocessing"}},[(_vm.activeTab === 'preprocessing')?_c('protocol-5104-preprocessing',{attrs:{"sensorModel":_vm.sensorModel,"assets":_vm.assets,"editPermission":_vm.editable,"references":_vm.references},on:{"save-sensor":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.onSaveSensor.apply(void 0, args);
}}}):_vm._e()],1),_c('el-tab-pane',{key:"journal",attrs:{"label":"Журнал работы","name":"journal"}},[(_vm.activeTab === 'journal')?_c('sensor-journal-view',{attrs:{"enums":_vm.enums,"references":_vm.references,"currentSensors":_vm.currentSensors,"protocol":_vm.protocol,"substation":_vm.substation}}):_vm._e()],1)],1)],1):_vm._e(),(_vm.protocol === 'Modbus_TCP')?_c('div',[_c('el-tabs',{attrs:{"value":_vm.activeTab},on:{"tab-click":_vm.onChangeTab}},[_c('el-tab-pane',{key:"network",attrs:{"label":"Локальная сеть","name":"network"}},[(_vm.activeTab === 'network')?_c('protocol-modbus-local-network',{attrs:{"sensorModel":_vm.sensorModel,"editPermission":_vm.editable},on:{"save-sensor":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.onSaveSensor.apply(void 0, args);
},"cancel-edit":_vm.onCancelLocalNetworkEdit}}):_vm._e()],1),_c('el-tab-pane',{key:"sources",attrs:{"label":"Источники","name":"sources"}},[(_vm.activeTab === 'sources')?_c('protocol-modbus-sources',{attrs:{"sensorModel":_vm.sensorModel,"editPermission":_vm.editable,"assets":_vm.assets},on:{"save-sensor":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.onSaveSensor.apply(void 0, args);
}}}):_vm._e()],1),_c('el-tab-pane',{key:"preprocessing",attrs:{"label":"Предобработка","name":"preprocessing"}},[(_vm.activeTab === 'preprocessing')?_c('protocol-modbus-preprocessing',{attrs:{"sensorModel":_vm.sensorModel,"assets":_vm.assets,"editPermission":_vm.editable,"references":_vm.references},on:{"save-sensor":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.onSaveSensor.apply(void 0, args);
}}}):_vm._e()],1),_c('el-tab-pane',{key:"journal",attrs:{"label":"Журнал работы","name":"journal"}},[(_vm.activeTab === 'journal')?_c('sensor-journal-view',{attrs:{"enums":_vm.enums,"references":_vm.references,"currentSensors":_vm.currentSensors,"protocol":_vm.protocol,"substation":_vm.substation}}):_vm._e()],1)],1)],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }