import moment from 'moment';

import { MOMENT_FORMAT } from '@/utils/datetime/DateTimeRangePresets';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { HistoryDataManager } from '@/store/history-data/history-data.manager';
import { QueryBuilder } from '@/utils/QueryBuilder';
import { RestApiService } from '@/services';
import { splitChunks } from '@/store/helpers';
import { buildDBQuery } from '@/components/items-tab/panes/algorithms/algorithms.extensions';

import { baseQuery, getHeaders, getName, getValues2, timeFormat } from './file-load.ext';
import { IPropertyScheme } from '@/store/history-data/history-data.types';
import { IReportColumn, IReportTab } from '@/store/report-data/types';

const path1 = getApiUrl(TR_TYPE.HTTP, '/click-house/paginator/selectedPage?');
const path2 = getApiUrl(TR_TYPE.HTTP, '/click-house/history-data');
const path3 = getApiUrl(TR_TYPE.HTTP, '/click-house');

const rest = new RestApiService();

export const getStateData = async (payload: IReportTab, columns: IReportColumn[]) => {
  try {
    const { uuids } = payload;
    const table = columns[0].tableName;
    const query = QueryBuilder.build({ uuids: uuids?.join(';'), table });
    const resp = await rest.get(path3 + '/summary-state-data?' + query);
    return resp.data;
  } catch (e) {
    return e;
  }
};

export const getTableData = async (payload: any, scheme: any) => {
  const query = QueryBuilder.build({
    ...buildDBQuery(payload, scheme),
    limit: undefined,
    offset: undefined,
  });
  const response = await rest.get(path1 + query);
  return response && response.data && splitChunks(response.data);
};

export const getChartData = async (payload: any) => {
  const { phase, scheme } = payload;
  if (!phase || isNaN(phase) || !scheme?.length) return;
  const queries = scheme.map((item: IPropertyScheme) => {
    const { select, calculatorName, algorithm } = item;
    return item.calculatorName
      ? {
          ...baseQuery(payload),
          select,
          calculator_name: calculatorName,
          [`qd_${select}`]: 0,
          algorithm,
        }
      : {
          ...baseQuery(payload),
          select,
          [`qd_${select}`]: 0,
          algorithm,
        };
  });
  if (!queries?.length) return;
  const sch = scheme.filter((i: any) => i.order > 0);
  const fields = ['time', ...queries.flatMap((query: any) => getValues2(query, sch))];
  const headers = ['Время', ...queries.flatMap((query: any) => getHeaders(query, sch))];
  const data = await Promise.all(
    queries.map((query: any) => {
      const dataManager = new HistoryDataManager();
      const q = QueryBuilder.build(query);
      const itm = scheme.find((s: IPropertyScheme) => s?.select === query?.select);

      return dataManager.add(
        getValues2(query, scheme),
        `${path2}?${q}`,
        query.interval ?? '',
        itm?.scale
      );
    })
  );

  const dataHash: { [key: string]: any } = {};
  data.flat().forEach((x: any) => {
    if (moment(x.time).isBefore(moment(payload.end))) {
      // clickhouse отсылает лишние миллисекунды, отбрасываем их здесь
      const key = moment(x.time).format(
        payload.interval === 'ms' ? MOMENT_FORMAT.dateToMs : MOMENT_FORMAT.dateToSec
      );

      dataHash[key] = { ...(dataHash[key] || {}), ...x };
    }
  });

  return {
    data: timeFormat(
      Object.values(dataHash).sort(
        (x, y) => new Date(x.time).getTime() - new Date(y.time).getTime()
      ),
      payload.interval
    ),
    fields: fields,
    headers: headers,
    name: getName('summary_charts', payload),
    interval: payload.interval,
  };
};
