












import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { DETAILS_CONST } from '@/store/details';
import { IEquipDetails } from '@/store/details/types';
import { Table, TableColumn } from 'element-ui';
@Component({
  components: {
    'el-table': Table,
    'el-table-column': TableColumn,
  },
})
export default class RegulatoryInf extends Vue {
  @Getter(DETAILS_CONST.EQUIPMENT_DETAILS, { namespace: DETAILS_CONST.DETAILS })
  equipmentDetails!: IEquipDetails;
}
