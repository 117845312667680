




































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { generateId } from '@/utils';

import { User, Role, UserRole } from './types';

@Component({})
export default class UsersView extends Vue {
  addDialogVisible = false;
  createUserModel = {
    login: '',
    name: '',
    mail: '',
    password: '',
  };
  createUserModelValidity: Record<string, boolean> = {
    login: false,
    name: false,
    mail: false,
    password: false,
  };

  rules = {
    login: [{ required: true, message: 'Введите логин.', trigger: 'change' }],
    name: [{ required: true, message: 'Введите имя.', trigger: 'change' }],
    mail: [{ required: true, message: 'Введите адрес электронной почты.', trigger: 'change' }],
    password: [{ required: true, message: 'Выберите пароль.', trigger: 'change' }],
  };

  @Prop() editable!: boolean;
  @Prop() users!: User<Role<UserRole>>[];
  @Prop() roles!: Role<User<UserRole>>[];
  @Prop() selectedUser!: User<Role<UserRole>> | null;

  get isCreateUserValid() {
    return !Object.entries(this.createUserModelValidity).some(([, value]) => !value);
  }

  onValidate(field: string, validity: boolean) {
    this.createUserModelValidity[field] = validity;
  }

  onUserClick(role: User<Role<UserRole>>) {
    this.$emit('select-user', role);
  }

  openAddUserDialog() {
    this.createUserModel = {
      login: '',
      name: '',
      mail: '',
      password: '',
    };

    this.createUserModelValidity = {
      login: false,
      name: false,
      mail: false,
      password: false,
    };

    this.addDialogVisible = true;

    setTimeout(() => {
      (this.$refs['createUserModel'] as any).clearValidate();
    }, 0);
  }

  closeAddUserDialog() {
    this.addDialogVisible = false;
  }

  addUser() {
    this.closeAddUserDialog();
    this.$emit('add-user', { ...this.createUserModel, uid: generateId() });
  }
}
