




























import { Component, Watch, Prop } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import Vue from 'vue';
import moment from 'moment';

import { ALGORITHM_DATA_CONST } from '@/store/abstract/algorithm-data/constants';
import { IAlgorithmQuery } from '@/store/algorithms/algorithms.types';
import { TableCol } from '@/components/basics/table/basic-table.types';
import { transformData } from '@/components/items-tab/panes/algorithms/algorithms.extensions';
import DateTimeSelector from '@/components/basics/selectors/DateTimeSelector.vue';
import { ALGORITHM_CONST } from '@/store/algorithms';
import { PRELOADER_STATE } from '@/store/abstract/preloader';
import { IPreloader } from '@/store/abstract/preloader/types';

import EventJournalTable from './EventJournalTable.vue';
import { Enum, Reference, EquipmentWithAssetId, Module } from './types';
import { UPDATE_DATETIME_RANGE } from '@/store/abstract/range-getter/constants';
import { IDatetimeRange } from '@/store/abstract/range-getter/types';
import { HISTORY_CHARTS } from '@/store/history-charts/constants';
import FileDownloader from '../files/file-downloader.vue';
import BasicTable from '../basics/table/BasicTable.vue';
import { propertyToLowerCase } from '@/utils/string';
import ExportDataView from '../sensors/ExportDataView.vue';
import { MOMENT_FORMAT } from '@/utils/datetime/DateTimeRangePresets';
import { RestApiService } from '@/services';
import { getApiUrl, TR_TYPE } from '@/store/tools';
import { QueryBuilder } from '@/utils/QueryBuilder';
import { splitChunks } from '@/store/helpers';
import { removeSubject, addSubject } from '@/socket';
import { table } from 'console';
import { ElRow } from 'element-ui/types/row';
import { Subject, Subscription } from 'rxjs';
import { sampleTime } from 'rxjs/operators';

const path = getApiUrl(TR_TYPE.HTTP, '/click-house/paginator/selectedPage?');

const rest = new RestApiService();

@Component({
  components: {
    'event-journal-table': EventJournalTable,
    'datetime-selector': DateTimeSelector,
    'file-downloader': FileDownloader,
    'basic-table': BasicTable,
    'export-data': ExportDataView,
  },
})
export default class EventJournalTableView extends Vue {
  @Prop() enums!: Enum[] | null;
  @Prop() references!: Reference[] | null;
  @Prop() equipments!: EquipmentWithAssetId[] | null;
  // @Prop() modules!: Module[] | null;
  @Prop() table!: string;

  isState = true;
  stateTab = {
    uuids: [],
    name: 'Журнал событий'
  };

  subject = 'equipment_change_state';
  updateSubject = new Subject();
  updateSubjectSubscription: Subscription | null = null;

  @Getter(ALGORITHM_DATA_CONST.DATA, { namespace: 'algorithms' })
  $pageData?: any;

  @Getter(PRELOADER_STATE, { namespace: ALGORITHM_CONST.ALGORITHMS })
  preloader?: IPreloader;

  @Action(ALGORITHM_DATA_CONST.LOAD_DATA, { namespace: 'algorithms' })
  $getData!: (payload: IAlgorithmQuery) => void;

  @Mutation(ALGORITHM_DATA_CONST.DATA_LOADED, { namespace: 'algorithms' })
  $setData!: (x: any) => void;

  @Action(UPDATE_DATETIME_RANGE, { namespace: HISTORY_CHARTS })
  updateDatetimeRange!: (payload: IDatetimeRange) => void;

  @Watch('query', { immediate: true }) onQueryChange(val: IAlgorithmQuery | null) {
    if (val && val.start) {
      const { limit, offset } = val;
      if (!limit || !offset) return;
      this.$getData(val);
    }
  }

  get preloaderState(): boolean {
    return (
      (!this.references ||
        !this.enums ||
        !this.equipments ||
        // !this.modules ||
        this.preloader?.isBusy) ??
      false
    );
  }
  get preloaderMessage(): string {
    return this.preloader?.message ?? '';
  }

  get query(): IAlgorithmQuery | null {
    return {
      ...this.$route.query,
      algorithm: this.table,
    };
  }

  get journalReference() {
    return this.references?.find((x) => x.name === this.table);
  }

  get scheme(): any {
    return {
      dbTableName: this.table,
      columns:
        this.journalReference?.props.map((x: any) => ({
          ...propertyToLowerCase(x, false),
          dbProps: x.Name,
          order: x.Order,
          property: x.Name,
          key: x.Name
        })) ??
        [],
    };
  }

  get exportConfig() {
    const { start, end } = this.query as any;

    const s = moment(start).format(MOMENT_FORMAT.secAlter);
    const e = moment(end).format(MOMENT_FORMAT.secAlter);

    return {
      fileName: `${this.stateTab.name}_${s}_${e}`,
      columns: this.columns.map((x) => ({
        prop: x.dbProps,
        name: x.notes,
      })),
    };
  }

  get columns(): TableCol[] {
    return (
      this.journalReference?.props
        .filter((x: any) => x.Mandatory && x.Order > 0)
        .sort((a: any, b: any) => a.Order > b.Order ? 1 : -1)
        .map((x: any) => ({
          ...propertyToLowerCase(x, false),
          header: x.Notes,
          dbProps: x.Name,
          property: x.Name,
          width: x.Notes.length * 11 + 50,
          tableName: 'equipment_change_state',
          formatter:
            x.Type === 'DateTime'
              ? (value: string) =>  moment(value).format('HH:mm:ss.S DD-MM-YYYY')
              : undefined,
        })) ?? []
    );
  }

  get guidTablesHash() {
    return (
      this.equipments && {
        substation_uuid: { items: this.equipments, property: 'name' },
        equipment_uuid: { items: this.equipments, property: 'name' },
        // calculator_uuid: { items: this.modules, property: 'name' },
      }
    );
  }

  get total() {
    return this.$pageData?.count || 0;
  }

  get page() {
    if (this.$pageData?.selectedPage?.length <= 0 || !this.guidTablesHash) {
      return [];
    } else {
      const p = transformData(
        this.$pageData.selectedPage,
        this.scheme,
        this.enums?.map((x) => ({ ...x, enumUid: x.enum_uid, order: Number(x.order) })),
        this.guidTablesHash
      );

      return p ?? [];
    }
  }

  async getFullTableData() {
    const query = {
      ...this.query,
      limit: undefined,
      offset: undefined,
    };

    const response = await rest.get(path + QueryBuilder.build(query));

    return (
      (response &&
        response.data &&
        transformData(
          splitChunks(response.data),
          this.scheme,
          this.enums?.map((x) => ({ ...x, order: Number(x.order), enumUid: x.enum_uid }))
        )) ||
      []
    );
  }

  reQuery() {
    this.query && this.$getData({ ...this.query, noSpinner: true });
  }

  unsubscribe() {
    this.$socket.client.off(this.subject);
    removeSubject(this.subject);
    this.updateSubjectSubscription?.unsubscribe();
  }

  subscribe() {
    addSubject({
      theme: this.subject,
      keys: [],
    });

    this.$socket.client.on(this.subject, (x: any) => {
      this.updateSubject.next(x);
    });

    this.updateSubjectSubscription = this.updateSubject
      .pipe(sampleTime(1000))
      .subscribe(() => this.reQuery());

    this.updateSubject.next();
  }

  async mounted() {
    this.subscribe();
  }

  beforeDestroy() {
    this.$setData({ count: 0, data: [] });
    this.unsubscribe();
  }

}
