export function protocolTranslation(protocol: string) {
  const protocols: any = {
    ['IEC_61850_8_1']: 'МЭК 61850-8-1 (клиент)',
    ['IEC_61850_9_2']: 'МЭК 61850-9-2',
    ['IEC_60870_5_104']: 'МЭК 60870-5-104 (клиент)',
    ['Modbus_TCP']: 'Modbus',
    ['NTP']: 'Синхронизация времени',
  };
  return protocols[protocol] || protocol;
}

export const calculatePhases = (flags: string) => {
  const phases: [string, number][] = [
    ['A', 32],
    ['B', 64],
    ['C', 128],
  ];

  return phases
    .filter(([name, num]) => Number(flags) & num)
    .map(([name, num]) => name)
    .join('');
};

function merge(left: any[], right: any[], comparator: (x: any, y: any) => boolean) {
  const result = [];

  while (left.length && right.length) {
    if (comparator(left[0], right[0])) {
      result.push(left.shift());
    } else {
      result.push(right.shift());
    }
  }

  while (left.length) result.push(left.shift());
  while (right.length) result.push(right.shift());

  return result;
}

export function mergeSort(arr: any[], comparator: (x: any, y: any) => boolean): any[] {
  if (arr.length < 2) return arr;

  const middle = arr.length / 2;
  const left = arr.slice(0, middle);
  const right = arr.slice(middle, arr.length);

  return merge(mergeSort(left, comparator), mergeSort(right, comparator), comparator);
}

export function numberSorter(x: any, y: any) {
  return (
    typeof x === 'string' &&
    typeof y === 'string' &&
    Number(x.replace(',', '.')) < Number(y.replace(',', '.'))
  );
}

export const getEditableModel = (data: any[]): Record<string, {}> => {
  return Object.fromEntries([...data.map((i) => [i.uuid, { ...i, isDirty: false }])]);
};

export const getModelsDiff = (
  oldModel: Record<string, any>,
  newModel: Record<string, any>
): any[] => {
  return Object.entries(newModel)
    .filter(([key, value]) => {
      const newEntries = Object.entries(value);
      return newEntries.some(([eKey, eValue]) => oldModel[key][eKey] !== eValue);
    })
    .map(([key, value]) => value);
};

export const getDeviation = (x: any) => {
  return !x || x.unit === 'bool' || x.unit === 'dbs' || x.unit === 'pos' || x.unit === 'dpi'
    ? ''
    : Number(x.deviation).toLocaleString();
};

export const getOffset = (x: any) => {
  return !x || x.unit === 'bool' || x.unit === 'dbs' || x.unit === 'pos' || x.unit === 'dpi'
    ? ''
    : Number(x.offset).toLocaleString();
};

const getSorter = (sort: any) => {
  const configSorter = (x: any, y: any) => x < y;

  return configSorter
    ? (x: any, y: any) =>
        sort.order === 'ascending'
          ? configSorter(x[sort.prop], y[sort.prop])
          : !configSorter(x[sort.prop], y[sort.prop])
    : (x: any, y: any) =>
        sort.order === 'ascending' ? x[sort.prop] < y[sort.prop] : x[sort.prop] > y[sort.prop];
};

export const getPage = (data: any[], sort: any, config: any) => {
  if (data.length <= 10) {
    return data;
  } else {
    const { limit, offset } = config;

    const sorted =
      sort.order === null
        ? data
        : mergeSort(
            mergeSort([...data], (x: any, y: any) => x.name > y.name),
            getSorter(sort)
          );

    return sorted.slice(Number(offset), Number(offset) + Number(limit));
  }
};
