












































































































































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import ConfirmChanges from '@/components/confirm-changes/index.vue';
import TablePagination from '@/components/basics/table/TablePagination.vue';
import TableFilter from '@/components/basics/table/TableFilter.vue';
import TableSorter from '@/components/basics/table/TableSorter.vue';
import { getColumnFilter } from '@/components/basics/table/utils';
import ExportDataView from '../ExportDataView.vue';

import { Sensor, Reference } from '../types';
import { calculatePhases, getModelsDiff, getEditableModel, getDeviation, getPage } from '../utils';

@Component({
  components: {
    'confirm-changes': ConfirmChanges,
    'table-pagination': TablePagination,
    'table-filter': TableFilter,
    'table-sorter': TableSorter,
    'export-data': ExportDataView,
  },
})
export default class Protocol5104Preprocessing extends Vue {
  @Prop() sensorModel!: Sensor | null;
  @Prop() references!: Reference[] | null;
  @Prop() editPermission!: boolean;

  showConfirm = false;
  isEdit = false;
  isSaving = false;
  editable: Record<string, {}> = {};

  sort: { order: string | null; prop: string } = { prop: 'name', order: 'ascending' };

  filtersMap: any = {};
  filtersState = {
    filterMin: {},
    filterMax: {},
    filterOptions: [],
    filterQueryOptions: [],
    numberFilterOptions: [],
    stringFilterOptions: [],
    filterGuidOptions: [],
  };

  validators = {
    ['scale']: (row: any) => !isNaN(Number(row.scale)),
    ['offset']: (row: any) => !isNaN(Number(row.offset)),
    ['deviation']: (row: any) => !isNaN(Number(row.deviation)),
    ['substValue']: (row: any) => !isNaN(Number(row.substValue)),
    ['substQuality']: (row: any) =>
      !isNaN(Number(row.substQuality)) &&
      Number(row.substQuality) >= 0 &&
      Number(row.substQuality) < 255 &&
      Number(row.substQuality) % 1 === 0,
    ['substTime']: (row: any) => !isNaN(Number(row.substTime)) && Number(row.substTime) >= -1,
  };

  columns = {
    ['name']: { Type: 'Enum', dbProps: 'name', Uid: 'name' },
    ['phase']: { Type: 'Enum', dbProps: 'phase', Uid: 'phase' },
    ['parameter']: { Type: 'Enum', dbProps: 'parameter', Uid: 'parameter' },
  };

  get config() {
    return {
      fileName: `IEC_60870_5_104_${this.sensorModel?.name}`,
      columns: [
        { prop: 'name', name: 'Диспетчерское наименование' },
        { prop: 'phase', name: 'Фаза' },
        { prop: 'parameter', name: 'Параметр' },
        { prop: 'scale', name: 'Масштаб' },
        { prop: 'offset', name: 'Смещение' },
        { prop: 'deviation', name: 'Погрешность' },
        { prop: 'substValue', name: 'Замещающее значение' },
        { prop: 'substQuality', name: 'Описатель качества' },
        { prop: 'substTime', name: 'Задержка применения' },
      ],
    };
  }

  get isValid() {
    const validatorsEntries = Object.entries(this.validators);

    return (
      this.editable &&
      !Object.entries(this.editable).some(([, x]) =>
        validatorsEntries.some(([key, value]) => !value(x))
      )
    );
  }

  get tableData() {
    const sources = this.sensorModel?.sources.flatMap((x) => x) ?? [];

    const data = sources
      .map((x) => {
        return {
          name: x.equipment.name,
          phase: calculatePhases(x.flags),
          parameter: this.references?.find((ref) => ref.id === x.typeId)?.notes,
          scale: x.scale,
          offset: x.offset,
          deviation: getDeviation(x),
          substValue: x.selector.x_subst,
          substQuality: x.selector.q_subst,
          substTime: x.selector.time_subst,
          uuid: x.uuid,
        };
      })
      .sort((x, y) => (`${x.name}${x.phase}` < `${y.name}${y.phase}` ? -1 : 1));

    return data;
  }

  get filteredData() {
    const queryFilters =
      this.queryFilter &&
      Object.fromEntries(
        JSON.parse(decodeURIComponent(this.queryFilter)).map((x: any) => [x.name, x])
      );

    return !queryFilters
      ? this.tableData
      : this.tableData.filter((data: any) =>
          Object.values(queryFilters).reduce(
            (has, filter: any) =>
              has &&
              filter.type === 'Enum' &&
              (!filter.options.length || filter.options.includes(data[filter.name])),
            true
          )
        );
  }

  get page() {
    return getPage(this.filteredData, this.sort, this.$route.query);
  }

  get queryFilter(): any {
    return this.$route.query.filter;
  }

  @Watch('queryFilter', { immediate: true }) onQueryFilterChange(val: any) {
    const queryFilters =
      val && Object.fromEntries(JSON.parse(decodeURIComponent(val)).map((x: any) => [x.name, x]));

    const columns = Object.values(this.columns);

    columns.forEach((column: any) => {
      const currentFilter = queryFilters && queryFilters[column.dbProps];
      const oldFilter: any = this.filtersMap[column.dbProps];

      const refs = columns.flatMap((x) =>
        [...new Set(this.tableData.map((y: any) => y[x.dbProps]))].map((y) => ({
          enumUid: x.Uid,
          order: y,
          notes: y,
        }))
      );

      Vue.set(
        this.filtersMap,
        column.dbProps,
        getColumnFilter(
          { query: val, columns: columns, refs: refs },
          column,
          oldFilter,
          currentFilter
        )
      );
    });
  }

  onSortChange(x: any) {
    this.sort = x;
  }

  getNewModel(sensor: Sensor, data: any[]) {
    const newSensor = {
      ...sensor,
      sources: data.map((row) => {
        const oldSource = sensor.sources.find((x) => x.uuid === row.uuid);

        return {
          ...oldSource,
          scale: Number(row.scale),
          offset: Number(row.offset),
          selector: {
            ...oldSource?.selector,
            x_subst: Number(row.substValue),
            q_subst: Number(row.substQuality),
            time_subst: Number(row.substTime),
          },
        };
      }),
    };

    return newSensor;
  }

  async handleConfirmAction(saveFlag: boolean) {
    this.showConfirm = false;
    if (saveFlag) {
      this.onSave();
    } else {
      this.isEdit = false;
    }
  }

  onSave() {
    const dirties = getModelsDiff(getEditableModel(this.tableData), this.editable);
    if (!dirties.length || !Array.isArray(dirties) || !this.sensorModel) {
      this.isEdit = false;
      this.isSaving = false;
    } else {
      this.isSaving = true;
      const newModel = this.getNewModel(
        this.sensorModel,
        Object.entries(this.editable).map(([key, value]) => value)
      );
      this.$emit('save-sensor', newModel, () => {
        this.isEdit = false;
        this.isSaving = false;
      });
    }
  }

  onEdit() {
    this.editable = getEditableModel(this.tableData);
    this.isEdit = true;
  }

  onCancelEdit() {
    const dirties = getModelsDiff(getEditableModel(this.tableData), this.editable);
    if (!dirties.length || !this.isValid) {
      this.$emit('cancel-edit');
      this.isEdit = false;
    } else {
      this.showConfirm = true;
    }
  }
}
