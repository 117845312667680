import { IDataExtract, IPdfBuilderOptions } from '@/components/files/services/types';
import { LocaleManager } from '@/services/locale/locale-manager';
import XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import { PdfContentBuilder } from '@/components/files/services/pdf-content-builder';

export class DataGenerator {
  public static sortByPropName = (data: any, props: string[], asString = true) => {
    const result = [];
    for (const prop of props) {
      result.push(data[prop] ?? '');
    }
    return asString ? result.join(';') : result;
  };

  public static generateTimelessPdf = async (
    extract: IDataExtract,
    opts: IPdfBuilderOptions
  ): Promise<any> => {
    return await new Promise<any>((resolve, reject) => {
      try {
        const pdfContent = PdfContentBuilder.build(extract, opts);

        const pdf = pdfMake.createPdf({ content: pdfContent, pageOrientation: 'landscape' });
        resolve(pdf);
        resolve(null);
      } catch (e) {
        reject(e);
      }
    });
  };

  public static generateTimelessXls = async (extract: IDataExtract): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        const { transformedData, propsNames, headers } = extract;
        const ctx = [
          headers,
          ...transformedData.map((d) => DataGenerator.sortByPropName(d, propsNames, false)),
        ];
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(ctx as any[]);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Данные');
        resolve(workbook);
      } catch (e) {
        reject(e);
      }
    });
  };

  public static generateTimelessCsv = async (extract: IDataExtract): Promise<any> => {
    return await new Promise<any>((resolve, reject) => {
      try {
        const { transformedData, propsNames, headers } = extract;
        let content = '';
        content += [
          headers.join(';'),
          ...transformedData.map((d) => DataGenerator.sortByPropName(d, propsNames)),
        ].join('\n');
        const blob = URL.createObjectURL(
          new Blob(['\ufeff' + content], {
            type: ` text/csv;charset=${LocaleManager.getCharset()}`,
          })
        );
        resolve(blob);
      } catch (e) {
        reject(e);
      }
    });
  };
}
