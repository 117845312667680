
















































import Vue from 'vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { Mutation, Getter } from 'vuex-class';
import InlineSvg from 'vue-inline-svg';

import { ROUTES } from '@/router/router.constants';
import { BREADCRUMBS } from '@/store/breadcrumbs/constants';
import { SENSORS_STORE_CONST } from '@/store/sensors/constants';

import { protocolTranslation } from './utils';
import { Sensor, Equipment } from './types';

@Component({
  components: {
    'inline-svg': InlineSvg,
  },
})
export default class SensorsTreeView extends Vue {
  @Prop() sensors!: Sensor[] | null;
  @Prop() routeId!: string | null;
  @Prop() equipments!: Equipment[] | null;
  @Prop() protocol!: string | null;
  @Prop() substation!: Equipment | null;
  @Prop() states!: Record<string, any> | null;

  @Getter(SENSORS_STORE_CONST.EXPANDED_ITEMS, { namespace: SENSORS_STORE_CONST.SENSORS })
  expanded!: Set<number | string>;

  @Mutation(BREADCRUMBS.SET_BREADCRUMBS, { namespace: BREADCRUMBS.BREADCRUMBS })
  setBreadcrumbs!: (x: string[]) => void;

  @Mutation(SENSORS_STORE_CONST.TREE_ITEMS_EXPAND, {
    namespace: SENSORS_STORE_CONST.SENSORS,
  })
  expandItems!: (keys: Set<number>) => void;
  @Mutation(SENSORS_STORE_CONST.TREE_ITEMS_COLLAPSE, {
    namespace: SENSORS_STORE_CONST.SENSORS,
  })
  collapseItems!: (keys: Set<number>) => void;

  @Ref('sensorsTree')
  readonly sensorsTree?: any;

  route = `${ROUTES.SENSORS}/`;

  filterString = '';

  defaultProps = {
    label: 'name',
    children: 'children',
  };

  get isLoading() {
    return !this.sensors;
  }

  get sensorsData() {
    const grouped =
      this.sensors &&
      this.sensors.reduce((hash, obj) => {
        if (obj['protocol']['protocol'] === undefined || obj.sources.length === 0) {
          return hash;
        }

        const substation = this.getSubstation(obj.sources[0].equipment);
        if (!substation) {
          return hash;
        }

        return Object.assign(hash, {
          [`${substation.id}`]: Object.assign(hash[substation.id] || {}, {
            [obj['protocol']['protocol']]: (
              (hash[substation.id] || {})[(obj as any)['protocol']['protocol']] || []
            ).concat(obj),
          }),
        });
      }, {} as { [key: string]: { [key: string]: Sensor[] } });

    const tree =
      grouped &&
      Object.entries(grouped).map(([substationId, items]) => {
        const root = {
          id: `station_${substationId}`,
          name: this.equipments?.find((x) => x.id === substationId)?.name,
          substation: true,
          children: Object.entries(items)
            .filter(([protocol]) => protocol !== 'IEC_60255_24' && protocol !== 'KSO_SERVER')
            .map(([protocol, items]) => ({
              id: protocol,
              root: substationId,
              name: protocolTranslation(protocol),
              children: [
                ...new Set(
                  items.map((x) =>
                    x.protocol.protocol === 'IEC_61850_9_2'
                      ? x.protocol.ied ?? ''
                      : `${x.protocol.ip}:${x.protocol.port}`
                  )
                ),
              ].map((x) => {
                const sensor = items.find((y) =>
                  y.protocol.protocol === 'IEC_61850_9_2'
                    ? y.protocol.ied === x
                    : `${y.protocol.ip}:${y.protocol.port}` === x
                );

                return {
                  ...sensor,
                  name: sensor?.protocol.ied,
                  root: substationId,
                };
              }),
            })),
        };
        root.children.push({
          id: 'NTP',
          root: substationId,
          name: protocolTranslation('NTP'),
          children:[]
        })
        return root;
      });
    return tree;
  }

  get crumbsArray() {
    const id = this.$route.params.id;
    const stationId = this.$route.params.stationId;

    if (this.sensorsData && id && stationId) {
      return this.getCrumbs(id, stationId, this.sensorsData) || [];
    } else if (this.substation) {
      return [this.substation];
    } else {
      return [];
    }
  }

  get routeIdWithData() {
    return {
      routeId: this.routeId,
      sensorsTree: this.sensorsTree,
      sensorsData: this.sensorsData,
    };
  }

  get protocolWithData() {
    return {
      protocol: this.protocol,
      sensorsTree: this.sensorsTree,
      sensorsData: this.sensorsData,
    };
  }

  get substationWithData() {
    return {
      protocol: this.protocol,
      substation: this.substation,
      sensorsTree: this.sensorsTree,
      sensorsData: this.sensorsData,
    };
  }

  @Watch('sensorsData', { immediate: true }) onChangeSensorsTree(val: this['sensorsData']) {
    if (val) {
      setTimeout((): void => {
        if (this.expanded?.size === 0) {
          const nodes = this.sensorsTree?.store.nodesMap || {};

          const rootKeys = Object.entries(nodes)
            .filter(([key, value]: any) => value.parent?.parent === null)
            .map(([key, value]: any) => key);

          this.expandItems(new Set(rootKeys));
        }
      }, 0);
    }
  }

  @Watch('routeIdWithData', { immediate: true }) onChangeSensor(val: this['routeIdWithData']) {
    const { routeId, sensorsTree, sensorsData } = val;

    if (sensorsTree && sensorsData) {
      setTimeout((): void => {
        this.onChangeExpanded(this.expanded, new Set([]));
        this.sensorsTree.setCurrentKey(routeId);
      }, 0);
    }
  }

  @Watch('protocolWithData', { immediate: true }) onChangeProtocolWithData(
    val: this['protocolWithData']
  ) {
    const { protocol, sensorsTree, sensorsData } = val;

    if (protocol && sensorsTree && sensorsData) {
      setTimeout((): void => {
        this.onChangeExpanded(this.expanded, new Set([]));
        sensorsTree.setCurrentKey(protocol);
      }, 0);
    }
  }

  @Watch('substationWithData', { immediate: true }) onChangeSubstationWithData(
    val: this['substationWithData']
  ) {
    const { substation, sensorsTree } = val;

    if (substation && sensorsTree && !this.$route.params.id) {
      setTimeout((): void => {
        const selectedKey = `station_${substation.id}`;

        this.onChangeExpanded(this.expanded, new Set([]));
        this.sensorsTree.setCurrentKey(selectedKey);
      }, 0);
    }
  }

  @Watch('filterString') onChangeFilter(val?: string | null) {
    this.sensorsTree && this.sensorsTree.filter(val);
  }

  @Watch('sensorsData') onChangeSensorsData() {
    setTimeout(() => {
      this.sensorsTree && this.sensorsTree.filter(this.filterString);
    }, 0);
  }

  @Watch('crumbsArray', { immediate: true }) onChangeCrumbsArray(val: any) {
    if (val) {
      this.setBreadcrumbs(val.map((x: any) => x.name));
    }
  }

  @Watch('expanded')
  onChangeExpanded(newExpanded: Set<number | string>, oldExpanded: Set<number | string>) {
    const nodes = this.sensorsTree?.store.nodesMap || {};
    const toAdd = [...newExpanded].filter((x) => !oldExpanded.has(x));
    const toDelete = [...oldExpanded].filter((x) => !newExpanded.has(x));

    toDelete.forEach((x) => {
      const node = nodes[x];
      if (node && node.expanded) {
        node.expanded = false;
      }
    });

    toAdd.forEach((x) => {
      const node = nodes[x];
      if (node && !node.expanded) {
        node.expanded = true;
      }
    });
  }

  onNodeExpand(data: any) {
    this.expandItems(new Set([data.id]));
  }

  onNodeCollapse(data: any) {
    this.collapseItems(new Set([data.id]));
  }

  getStatus(sensor: Sensor) {
    return (this.states || {})[
      sensor?.protocol.protocol === 'IEC_61850_9_2'
        ? sensor?.protocol.ied || ''
        : `${sensor?.protocol.ip}${sensor?.protocol.port ? `:${sensor?.protocol.port}` : ''}`
    ];
  }

  getParentStatus(children: any[]) {
    const statuses = children.map((x) => this.getStatus(x)?.status);

    if (statuses.some((x) => x === 'off')) {
      return 'off';
    } else if (statuses.some((x) => x === undefined)) {
      return 'undefined';
    } else if (statuses.some((x) => x === 'warn')) {
      return 'warn';
    } else {
      return 'on';
    }
  }

  getCrumbs(id: string, stationId: string, nodes: any[]): any[] | null {
    const station = nodes.find((x) => x.id === `station_${stationId}`);
    const protocol = isNaN(Number(id))
      ? station?.children.find((x: any) => x.id === id)
      : station?.children.find((x: any) => x.children.some((y: any) => y.id === id));
    const sensor = nodes
      .flatMap((x) => x.children)
      .flatMap((y: any) => y.children)
      .find((x) => x.id === id);

    return [station, protocol, sensor].filter((x) => !!x);
  }

  filterNode(value: string | null, data: any) {
    if (!value) {
      return true;
    }
    const val = value.toLowerCase();
    return data?.protocol?.ied?.toLowerCase().includes(val);
  }

  getSubstation(x: Equipment): Equipment | null {
    if (!this.equipments) {
      return null;
    } else if (x.parentId === null) {
      return x;
    } else {
      const parent = this.equipments.find((y) => y.id === x.parentId);
      return parent ? this.getSubstation(parent) : null;
    }
  }

  querySearch(query: string, cb: Function) {
    cb(
      (
        (query
          ? this.equipments?.filter((x) => x.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
          : this.equipments) ?? []
      ).map((x) => ({ ...x, value: x.name }))
    );
  }

  nodeClick(item: any) {
    if (item.substation) {
      this.$router
        .push(`${this.route}${item.id.replace('station_', '')}`)
        .catch((err: any) => void 0);
    } else if (item.protocol) {
      const current = Number(this.$route.params.id);
      const station = Number(this.$route.params.stationId);

      if (current !== Number(item.id) || station !== Number(item.root)) {
        this.$router.push(`${this.route}${item.root}/${item.id}`).catch((err: any) => void 0);
      }
    } else {
      const current = this.$route.params.id;
      const station = Number(this.$route.params.stationId);

      if (current !== item.id || station !== Number(item.root)) {
        this.$router.push(`${this.route}${item.root}/${item.id}`).catch((err: any) => void 0);
      }
    }
  }

  beforeDestroy() {
    this.setBreadcrumbs([]);
  }
}
